import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import { apolloSpaceInset2X } from "@nexthink/apollo-components/lib/tokens";
import styled from "styled-components";

export const StyledPanelContainer = styled(Flex)({
  flex: 1,
  flexDirection: "column",
  overflow: "hidden",
});

export const StyledPanelContent = styled(Flex)({
  flex: 1,
  overflow: "auto",
  flexDirection: "column",
});

export const StyledPanelTopContainer = styled(Flex)({
  flex: 0,
  flexDirection: "column",
  paddingBottom: apolloSpaceInset2X,
});
