import { trapEvent } from "@nexthink/apollo-components/lib/utils/EventHelpers";
import { type FC, useCallback, useState } from "react";
import { MenuItem } from "../MenuItem/MenuItem";
import { useGetMenuGroups } from "../hooks/useGetMenuGroups";
import { useIsMenuGroupActive } from "../hooks/useIsMenuGroupActive";
import { useGetMenuItemsByGroupList } from "../hooks/usetGetMenuItemsByGroupList";
import { getMenuItemProps } from "../util";
import { MenuGroup, type MenuGroupChild } from "./MenuGroup";
import {
  StyleMenuGroupCollapsibleCollapseIcon,
  StyledMenuGroupCollapsibleContent,
  StyledMenuGroupCollapsibleTitle,
} from "./MenuGroupCollapsible.style";

const MenuGroupCollapsible: FC<MenuGroupChild> = ({ menuGroup, level }) => {
  const groups = useGetMenuGroups(menuGroup.menuId, menuGroup.id);
  const items = useGetMenuItemsByGroupList(menuGroup.menuId, [menuGroup.id]);
  const isActive = useIsMenuGroupActive(menuGroup.id);

  const [isExpanded, setIsExpanded] = useState(isActive);

  const toggleExpanded = useCallback((e) => {
    setIsExpanded((currentIsExpanded) => !currentIsExpanded);
    e.stopPropagation();
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Enter" || e.key === " ") {
      trapEvent(e);
      setIsExpanded((currentIsExpanded) => !currentIsExpanded);
    }
  }, []);

  const titleIconName = isExpanded ? "angleDown" : "angleRight";

  return (
    <>
      <StyledMenuGroupCollapsibleTitle
        variant="bodySmallStrong"
        role="menuitem"
        aria-haspopup="true"
        aria-expanded={isExpanded}
        tabIndex={0}
        onClick={toggleExpanded}
        onKeyDown={handleKeyDown}
        id={menuGroup.id}
      >
        <StyleMenuGroupCollapsibleCollapseIcon name={titleIconName} />
        <span>{menuGroup.title}</span>
      </StyledMenuGroupCollapsibleTitle>
      <StyledMenuGroupCollapsibleContent isExpanded={isExpanded} childrenCount={items.length + groups.length}>
        {items.map((i) => (
          <MenuItem key={i.id} {...getMenuItemProps(i)} />
        ))}
        {groups.map((g) => (
          <MenuGroup key={g.id} id={g.id} level={level + 1} />
        ))}
      </StyledMenuGroupCollapsibleContent>
    </>
  );
};

export default MenuGroupCollapsible;
