import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import useProductShellService from "../hooks/useProductShellService";
import { reportError } from "../observability";
import { ElementLoadError } from "../utils";

const USE_MODULE_FEDERATION_APP_PAGE = "productShell-moduleFederationAppearancePage";

export const useIsModuleFederationEnabled = () => {
  const [isModuleFederationEnabled, setIsModuleFederationEnabled] = useState(false);
  const { isAuthenticated } = useAuthContext();
  const { getLDFlagValue } = useProductShellService();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const init = async () => {
      let enabledModuleFederation = false;
      try {
        if (isAuthenticated) {
          enabledModuleFederation = await getLDFlagValue(USE_MODULE_FEDERATION_APP_PAGE);
        }
      } catch (e) {
        const newRelicError = new ElementLoadError(`Error getting LD flag ${USE_MODULE_FEDERATION_APP_PAGE}`, e);
        reportError(newRelicError, { file: newRelicError.file, errorType: "fileLoad" });
      }
      setIsModuleFederationEnabled(enabledModuleFederation);
    };
    init();
  }, []);

  return { isModuleFederationEnabled };
};
