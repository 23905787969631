/**
 * OPEN QUESTIONS:
 *  - i18n
 */
import type { BcsFilters, BcsTag } from "./bcs-shared";

export type Languages = "en" | "fr";

export type Translation = Record<Languages, string>;

export type GenericMenuConfigComponent = {
  id: string;
  title: Translation;
  url?: string;
  icon?: string;
  sortOrder: number;
  requiredFlags?: string[];
};

export type TranslatedMenuConfigComponent<T extends GenericMenuConfigComponent> = {
  [key in keyof T]: T[key] extends Translation
    ? string
    : T[key] extends Translation | undefined
      ? string | undefined
      : T[key];
};

export type MenuConfigComponentResponse<T extends GenericMenuConfigComponent> = Omit<
  TranslatedMenuConfigComponent<T>,
  "requiredFlags" | "sortOrder"
>;

/**
 * @openapi
 * components:
 *   schemas:
 *     App:
 *       properties:
 *         id:
 *           title: App.id
 *           type: string
 *         jsFile:
 *           title: App.jsFile
 *           type: string
 *         routes:
 *           title: App.routes
 *           type: array
 *           items:
 *             title: App.routes.[]
 *             type: string
 *         cssFiles:
 *           items:
 *             title: App.cssFiles.[]
 *             type: string
 *           title: App.cssFiles
 *           type: array
 *         jsResources:
 *           items:
 *             title: App.jsResources.[]
 *             type: string
 *           title: App.jsResources
 *           type: array
 *       required:
 *         - id
 *         - jsFile
 *         - routes
 *       additionalProperties: false
 *       title: App
 *       type: object
 */
export interface App {
  id: string;
  jsFile: string;
  routes?: string[];
  version?: string;
  name?: string;
  imagePath?: string;
  cssFiles?: string[];
  jsResources?: string[];
}

// DEFINED BY OWNER

/**
 * @openapi
 * components:
 *   schemas:
 *     Menu:
 *       properties:
 *         id:
 *           title: Menu.id
 *           type: string
 *         title:
 *           title: Menu.title
 *           type: string
 *         icon:
 *           title: Menu.icon
 *           type: string
 *         url:
 *           title: Menu.url
 *           type: string
 *         apiUrl:
 *           title: Menu.apiUrl
 *           type: string
 *         prefix:
 *           type: string
 *           title: Menu.prefix
 *         telemetryDashboardType:
 *           type: string
 *           title: Menu.telemetryDashboardType
 *         category:
 *           type: string
 *           title: Menu.category
 *         panelType:
 *           enum:
 *             - default
 *             - favorites
 *           title: PanelType
 *           type: string
 *       required:
 *         - id
 *         - title
 *         - icon
 *       additionalProperties: false
 *       title: Menu
 *       type: object
 */

export type BlueMenuGroup = {
  menuItems: MenuResponseMenu[];
  type: string;
};
export interface Menu extends GenericMenuConfigComponent {
  icon: string; // required for Menu
  subtitle?: Translation;
  tags?: boolean;
  extraRoutePrefixes?: string[]; // these routes will cause this menu to show Active
  apiUrl?: string; // if dynamic menu
  panelType?: "default" | "favorites" | "custom-dashboards" | "tabbed";
  blueMenuGroupId?: string;
}

export enum PanelType {
  Default = "default",
  Favorites = "favorites",
  Custom_Dashboards = "custom-dashboards",
  Tabbed = "tabbed",
}

/**
 * @openapi
 * components:
 *   schemas:
 *     MenuGroup:
 *       properties:
 *         id:
 *           title: MenuGroup.id
 *           type: string
 *         title:
 *           title: MenuGroup.title
 *           type: string
 *         icon:
 *           title: MenuGroup.icon
 *           type: string
 *         url:
 *           title: MenuGroup.url
 *           type: string
 *         menuId:
 *           title: MenuGroup.menuId
 *         menuGroupId:
 *           title: MenuGroup.menuGroupId
 *       required:
 *         - id
 *         - title
 *         - menuId
 *       additionalProperties: false
 *       title: MenuGroup
 *       type: object
 */
export interface MenuGroup extends GenericMenuConfigComponent {
  url?: never;

  menuId: Menu["id"];
  menuGroupId?: MenuGroup["id"]; // ALWAYS REQUIRE MENU TO AVOID CONFLICTS WITH OTHER TEAMS
}

/**
 * @openapi
 * components:
 *   schemas:
 *     MenuItem:
 *       properties:
 *         id:
 *           title: MenuItem.id
 *           type: string
 *         menuId:
 *           title: MenuItem.menuId
 *         menuGroupId:
 *           title: MenuItem.menuGroupId
 *         title:
 *           title: MenuItem.title
 *           type: string
 *         url:
 *           title: MenuItem.url
 *           type: string
 *         icon:
 *           title: MenuItem.icon
 *           type: string
 *       required:
 *         - id
 *         - menuId
 *         - title
 *         - url
 *       additionalProperties: false
 *       title: MenuItem
 *       type: object
 */
export interface MenuItem extends GenericMenuConfigComponent {
  menuId: Menu["id"];
  menuGroupId?: MenuGroup["id"];
  url: string;
  tags?: BcsTag[];
}

export interface DynamicItem extends Omit<GenericMenuConfigComponent, "title"> {
  bcsFilters: BcsFilters;
  includeProductArea?: boolean;
  menuId: Menu["id"];
  menuGroupId?: MenuGroup["id"];
  url: string;
}

/**
 * @openapi
 * components:
 *   schemas:
 *     MenuAction:
 *       properties:
 *         id:
 *           title: MenuAction.id
 *           type: string
 *         menuId:
 *           title: MenuAction.menuId
 *           type: string
 *         title:
 *           title: MenuAction.title
 *           type: string
 *         icon:
 *           title: MenuAction.icon
 *           type: string
 *         type:
 *           enum:
 *             - create
 *             - config
 *             - default
 *           title: MenuAction.type
 *           type: string
 *         url:
 *           title: MenuAction.url
 *           type: string
 *         disabled:
 *           title: MenuAction.disabled
 *           type: boolean
 *         disabledTooltip:
 *           title: MenuAction.title
 *           type: string
 *       required:
 *         - id
 *         - menuId
 *         - title
 *         - type
 *         - url
 *       additionalProperties: false
 *       title: MenuAction
 *       type: object
 */
export interface MenuAction extends GenericMenuConfigComponent {
  menuId: Menu["id"];
  url: string;

  disabled?: boolean;
  disabledTooltip?: Translation;

  type: "config" | "create" | "overview" | "default";
}

export enum MenuActionType {
  Config = "config",
  Overview = "overview",
  Default = "default",
  Create = "create",
}

/**
 * @openapi
 * components:
 *   schemas:
 *     MenuItemAction:
 *       properties:
 *         id:
 *           title: MenuItemAction.id
 *           type: string
 *         menuId:
 *           title: MenuItemAction.menuId
 *           type: string
 *         title:
 *           title: MenuItemAction.title
 *           type: string
 *         icon:
 *           title: MenuItemAction.icon
 *           type: string
 *         url:
 *           title: MenuItemAction.url
 *           type: string
 *       required:
 *         - id
 *         - menuId
 *         - title
 *         - icon
 *         - url
 *       additionalProperties: false
 *       title: MenuAction
 *       type: object
 */
export interface MenuItemAction extends GenericMenuConfigComponent {
  menuItemId: MenuItem["id"];
  url: string;
  icon: string;
}

/**
 * @openapi
 * components:
 *   schemas:
 *     MenuResponse:
 *       properties:
 *         apps:
 *           items:
 *             $ref: '#/components/schemas/App'
 *             title: MenuResponse.apps.[]
 *           title: MenuResponse.apps
 *           type: array
 *         menus:
 *           items:
 *             $ref: '#/components/schemas/Menu'
 *             title: MenuResponse.menus.[]
 *           title: MenuResponse.menus
 *           type: array
 *         menuActions:
 *           items:
 *             $ref: '#/components/schemas/MenuAction'
 *             title: MenuResponse.menuActions.[]
 *           title: MenuResponse.menuActions
 *           type: array
 *         groups:
 *           items:
 *             $ref: '#/components/schemas/MenuGroup'
 *             title: MenuResponse.groups.[]
 *           title: MenuResponse.groups
 *           type: array
 *         items:
 *           items:
 *             $ref: '#/components/schemas/MenuItem'
 *             title: MenuResponse.items.[]
 *           title: MenuResponse.items
 *           type: array
 *       required:
 *         - apps
 *         - menus
 *         - menuActions
 *         - groups
 *         - items
 *       additionalProperties: false
 *       title: MenuResponse
 *       type: object
 */

export type MenuResponseApp = App;
export type MenuResponseMenu = MenuConfigComponentResponse<Menu>;
export type MenuResponseGroupedMenu = BlueMenuGroup;
export type MenuResponseMenuAction = MenuConfigComponentResponse<MenuAction>;
export type MenuResponseMenuGroup = MenuConfigComponentResponse<MenuGroup>;
export type MenuResponseMenuItem = MenuConfigComponentResponse<MenuItem>;
export type MenuResponseMenuItemAction = MenuConfigComponentResponse<MenuItemAction>;

export type MenuResponse = {
  apps: MenuResponseApp[];
  groupedMenus: MenuResponseGroupedMenu[];
  menuActions: MenuResponseMenuAction[];
  groups: MenuResponseMenuGroup[];
  items: MenuResponseMenuItem[];
  itemActions: MenuResponseMenuItemAction[];
};

export type MenuConfig = {
  apps: App[];
  menuActions: MenuAction[];
  groups: MenuGroup[];
  dynamicItems: DynamicItem[];
  items: MenuItem[];
  itemActions: MenuItemAction[];
};

export type DynamicMenuConfig = Omit<MenuConfig, "apps" | "menus">;

export type MenuConfigTranslated = {
  apps: App[];
  groupedMenus: TranslatedBlueMenuConfigComponent;
  menuActions: TranslatedMenuConfigComponent<MenuAction>[];
  groups: TranslatedMenuConfigComponent<MenuGroup>[];
  items: TranslatedMenuConfigComponent<MenuItem>[];
  itemActions: TranslatedMenuConfigComponent<MenuItemAction>[];
};

type TranslatedBlueMenuConfigComponent = {
  menuItems: TranslatedMenuConfigComponent<Menu>[];
  type: string;
};

export const isTranslation = (value: Translation | unknown): value is Translation =>
  Boolean((value as Translation)?.en);
