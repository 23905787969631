import { type FC, useMemo } from "react";
import { useAuthContext } from "../context/AuthContext";
import OktaAuth from "./OktaAuth";
import PortalAuth from "./PortalAuth";

const Auth: FC = ({ children }) => {
  const { hasPortal } = useAuthContext();

  return useMemo(() => {
    return hasPortal ? <PortalAuth>{children}</PortalAuth> : <OktaAuth>{children}</OktaAuth>;
  }, [hasPortal, children]);
};

export default Auth;
