import { isEqual } from "lodash";
import { memo } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { useGetDynamicComponentProps } from "../hooks/useGetDynamicComponentProps";
import { observeMicroFrontEnd } from "../observability";
import { getPropsAreEqual } from "../utils";
import DynamicModule from "./DynamicModule";

export interface InnerPureComponentProps extends RouteComponentProps {
  id: string;
  remoteEntry: string;
  scope: string;
  remoteModule: string;
  version: string;
}

const InnerFederatedComponentPure = memo(
  function InternalRenderComponent({
    id,
    remoteEntry,
    scope,
    remoteModule,
    version,
    ...routeComponentProps
  }: InnerPureComponentProps) {
    const dynamicComponentProps = useGetDynamicComponentProps(routeComponentProps);
    observeMicroFrontEnd(id, version); // We must get the version of each MFE from somewhere

    // will never happen
    if (!dynamicComponentProps) {
      return null;
    }

    return (
      <DynamicModule
        module={remoteModule}
        remoteEntryUrl={remoteEntry}
        scope={scope}
        version={version}
        {...dynamicComponentProps}
      />
    );
  },
  getPropsAreEqual((prevValue, nextValue) => isEqual(prevValue, nextValue))
);

export default InnerFederatedComponentPure;
