import type { CancelToken } from "axios";
import { useCallback } from "react";

declare global {
  interface Window {
    nxCache?: Record<string, unknown>;
  }
}

export const resetNxCache = () => {
  window.nxCache = {};
};

export const useCachedMethod = <T,>(
  fn: (cancelToken?: CancelToken) => Promise<T>,
  cacheDurationMs: number,
  cacheKey: string
): ((cancelToken?: CancelToken) => Promise<T>) => {
  return useCallback(
    async (cancelToken?: CancelToken) => {
      window.nxCache = window.nxCache || {};
      const cache = window.nxCache[cacheKey];

      if (cache) {
        const { value, lastUpdate } = cache as { value: T; lastUpdate: number };

        if (value && lastUpdate && new Date().getTime() - lastUpdate < cacheDurationMs) {
          return Promise.resolve(value);
        }
      }

      const newValue = await fn(cancelToken);

      window.nxCache[cacheKey] = { value: newValue, lastUpdate: new Date().getTime() };

      return newValue;
    },
    [cacheDurationMs, cacheKey, fn]
  );
};
