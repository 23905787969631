import { Flex } from "@nexthink/apollo-components";
import styled from "styled-components";

import { MENU_WIDTH_COLLAPSED } from "../Navigation/BlueBar.style";

export const LOADING_TRANSITION_DURATION = 200;
export const StyledLoadingOverlayContainer = styled(Flex)<{
  isVisible: boolean;
}>(({ theme, isVisible }) => ({
  position: "absolute",
  top: 0,
  left: MENU_WIDTH_COLLAPSED,
  right: 0,
  bottom: 0,
  zIndex: 1000,

  backgroundColor: theme.colors.apolloColorBackgroundDashboard,

  opacity: isVisible ? 1 : 0,
  transition: isVisible ? "" : `opacity ${LOADING_TRANSITION_DURATION}ms`,

  alignItems: "center",
  justifyContent: "center",
}));
