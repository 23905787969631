import type React from "react";
import { useCallback, useEffect, useState } from "react";
import type { MenuItem as MenuItemType } from "../../services/types/menu";

import { Box, ColorFlag, type FlagColors, TooltipText, WithTooltip } from "@nexthink/apollo-components";
import { trapEvent } from "@nexthink/apollo-components/lib/utils/EventHelpers";
import { HighlightedText } from "../../Navigation/MenuItem/HighlightedText";
import { useProductShellNavigationContext } from "../../Navigation/ProductShellNavigationContext";
import { MenuItem } from "../MenuItem/MenuItem";
import { type MenuTag, getMenuItemProps } from "../util";
import {
  StyleMenuTagCollapsibleCollapseIcon,
  StyledBox,
  StyledMenuTag,
  StyledMenuTagContent,
  StyledTagText,
} from "./MenuTagCollapsible.style";

const MAX_LENGTH_TAG_TITLE = 50;

const saveSelectedTag = (menuTag: MenuTag | undefined) => {
  if (menuTag) {
    const storedValue = localStorage.getItem(menuTag.menuId);
    const savedTags = storedValue ? JSON.parse(storedValue) : [];
    const hasSavedTagForGroup = savedTags.some((tag: MenuTag) => tag.menuGroupId === menuTag.menuGroupId);
    const tags = hasSavedTagForGroup
      ? savedTags.filter((tag: MenuTag) => tag.menuGroupId !== menuTag.menuGroupId)
      : savedTags;
    localStorage.setItem(
      menuTag.menuId,
      JSON.stringify([...tags, { id: menuTag.id, menuGroupId: menuTag.menuGroupId, title: menuTag.title }])
    );
  }
};

const TagText = ({
  isEmptySearchQuery,
  searchQuery,
  menuTag,
}: {
  isEmptySearchQuery: boolean;
  searchQuery: string;
  menuTag?: MenuTag;
}) => {
  if (isEmptySearchQuery) {
    return <>{menuTag?.title}</>;
  }
  return <HighlightedText text={menuTag?.title} highlight={searchQuery} inverseOrder={true} />;
};

const TagTitle = ({
  menuTag,
  searchQuery,
  itemsCount,
}: {
  menuTag?: MenuTag;
  searchQuery: string;
  itemsCount: number;
}) => {
  const isEmptySearchQuery = searchQuery === "";
  const isLongTitle = (menuTag?.title?.length || 0) >= MAX_LENGTH_TAG_TITLE;
  const tooltipId = `${menuTag?.id}-tooltip`;

  if (!isLongTitle) {
    return (
      <StyledBox>
        <StyledTagText>
          <TagText isEmptySearchQuery={isEmptySearchQuery} searchQuery={searchQuery} menuTag={menuTag} />
        </StyledTagText>
        <span>{` (${itemsCount})`}</span>
      </StyledBox>
    );
  }
  return (
    <WithTooltip
      tooltipContents={
        <TooltipText data-testid={tooltipId} id={tooltipId}>
          {menuTag?.title}
        </TooltipText>
      }
      alignment="bottomRight"
      trigger="hover"
      spacing="compact"
      maxWidth={"320px"}
    >
      <StyledBox>
        <StyledTagText>
          <TagText isEmptySearchQuery={isEmptySearchQuery} searchQuery={searchQuery} menuTag={menuTag} />
        </StyledTagText>
        <span>{` (${itemsCount})`}</span>
      </StyledBox>
    </WithTooltip>
  );
};

type MenuTagCollapsibleProps = {
  menuTag?: MenuTag;
  menuItems: MenuItemType[];
  setActiveTag: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedTag: string | undefined;
};

const MenuTagCollapsible = ({ menuTag, menuItems, setActiveTag, selectedTag }: MenuTagCollapsibleProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const itemsCount = menuItems.length;
  const navigationContext = useProductShellNavigationContext();

  useEffect(() => {
    const isSelectedTag = menuTag?.title === selectedTag;
    setIsExpanded(isSelectedTag);
  }, [menuTag?.title, selectedTag]);

  const handleClick = useCallback(
    (e) => {
      setIsExpanded((currentIsExpanded) => !currentIsExpanded);
      setActiveTag(menuTag?.title);
      saveSelectedTag(menuTag);
      e.stopPropagation();
    },
    [menuTag, setActiveTag]
  );

  const handleKeyDown = useCallback((e) => {
    if (e.key === "Enter" || e.key === " ") {
      trapEvent(e);
      setIsExpanded((currentIsExpanded) => !currentIsExpanded);
    }
  }, []);

  const isEmptySearchQuery = navigationContext.searchQuery === "";
  const isOpen = isEmptySearchQuery ? isExpanded : true;
  const iconName = isOpen ? "angleDown" : "angleRight";

  return (
    <Box>
      <StyledMenuTag
        variant="bodySmallStrong"
        role="menuitem"
        aria-label={`${menuTag?.title}, ${itemsCount} item${itemsCount === 1 ? "" : "s"} on the tag`}
        aria-haspopup="true"
        aria-expanded={isOpen}
        tabIndex={0}
        onClick={isEmptySearchQuery ? handleClick : undefined}
        onKeyDown={isEmptySearchQuery ? handleKeyDown : undefined}
        id={menuTag?.id}
        isEmptySearchQuery={isEmptySearchQuery}
      >
        <StyleMenuTagCollapsibleCollapseIcon data-testid={`arrow-${iconName}-icon-${menuTag?.id}`} name={iconName} />
        <ColorFlag color={menuTag?.color as FlagColors} />
        <TagTitle menuTag={menuTag} searchQuery={navigationContext.searchQuery} itemsCount={itemsCount} />
      </StyledMenuTag>
      {isOpen ? (
        <StyledMenuTagContent isExpanded={isOpen}>
          {menuItems.map((item) => (
            <MenuItem key={item.id} {...getMenuItemProps(item)} />
          ))}
        </StyledMenuTagContent>
      ) : null}
    </Box>
  );
};

export default MenuTagCollapsible;
