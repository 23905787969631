import type { ProductShellState } from "@nexthink/product-shell-library";
import type { PropsWithChildren } from "react";
import { DEVICE_LOCATOR_MENU_ID, MY_ACCOUNT_MENU_ID, SEARCH_APP_ID, SEARCH_MENU_ID } from "./Navigation/constants";
import type { Menu, MenuResponse } from "./services/types/menu";

export const SAML_LOGIN = "/saml/redirect";
export const SAML_LOGOUT = "/logout";
export const LOCAL_LOGIN = "/hierarchy";
export const LOCAL_LOGOUT = "/hierarchy";

const SERVICE_VERSION_PATH = process.env.SERVICE_VERSION ? `/${process.env.SERVICE_VERSION}` : "";
const useCDN = process.env.USE_CDN === "true";

export const RESET_JS_SCRIPT_URL = useCDN
  ? `/static/ui/product-shell${SERVICE_VERSION_PATH}/ps/lib/core-js-bundle-3.22.8.min.js`
  : `/nxaws/ui/product-shell${SERVICE_VERSION_PATH}/ps/lib/core-js-bundle-3.22.8.min.js`;

export const customDashboardsHashUrlToUrl = (hashUrl: string) =>
  hashUrl.replace(/^#(personal|published|role_based):(.*?):(.*)/g, "/$1/$2/$3");

export const getRedirectTo = () => encodeURIComponent(location.pathname + location.search);

export const navigateToUrl = (url: string, shouldAppendRedirectTo = false) => {
  window.location.assign(shouldAppendRedirectTo ? `${url}?redirectTo=${getRedirectTo()}` : url);
};

// called when token expired / logout from different tab
export const redirectToLogin = (withSaml: boolean) => {
  navigateToUrl(withSaml ? SAML_LOGIN : LOCAL_LOGIN, true);
};

// called when Logout link pressed
export const redirectToLogout = (withSaml: boolean) => {
  navigateToUrl(withSaml ? SAML_LOGOUT : LOCAL_LOGOUT, true);
};

export const isSearchAppPresent = (menuResponse: MenuResponse): boolean =>
  Boolean(menuResponse.apps.find((app) => app.id === SEARCH_APP_ID));

export const filterOutDialogMenus = (menu: Menu) =>
  ![...SEARCH_MENU_ID, MY_ACCOUNT_MENU_ID, DEVICE_LOCATOR_MENU_ID].includes(menu.id);

export const resetJs = () => {
  unloadJsFileByUrl(RESET_JS_SCRIPT_URL);

  const scriptTag = document.createElement("script");

  scriptTag.src = RESET_JS_SCRIPT_URL;
  scriptTag.type = "text/javascript";
  scriptTag.async = false;

  document.head.appendChild(scriptTag);
};

export const loadJsFile = (url: string): Promise<unknown> => {
  const scriptTag = document.createElement("script");

  scriptTag.src = url;
  scriptTag.type = "text/javascript";
  scriptTag.async = true;

  const promise = promisifyElementLoad(scriptTag);

  document.head.appendChild(scriptTag);

  return promise;
};

export const loadCSSFile = async (url: string): Promise<void> => {
  const linkTag = document.createElement("link");

  linkTag.type = "text/css";
  linkTag.rel = "stylesheet";
  linkTag.href = url;

  const promise = promisifyElementLoad(linkTag);

  document.head.appendChild(linkTag);

  await promise;
};

export class ElementLoadError extends Error {
  constructor(
    message: string,
    public file: string
  ) {
    super(message);
  }
}

export const promisifyElementLoad = (element: HTMLScriptElement | HTMLLinkElement): Promise<unknown> =>
  new Promise((resolve, reject) => {
    element.onload = () => {
      resolve(window.default);
    };

    element.onerror = (_, __, ___, ____, error) => {
      const file = (element as HTMLScriptElement).src || (element as HTMLLinkElement).href;
      reject(
        new ElementLoadError(
          error?.message || `could not load ${file}`,
          (element as HTMLScriptElement).src || (element as HTMLLinkElement).href
        )
      );
    };
  });

export const unloadJsFileByUrl = (url: string): void => {
  unloadFileByQuerySelector(`script[src="${url}"]`);
};

export const unloadCSSFileByUrl = (url: string): void => {
  unloadFileByQuerySelector(`link[href="${url}"]`);
};

export const unloadFileByQuerySelector = (querySelector: string): void => {
  const tag = document.head.querySelector(querySelector);
  if (tag) {
    document.head.removeChild(tag);
  }
};

export enum ErrorTypes {
  serviceError = "serviceError",
  startupError = "startupError",
  errorBoundaryError = "errorBoundaryError",
  microFrontendError = "microFrontendError",
}

export const getPropsAreEqual =
  <T>(
    comparator: (
      prevValue: Readonly<PropsWithChildren<T>>[keyof T],
      nextValue: Readonly<PropsWithChildren<T>>[keyof T]
    ) => boolean
  ) =>
  (prevProps: Readonly<PropsWithChildren<T>>, nextProps: Readonly<PropsWithChildren<T>>): boolean =>
    Object.keys(prevProps).reduce((result, keyString) => {
      const key = keyString as keyof T;
      const prevValue = prevProps[key];
      const nextValue = nextProps[key];

      // react-router keeps an internal reference "key" for its own logic which we are not interested in
      // query params are not relevant for the comparison because they're the responsibility of the MFE
      // also we are only interested if the app's MFE routes config changes in order to determine if the MFE should be reloaded
      if (key === "location" || key === "pathname") {
        return true;
      }

      const isPropTheSame = comparator(prevValue, nextValue);
      return result && isPropTheSame;
    }, true);

export const getAppStateType = (state: ProductShellState) => {
  if (state !== undefined) {
    return state[0];
  }
  return undefined;
};

export const getErrorCode = (state: ProductShellState) => {
  if (state.length > 1 && getAppStateType(state) === "error") {
    return state?.[1]?.code;
  }
  return undefined;
};

export const getErrorMessage = (state: ProductShellState) => {
  if (getAppStateType(state) === "error" && state[1] !== undefined && state[1].message !== null) {
    return state[1].message;
  }
  return undefined;
};
