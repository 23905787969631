import StatusGreen from "@nexthink/apollo-components/lib/color-icons/StatusGreen";
import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Container from "@nexthink/apollo-components/lib/components/container/Container";
import Header from "@nexthink/apollo-components/lib/components/header/Header";
import TitleBar from "@nexthink/apollo-components/lib/components/header/TitleBar";
import { H3 } from "@nexthink/apollo-components/lib/components/heading/Heading";
import MainContainer from "@nexthink/apollo-components/lib/components/main-container/MainContainer";
import MainContent from "@nexthink/apollo-components/lib/components/main-container/MainContent";
import Text from "@nexthink/apollo-components/lib/components/text/Text";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { apolloSpaceInset1X, apolloSpaceStack2X, apolloSpaceStack3X } from "@nexthink/apollo-components/lib/tokens";
import { type FC, useCallback } from "react";

import Link from "@nexthink/apollo-components/lib/components/link/Link";
import { useAuthContext } from "../context/AuthContext";
import { useProductShellContext } from "../context/ProductShellContext";
import { useIsAmplifyUser } from "../hooks/useIsAmplifyUser";
import { useSendLogoutToAmplify } from "../hooks/useSendToAmplify";
import BackgroundDark from "./landingPageBgDark.svg";
import BackgroundLight from "./landingPageBgLight.svg";

const LANDING_PAGE_NO_MENU_TRANSLATION_NAMESPACE = "landing-page-no-menu-amplify";
const LANDING_PAGE_NO_PERMISSIONS_TRANSLATION_NAMESPACE = "landing-page-no-menu-no-permissions";

const LandingPageNoMenu: FC = () => {
  const isAmplifyUser = useIsAmplifyUser();
  const amplifyLogoutFn = useSendLogoutToAmplify();
  const { logoutFn: logout } = useAuthContext();
  const { t } = useNxTranslation();
  const { theme } = useProductShellContext();

  const handleOnClick = useCallback(async () => {
    amplifyLogoutFn();
    await logout();
  }, [amplifyLogoutFn, logout]);

  if (isAmplifyUser === undefined) {
    return <Container alignItems={"center"} />;
  }

  return (
    <MainContainer>
      <Header
        titleBar={
          <TitleBar
            id="title-bar"
            mainTitle={t("main_title", { ns: LANDING_PAGE_NO_MENU_TRANSLATION_NAMESPACE })}
            secondaryTitle={isAmplifyUser ? "AMPLIFY" : ""}
          />
        }
      />
      <MainContent>
        <Container height="auto">
          <Flex flexDirection="column" alignItems="center" flex={1} py={apolloSpaceStack3X}>
            <Flex gap={apolloSpaceInset1X} flexDirection="row" alignItems="center">
              {isAmplifyUser && <StatusGreen />}
              {isAmplifyUser ? (
                <H3>{t("container_heading", { ns: LANDING_PAGE_NO_MENU_TRANSLATION_NAMESPACE })}</H3>
              ) : (
                <H3>{t("no_permissions_text", { ns: LANDING_PAGE_NO_PERMISSIONS_TRANSLATION_NAMESPACE })}</H3>
              )}
            </Flex>

            <Flex pt={apolloSpaceStack3X} pb={apolloSpaceStack2X}>
              {theme === "light" ? <BackgroundLight /> : <BackgroundDark />}
            </Flex>

            {isAmplifyUser ? (
              <Text variant="displayData">
                {t("container_content", { ns: LANDING_PAGE_NO_MENU_TRANSLATION_NAMESPACE })}
              </Text>
            ) : (
              <Text variant="displayData">
                {t("instructions_text", { ns: LANDING_PAGE_NO_PERMISSIONS_TRANSLATION_NAMESPACE })}
              </Text>
            )}
            {isAmplifyUser && (
              <Link id="amplify-sign-out" as="button" onClick={handleOnClick}>
                {t("sign_out", { ns: LANDING_PAGE_NO_MENU_TRANSLATION_NAMESPACE })}
              </Link>
            )}
          </Flex>
        </Container>
      </MainContent>
    </MainContainer>
  );
};

export default LandingPageNoMenu;
