import type { ProductShellSharedContext } from "@nexthink/product-shell-library";
import { type FC, useEffect, useRef } from "react";
import { usePortalContext } from "./context/PortalContext";
import { useProductShellContext } from "./context/ProductShellContext";
import { useGetApplyCacheBustingToUrl } from "./hooks/useGetApplyCacheBustingToUrl";
import { resetJs } from "./utils";

type PortalType = {
  _onWindowUnload: () => void;
};
type PortalConstructor = new (...args: unknown[]) => PortalType;
declare global {
  interface Window {
    portal: PortalConstructor;
  }
}

export const PORTAL_POPUP_ARTIFACTS_CLASSNAMES = ["pika-single", "qtip", "ui-widget"];

function importExternal(url: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;
    script.async = url.startsWith("/portal/");
    script.onload = resolve;
    script.onerror = reject;
    document.body.appendChild(script);
  });
}

// load all Portal dependencies
async function loadDeps(portalVersion: string, applyCacheBustingToUrl: (url: string, portalVersion: string) => string) {
  if (!document.querySelector(`script[src='${applyCacheBustingToUrl("/portal/main.js", portalVersion)}']`)) {
    // changes introduced by PRT-6294 released with 6.30.12.0
    // can be removed with 2022.10 or later
    const newPath = portalVersion.startsWith("9999.9999.9999.") || Number(portalVersion.replace(/\./g, "")) >= 630120;
    const jqueryPath = newPath ? "/webjars/jquery/dist/jquery.min.js" : "/webjars/jquery/jquery.min.js";
    const jqueryUiPath = newPath ? "/webjars/jquery-ui/dist/jquery-ui.min.js" : "/webjars/jquery-ui/jquery-ui.min.js";

    await Promise.all(
      [
        "/portal/main.js",
        "/portal/vendors-main.js",
        "/portal/admin-main.js",
        jqueryPath,
        "/webjars/prototype/prototype.js",
        "/lib/effects.min.js",
        "/lib/dragdrop.min.js",
        jqueryUiPath,
        "/webjars/jqgrid/js/minified/jquery.jqGrid.min.js",
        "/lib/ui.multiselect.js",
        "/lib/grid.locale-en.js",
        "/lib/jquery.scrollbar.min.js",
        "/lib/selectonic.min.js",
        "/lib/jquery.dotdotdot.min.js",
        "/lib/jquery.qtip.min.js",
        "/lib/insertAdjacentElement.js",
        "/lib/ajaxuploader.js",
        "/lib/controls.min.js",
        "/lib/quadprog.js",
        "/lib/firebugx.js",
        "/webjars/markdown-it/dist/markdown-it.min.js",
        "/webjars/markdown-it-container/dist/markdown-it-container.min.js",
      ]
        .map((url) => applyCacheBustingToUrl(url, portalVersion))
        .map(importExternal)
    ).catch((e) => console.error(e));
  }
}

// load Portal styles (dark or light theme)
async function loadStylesheet(url: string) {
  return new Promise((resolve, reject) => {
    const stylesheet = document.createElement("link");
    stylesheet.id = "portal-css";
    stylesheet.href = url;
    stylesheet.rel = "stylesheet";
    stylesheet.onload = resolve;
    stylesheet.onerror = reject;
    document.head.appendChild(stylesheet);
  });
}

export const removeDivsByClassName = (className: string) => {
  const elements = document.querySelectorAll(`body > .${className}`);
  // biome-ignore lint/complexity/noForEach: <explanation>
  elements.forEach((element) => document.body.removeChild(element));
};

const PortalWrapper: FC<ProductShellSharedContext> = ({ theme, history, refreshMenu }) => {
  const { withSaml, withSso, version: svnVersion } = usePortalContext();
  const { serviceConfig } = useProductShellContext();

  const container = useRef(null);
  const { applyCacheBustingToUrl } = useGetApplyCacheBustingToUrl();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    let portalInstance: PortalType | null = null;

    const createPortal = async () => {
      if (container.current) {
        try {
          const url = applyCacheBustingToUrl(`/portal/css/portal${theme === "dark" ? "-dark" : ""}.css`, svnVersion);
          await loadStylesheet(url);
          await loadDeps(svnVersion, applyCacheBustingToUrl);
          portalInstance = new window.portal(
            container.current,
            history,
            refreshMenu,
            svnVersion,
            withSso,
            withSaml,
            theme,
            serviceConfig?.productId,
            serviceConfig?.licenseId
          );
        } catch (e) {
          console.error(e);
        }
      }
    };
    createPortal();

    return () => {
      portalInstance?._onWindowUnload();
      resetJs();
      // remove css to avoid any styles conflicts
      const el = document.head.querySelector("#portal-css");
      if (el) {
        document.head.removeChild(el);
      }
      // remove divs created by popups ( .pika-single | .qtip | .ui-widget )
      for (const className of PORTAL_POPUP_ARTIFACTS_CLASSNAMES) {
        removeDivsByClassName(className);
      }
    };
  }, [history, serviceConfig?.licenseId, serviceConfig?.productId, svnVersion, theme, withSaml, withSso]);

  return <div ref={container} />;
};

export default PortalWrapper;
