import { render } from "react-dom";
import ProductShell from "./ProductShell";

// Inter font files
import "@nexthink/apollo-components/lib/fonts.css";
// for focus global style
import "focus-visible";
// Load observability tools
import { initObservability } from "./observability";

initObservability();
render(<ProductShell />, document.getElementById("root"));
