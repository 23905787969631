import Box from "@nexthink/apollo-components/lib/components/box/Box";
import { Tabs } from "@nexthink/apollo-components/lib/components/tabs";
import { apolloSpaceStack2X } from "@nexthink/apollo-components/lib/tokens";
import styled from "styled-components";

export const StyledTabs = styled(Tabs)({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  overflow: "auto",
});

export const StyledPanelContent = styled(Box)({
  overflow: "auto",
  paddingTop: apolloSpaceStack2X,
});
