import type { CancelToken } from "axios";
import { useCallback } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useProductShellContext } from "../context/ProductShellContext";
import { reportError } from "../observability";
import ProductShellService, {
  type ProductShellApiResult,
  type ValidateClaimsBody,
} from "../services/productShellService";
import type { MenuResponse } from "../services/types/menu";
import type { PortalMenuResponse } from "../services/types/portal-menu";
import type { ServiceConfig } from "../services/types/service-config";
import { ErrorTypes, redirectToLogin } from "../utils";
import { resetNxCache } from "./useCachedMethod";
import { useSendLogoutToAmplify } from "./useSendToAmplify";

const productShellService = new ProductShellService();

export type useProductShellServiceReturnedType = {
  getLDFlagValue: (flag: string, cancelToken?: CancelToken) => Promise<boolean>;
  getProductShellStaticMenus: (
    portalMenuResponse: PortalMenuResponse | null,
    cancelToken?: CancelToken
  ) => Promise<MenuResponse | null>;
  getMenuLogo: (cancelToken?: CancelToken) => Promise<string | null>;
  getProductShellDynamicMenu: (menuId: string, cancelToken?: CancelToken) => Promise<MenuResponse | null>;
  validateClaims: (data: ValidateClaimsBody, cancelToken?: CancelToken) => Promise<boolean>;
  getServiceConfig: (cancelToken?: CancelToken) => Promise<ServiceConfig | null>;
  setPSApiAuthInterceptorFn: (tokenFn: (cancelToken?: CancelToken) => Promise<string | null>) => void;
};

const useProductShellService = (): useProductShellServiceReturnedType => {
  const { lang, setServiceError } = useProductShellContext();
  const { setIsAuthenticated, hasPortal, portalWithSaml } = useAuthContext();
  const amplifyLogoutFn = useSendLogoutToAmplify();

  const processResult = useCallback(
    <T,>(result: ProductShellApiResult<T>, serviceName: string) => {
      if (result.isStatusUnauthorized()) {
        amplifyLogoutFn();
        resetNxCache();
        setIsAuthenticated(false);
        // FIXME handle behaviour with oktaAuth
        if (hasPortal) {
          redirectToLogin(portalWithSaml);
        }
      } else if (result.hasError() && !result.isAxiosCancel()) {
        setServiceError(
          new Error(`${serviceName} response error, resultStatus=${JSON.stringify(result.resultStatus)}`)
        );
      }
    },
    [amplifyLogoutFn, setIsAuthenticated, hasPortal, portalWithSaml, setServiceError]
  );

  const getMenuLogo = useCallback((cancelToken?: CancelToken) => {
    return productShellService.getMenuLogo(cancelToken);
  }, []);

  const getLDFlagValue = useCallback(
    async (flag: string, cancelToken?: CancelToken) => {
      const response = await productShellService.getLDFlagValue(flag, cancelToken);
      processResult(response, `getLDFlagValue=${flag}`);
      return response.result ? response.result[flag] : false;
    },
    [processResult]
  );

  const getProductShellStaticMenus = useCallback(
    async (portalMenuResponse: PortalMenuResponse | null, cancelToken?: CancelToken) => {
      productShellService.setLang(lang);
      const productShellMenu = await productShellService.getMenu(portalMenuResponse, cancelToken);
      processResult(productShellMenu, "getProductShellStaticMenus");

      return productShellMenu.result;
    },
    [processResult, lang]
  );

  const getProductShellDynamicMenu = useCallback(
    async (menuId: string, cancelToken?: CancelToken) => {
      productShellService.setLang(lang);
      //Removed processResult() to avoid blocking the user from navigating to other apps when there is an error
      try {
        const productShellMenu = await productShellService.getDynamicMenu(menuId, cancelToken);
        return productShellMenu.result;
      } catch (e) {
        reportError(e, {
          errorType: ErrorTypes.serviceError,
        });
        return null;
      }
    },
    [lang]
  );

  const getServiceConfig = useCallback(
    async (cancelToken?: CancelToken) => {
      const serviceConfigResponse = await productShellService.getServiceConfig(cancelToken);
      processResult(serviceConfigResponse, "getServiceConfig");

      return serviceConfigResponse.result;
    },
    [processResult]
  );

  const validateClaims = useCallback(
    async (data: ValidateClaimsBody, cancelToken?: CancelToken) => {
      const claimsResponse = await productShellService.validateClaims(data, cancelToken);
      processResult(claimsResponse, `validateClaims=${JSON.stringify(data)}`);

      return claimsResponse.result?.result || false;
    },
    [processResult]
  );

  // FIXME use getToken for setting the interceptor instead
  const setPSApiAuthInterceptorFn = useCallback((tokenFn: (cancelToken?: CancelToken) => Promise<string | null>) => {
    productShellService.setApiAuthInterceptorFn(tokenFn);
  }, []);

  return {
    getLDFlagValue,
    getMenuLogo,
    validateClaims,
    getProductShellStaticMenus,
    getProductShellDynamicMenu,
    getServiceConfig,
    setPSApiAuthInterceptorFn,
  };
};

export default useProductShellService;
