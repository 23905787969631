import { useProductShellContext } from "../context/ProductShellContext";
import type { ServiceConfig } from "../services/types/service-config";

export const useGetServiceConfig = (): ServiceConfig => {
  const { serviceConfig } = useProductShellContext();

  if (!serviceConfig) {
    throw new Error("Service config is not available"); // will never happen but TS doesn't know that
  }
  return serviceConfig;
};
