import type { ProductShellSharedContext } from "@nexthink/product-shell-library";
import type { CommandBus, EventBus } from "eventDrivenBusEvents";
import { isEqual } from "lodash";
import { type FC, memo, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { getActiveItemsByUrl, getPageTitle } from "../Navigation/util";
import { useMenuContext } from "../context/MenuContext";
import { useProductShellContext } from "../context/ProductShellContext";
import ErrorBoundary from "../errors/ErrorBoundary/ErrorBoundary";
import ErrorPage from "../errors/ErrorPage/ErrorPage";
import useDynamicScript, { type UseDynamicScriptReturnType } from "../hooks/useDynamicScript";
import { type ObservabilityMethods, reportError } from "../observability";
import { ElementLoadError, ErrorTypes, getPropsAreEqual } from "../utils";
import { StyledContainer } from "./DynamicComponent.style";

declare global {
  interface Window {
    default: FC<ProductShellSharedContext>;
  }
}

export interface DynamicComponentProps extends ProductShellSharedContext {
  jsFile: string;
  cssFiles?: string[];
  jsResources?: string[];
  observability?: ObservabilityMethods;
  localEventBus?: EventBus;
  commandBus?: CommandBus;
}

export interface DynamicComponentPureProps extends DynamicComponentProps {
  pathname: string;
  ready: UseDynamicScriptReturnType["ready"];
  error: UseDynamicScriptReturnType["error"];
  component: UseDynamicScriptReturnType["component"];
}

const DynamicComponentPure: FC<DynamicComponentPureProps> = memo(
  function DynamicComponentPure({ ready, error, component: Component, ...rest }) {
    if (!ready) {
      return <StyledContainer alignItems={"center"} />;
    }

    if (error || (ready && !Component)) {
      const newRelicError = error || new ElementLoadError(`could not load ${rest.jsFile}`, rest.jsFile);
      reportError(newRelicError, { file: newRelicError.file, errorType: "fileLoad" });
      return <ErrorPage id="dynamic-content-loader-error" />;
    }

    return Component ? <Component {...rest} /> : null; // should never happen but TypeScript does not know that :)
  },
  getPropsAreEqual((prevValue, nextValue) => isEqual(prevValue, nextValue))
);

const DynamicComponent: FC<DynamicComponentProps> = (props) => {
  const { pathname } = useLocation();
  const { setPageTitle } = useProductShellContext();
  const { menu } = useMenuContext();

  const { ready, error, component } = useDynamicScript({
    url: props.jsFile,
    cssFiles: props.cssFiles,
    jsResources: props.jsResources,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (menu) {
      setPageTitle(getPageTitle(menu, getActiveItemsByUrl(menu, pathname)));
    }
  }, [menu, setPageTitle]);

  return (
    <ErrorBoundary newRelicErrorType={ErrorTypes.microFrontendError}>
      <DynamicComponentPure
        {...{
          ...props,
          pathname,
          ready,
          error,
          component,
        }}
      />
    </ErrorBoundary>
  );
};

export default DynamicComponent;
