import { type FC, useCallback, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { CHECK_SESSION_FLAG } from "../Navigation/constants";
import { useAuthContext } from "../context/AuthContext";
import usePortalService from "../hooks/usePortalService";
import useProductShellService from "../hooks/useProductShellService";
import { redirectToLogin } from "../utils";

export const IDLE_CHECK_INTERVAL = 1000 * 60 * 6; // 6 minutes

const PortalAuth: FC = ({ children }) => {
  const { isAuthenticated, setIsAuthenticated, setGetToken, setLogoutFn, portalWithSaml } = useAuthContext();
  const { logoutPortal, getPortalToken, getCSRFToken, getAccountInfo: checkSessionValidity } = usePortalService();
  const { setPSApiAuthInterceptorFn, getLDFlagValue } = useProductShellService();
  const [sessionCheckEnabled, setSessionCheckEnabled] = useState(false);

  // call getAccountInfo and discard result to force checking whether the user session is still valid or not
  // getAccountInfo is run through processResult() which will take care of redirecting to the login page if necessary
  const onActive = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }
    if (sessionCheckEnabled) {
      checkSessionValidity();
    }
  }, [isAuthenticated, checkSessionValidity, sessionCheckEnabled]);

  useIdleTimer({
    onActive,
    timeout: IDLE_CHECK_INTERVAL,
    crossTab: true,
  });

  useEffect(() => {
    const getCSRFTokenFn = async () => {
      // get csrf token to have it in localStorage
      const response = await getCSRFToken();

      if (response) {
        setGetToken(() => getPortalToken);
        // FIXME use getToken for setting the interceptor instead
        setPSApiAuthInterceptorFn(() => getPortalToken());
        setLogoutFn(() => logoutPortal);
        setIsAuthenticated(true);
        setSessionCheckEnabled(await getLDFlagValue(CHECK_SESSION_FLAG));
      } else {
        // in case of invalid session redirect to login page
        redirectToLogin(portalWithSaml);
      }
    };

    if (!isAuthenticated) {
      getCSRFTokenFn();
    }
  }, [
    getCSRFToken,
    getPortalToken,
    isAuthenticated,
    setIsAuthenticated,
    portalWithSaml,
    setGetToken,
    setPSApiAuthInterceptorFn,
    setLogoutFn,
    logoutPortal,
    getLDFlagValue,
  ]);

  return isAuthenticated ? <>{children}</> : null;
};

export default PortalAuth;
