import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import {
  apolloFontSizeVerySmall,
  apolloSpaceInset4X,
  apolloSpaceInset5X,
} from "@nexthink/apollo-components/lib/tokens";
import { getValueFromPixelString } from "@nexthink/apollo-components/lib/utils/TokenHelpers";
import type { CSSProperties, HTMLAttributes } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import Link from "../Link";

const selectedFocusedBackgroundOffset = 8;
const borderStyle = "2px solid transparent";

export const StyledMenuIconBase = styled(Link)<
  HTMLAttributes<HTMLAnchorElement> & { isActive: boolean; isMenuSearch?: boolean }
>(({ isMenuSearch }) => ({
  "&&": {
    alignItems: "center",
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    outline: "none !important",
    overflow: "hidden",
    padding: 0,
    position: "relative",
    textAlign: "center",
    svg: {
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%,-50%)",
      outline: "none !important",
    },
    height: isMenuSearch ? apolloSpaceInset5X : "20px",
    width: "20px",
    border: "none",
    margin: "0 10px",
    borderRadius: "8px",
  },
}));

export const StyledMenuIconBaseContainer = styled.li<{
  isActive: boolean;
  isMenuSearch?: boolean;
}>(({ theme, isActive, isMenuSearch }) => ({
  display: "inline-flex",
  overflow: "hidden",
  alignItems: "center",
  ...(isActive && { background: theme.colors.apolloColorBackgroundMainNavBarItemSelected }),
  borderRadius: "8px",

  height: isMenuSearch ? apolloSpaceInset5X : apolloSpaceInset4X,
  cursor: "pointer",

  "&.focus-visible": {
    background: !isActive && !isMenuSearch ? "none" : undefined,
    outline: `2px ${theme.colors.apolloColorStrokeFocusOnDark} solid !important`,
  },

  "&:hover": {
    ...(!isActive && { background: theme.colors.apolloColorBackgroundMainNavBarItemHover }),

    "#animated-section": {
      animation: "rotate-section 1.4s infinite linear",
      transformOrigin: "5.5px 6px",

      "@keyframes rotate-section": {
        "0%": {
          transform: "rotate(0deg) scale(1)",
          opacity: 1,
        },
        "25%": {
          transform: "rotate(90deg) scale(0.8)",
          opacity: 0.5,
        },
        "50%": {
          transform: "rotate(180deg) scale(1)",
          opacity: 1,
        },
        "75%": {
          transform: "rotate(270deg) scale(0.8)",
          opacity: 0.5,
        },
        "100%": {
          transform: "rotate(360deg) scale(1)",
          opacity: 1,
        },
      },
    },
  },
}));

export const StyledMenuIcon = styled(SVG)<{
  color: CSSProperties["fill"];
  size: CSSProperties["maxWidth"];
  isActive?: boolean;
}>(({ color, size, isActive }) => ({
  fill: color,
  maxWidth: size,
  maxHeight: size,
  outline: "none",

  "& *": {
    fill: color,
  },

  "& #icon-inactive": {
    display: isActive ? "none" : "block",
  },

  "& #icon-active": {
    display: isActive ? "block" : "none",
  },
}));

export const StyledTitleWrapper = styled(Link)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  overflow: "hidden",
  textDecoration: "none",
  marginLeft: "2px",
});

export const StyledTitle = styled.div(({ theme }) => ({
  color: theme.colors.apolloColorTextPrimaryOnDark,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const StyledSubtitle = styled(Flex)({
  fontSize: apolloFontSizeVerySmall,
});

export const StyleSearchMenuIconBase = styled(Link)<HTMLAttributes<HTMLAnchorElement> & { isActive: boolean }>(
  ({ theme, isActive }) => ({
    "&&": {
      alignItems: "center",
      border: borderStyle,
      boxSizing: "border-box",
      cursor: "pointer",
      display: "flex",
      flexShrink: 0,
      justifyContent: "center",
      outline: "none",
      overflow: "hidden",
      padding: 0,
      position: "relative",
      textAlign: "center",
      height: apolloSpaceInset5X,
      width: apolloSpaceInset5X,

      borderRadius: "20%",

      "&.focus-visible": {
        background: isActive ? "none" : undefined,
        borderColor: theme.colors.apolloColorStrokeFocusOnDark,
        outline: "none !important",

        "&:before": {
          background: theme.colors.apolloColorBackgroundMainNavItemSelected,
          borderRadius: "20%",
          content: '""',
          display: isActive ? "block" : "none",
          left: "50%",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          height: apolloSpaceInset4X,

          width: getValueFromPixelString(apolloSpaceInset5X) - selectedFocusedBackgroundOffset,
          zIndex: -1,
        },
      },
    },
  })
);

export const StyledAssistIconWrapper = styled.div({
  height: "20px",
});

export const StyledAssistLensContainer = styled.div({
  marginLeft: "1px",
  marginTop: "1px",
});

export const StyledAssistStarIconAnimated = styled(SVG)<{
  color: CSSProperties["fill"];
  size: CSSProperties["maxWidth"];
}>(({ color, size }) => ({
  fill: color,
  maxWidth: size,
  maxHeight: size,
  marginTop: ".5px",
  outline: "none",
  position: "absolute",

  "& *": {
    fill: color,
  },
  display: "block",
}));

export const StyledAssistPairIcon = styled(SVG)<{
  color: CSSProperties["fill"];
  size: CSSProperties["maxWidth"];
  isActive?: boolean;
}>(({ size }) => ({
  outline: "none",
  maxWidth: size,
  maxHeight: size,
}));
