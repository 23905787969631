import { useGetMenuResponse } from "../../hooks/useGetMenuResponse";
import type { Menu } from "../../services/types/menu";
import { PANEL_SEARCH_MIN_MENU_ITEMS_COUNT } from "../constants";

export const useShouldMenuHaveSearch = (menu: Menu): boolean => {
  const menuResponse = useGetMenuResponse();
  const countItems = menuResponse?.items.filter((menuItem) => menuItem.menuId === menu.id).length || 0;
  const isDynamicMenu = !!menu.apiUrl;

  return isDynamicMenu && countItems >= PANEL_SEARCH_MIN_MENU_ITEMS_COUNT;
};
