import { useMemo } from "react";
import { useProductShellContext } from "../context/ProductShellContext";
import type { ServiceConfig } from "../services/types/service-config";

export const useGetMtpFlags = (): Pick<ServiceConfig, "noFinder" | "noEngines" | "noPortal" | "noV6Permissions"> => {
  const { serviceConfig } = useProductShellContext();
  return useMemo(
    () => ({
      noFinder: serviceConfig?.noFinder || false,
      noEngines: serviceConfig?.noEngines || false,
      noPortal: serviceConfig?.noPortal || false,
      noV6Permissions: serviceConfig?.noV6Permissions || false,
    }),
    [serviceConfig]
  );
};
