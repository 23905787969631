import property from "lodash/property";
import { useCallback } from "react";
import type { MenuResponse } from "../services/types/menu";

export const useGetIsFeatureEnabled = (menu: MenuResponse | null): ((id: string) => boolean) => {
  const appIds = (menu?.apps || []).map(property("id")).sort((a: string, b: string) => a.localeCompare(b));
  const appIdsJson = JSON.stringify(appIds);

  return useCallback(
    (id: string): boolean => {
      const featureId = id.toLowerCase();
      return JSON.parse(appIdsJson).includes(featureId);
    },
    [appIdsJson]
  );
};
