import type { IconName } from "@nexthink/apollo-components/lib/components/icons/Icon";
import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { useContext } from "react";
import { StyledApolloIcon, StyledSvg, StyledUrlIcon } from "./Icon.style";

export type IconSize = "sm" | "md" | "lg" | "xl";
export interface IconProps {
  size?: IconSize;
  title: string;
  icon: string; // apollo-components.IconName or URL
  color?: string;
  alt?: string;
  isHidden?: boolean;
}

export const IconPixelSizeFromSize: Record<IconSize, string> = {
  sm: "16px",
  md: "20px",
  lg: "38px",
  xl: "40px",
};

const Icon = ({ size = "sm", icon, color, title, alt, isHidden = false }: IconProps) => {
  const theme = useContext(ThemeContext);
  const isIconUrl = icon.includes("/") || /^data:image\/(png|jpg|jpeg|gif|svg)\+xml;base64,.+/.test(icon);
  const isSvg = icon.endsWith(".svg") || /^data:image\/svg\+xml;base64,.+/.test(icon);

  return isIconUrl ? (
    isSvg ? (
      <StyledSvg
        title={title}
        src={icon}
        tabIndex={-1}
        size={IconPixelSizeFromSize[size]}
        color={color}
        aria-hidden={true}
        isHidden={isHidden}
      />
    ) : (
      <StyledUrlIcon
        title={title}
        src={icon}
        tabIndex={-1}
        size={IconPixelSizeFromSize[size]}
        alt={alt}
        isHidden={isHidden}
      />
    )
  ) : (
    <StyledApolloIcon
      aria-label={title}
      size={size}
      color={color || theme.colors.apolloColorTextPrimary}
      name={icon as IconName}
      isHidden={isHidden}
    />
  );
};

export default Icon;
