import { Button } from "@nexthink/apollo-components";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import React, { type FC, useEffect, useState, useRef } from "react";
import Icon from "../components/Icon";
import { DEVICE_LOCATOR_DIALOG_ID } from "../dialogs/DeviceLocatorDialog/DeviceLocatorDialog";
import { SEARCH_APP_DIALOG_ID } from "../dialogs/SearchAppDialog/SearchAppDialog";
import { useGetMenuResponse } from "../hooks/useGetMenuResponse";
import { useGetServiceConfigString } from "../hooks/useGetServiceConfigString";
import useProductShellService from "../hooks/useProductShellService";
import { reportError } from "../observability";
import { isSearchAppPresent } from "../utils";
import {
  StyledAccountContainer,
  StyledDiv,
  StyledFixedBottomArrowIcon,
  StyledFixedTopContent,
  StyledList,
  StyledLogoAndAccountWrapper,
  StyledMainLogo,
  StyledMainLogoContainer,
  StyledMenuContentContainer,
  StyledMenuIconDialogSearch,
  StyledNavigation,
  StyledPaddingWrapper,
  StyledScrollUpArrow,
  StyledSeparator,
  StyledSkipToContentContainer,
} from "./BlueBar.style";
import MenuIcon from "./MenuIcon/MenuIcon";
import MenuIconDialog from "./MenuIcon/MenuIconDialog";
import { useProductShellNavigationContext } from "./ProductShellNavigationContext";
import {
  DEVICE_LOCATOR_MENU_ID,
  NAVIGATION_TRANSLATION_NAMESPACE,
  SEARCH_TITLE_KEY,
  SKIP_TO_MAIN_CONTENT_TITLE_KEY,
} from "./constants";
import { useLogoUpdateTrigger } from "./hooks/useLogoUpdateTrigger";
import { focusMainContent } from "./util";

const DEFAULT_MENU_LOGO = "/static/public/product-shell/menu-icons/menu-logo-default.svg";
const NAV_PANEL_MAIN_LOGO_TRANSLATION_NAMESPACE = "navigation-panel-action-text";

const BlueBar: FC = () => {
  const [menuLogo, setMenuLogo] = useState<string | null>(null);
  const { logoUpdateSignal } = useLogoUpdateTrigger();
  const menuResponse = useGetMenuResponse();
  const serviceConfigString = useGetServiceConfigString();
  const { isBlueBarExpanded, openedMenuId } = useProductShellNavigationContext();
  const { getMenuLogo } = useProductShellService();
  const shouldShowSearch = Boolean(menuResponse && isSearchAppPresent(menuResponse));

  const { t } = useNxTranslation();

  const contentGroupMenuItems = menuResponse?.groupedMenus?.filter(
    (group) => group.type !== "content_group_5" && group.type.startsWith("content_")
  );
  const deviceLocatorItem = menuResponse?.groupedMenus
    ?.find((group) => group.type === "content_group_5")
    ?.menuItems.find((menu) => menu.id === DEVICE_LOCATOR_MENU_ID);
  const topbarGroupMenuItems = menuResponse?.groupedMenus?.find((group) => group.type === "topbar");
  const searchGroupMenuItem = menuResponse?.groupedMenus?.find((group) => group.type === "search")?.menuItems[0];

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const logo = await getMenuLogo();
        setMenuLogo(logo);
      } catch (e) {
        setMenuLogo(DEFAULT_MENU_LOGO);
        reportError(e, { feature: "getMenuLogo" });
      }
    };
    fetchLogo();
  }, [getMenuLogo, logoUpdateSignal]);

  const scrollContainer = useRef<HTMLDivElement>(null);
  const [showArrowUp, setShowArrowUp] = useState<boolean>(false);
  const [showArrowDown, setShowArrowDown] = useState<boolean>(false);

  const checkOverflow = () => {
    const container = scrollContainer.current;
    if (!container) {
      return;
    }

    const canScrollUp = container.scrollTop > 0;
    const canScrollDown = container.scrollHeight > container.clientHeight + container.scrollTop;

    setShowArrowUp(canScrollUp);
    setShowArrowDown(canScrollDown);
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    const container = scrollContainer.current;
    container?.addEventListener("scroll", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
      if (container) {
        container.removeEventListener("scroll", checkOverflow);
      }
    };
  });

  let isFirstContentGroup = true;

  return (
    <StyledNavigation
      isExpanded={isBlueBarExpanded}
      isPanelOpened={openedMenuId !== null}
      aria-label="Main navigation"
      data-testid="blue-bar"
    >
      <StyledSkipToContentContainer>
        <Button
          id="skip-to-main-content-button"
          variant="tertiary"
          onClick={focusMainContent}
          aria-label={t(SKIP_TO_MAIN_CONTENT_TITLE_KEY, { ns: NAVIGATION_TRANSLATION_NAMESPACE })}
          data-testid="skip-to-main-content"
        >
          {t(SKIP_TO_MAIN_CONTENT_TITLE_KEY, { ns: NAVIGATION_TRANSLATION_NAMESPACE })}
        </Button>
      </StyledSkipToContentContainer>

      <StyledMenuContentContainer
        isExpanded={isBlueBarExpanded}
        ref={scrollContainer}
        tabIndex={-1}
        data-testid="menu-container"
      >
        <StyledFixedTopContent isExpanded={isBlueBarExpanded}>
          <StyledLogoAndAccountWrapper>
            <StyledMainLogoContainer>
              <StyledMainLogo id="ps-logo" to="/" isExpanded={isBlueBarExpanded} aria-label="Go to Home Page">
                <Icon
                  size="lg"
                  icon={menuLogo ?? ""}
                  title={`Nexthink Infinity ${serviceConfigString}\n${t("main_logo", { ns: NAV_PANEL_MAIN_LOGO_TRANSLATION_NAMESPACE })}`}
                  alt={"Nexthink Infinity"}
                  data-testid="main-logo"
                />
              </StyledMainLogo>
            </StyledMainLogoContainer>

            <StyledAccountContainer id="myaccount">
              {topbarGroupMenuItems?.menuItems.map((menu) => {
                return (
                  <MenuIcon
                    aria-hidden="true"
                    key={`menu-icon-${menu.id}`}
                    menuId={menu.id}
                    hideTitle
                    data-testid="topbar-menu-icon"
                  />
                );
              })}
            </StyledAccountContainer>
          </StyledLogoAndAccountWrapper>

          {shouldShowSearch && (
            <StyledPaddingWrapper isSearchItem>
              {searchGroupMenuItem && (
                <StyledDiv>
                  <StyledMenuIconDialogSearch
                    key={`menu-icon-${searchGroupMenuItem.id}`}
                    id="MNV_search-menu"
                    dialogId={SEARCH_APP_DIALOG_ID}
                    icon={searchGroupMenuItem.icon ?? ""}
                    title={searchGroupMenuItem.title ?? t(SEARCH_TITLE_KEY, { ns: NAVIGATION_TRANSLATION_NAMESPACE })}
                    isSearchItem={searchGroupMenuItem.id}
                    data-testid="search-menu-icon"
                  />
                  <StyledSeparator isSearchItem />
                </StyledDiv>
              )}
            </StyledPaddingWrapper>
          )}

          {(showArrowDown || showArrowUp) && (
            <StyledScrollUpArrow showIcon={showArrowUp}>
              {showArrowUp && (
                <span data-testid="arrow-up">
                  <Icon size="sm" icon="largeAngleUp" title="Scroll up" alt="Scroll up" color="white" />
                </span>
              )}
            </StyledScrollUpArrow>
          )}
        </StyledFixedTopContent>

        <StyledPaddingWrapper>
          <StyledList role="menubar">
            {contentGroupMenuItems?.map((group) => {
              const renderSeparator = !isFirstContentGroup;
              isFirstContentGroup = false;
              return (
                <React.Fragment key={`section-${group.type}`}>
                  {renderSeparator && <StyledSeparator />}
                  {group.menuItems.map((menu) => (
                    <MenuIcon key={`menu-icon-${menu.id}`} menuId={menu.id} data-testid="content-menu-icon" />
                  ))}
                </React.Fragment>
              );
            })}

            {deviceLocatorItem && (
              <>
                <StyledSeparator key={"separator-content_group_5"} />
                <MenuIconDialog
                  key={`menu-icon-${deviceLocatorItem.id}`}
                  id="MNV_device-locator"
                  dialogId={DEVICE_LOCATOR_DIALOG_ID}
                  icon={deviceLocatorItem.icon}
                  title={deviceLocatorItem.title}
                  data-testid="device-locator-menu-icon"
                />
              </>
            )}
          </StyledList>
        </StyledPaddingWrapper>

        {(showArrowUp || showArrowDown) && (
          <StyledFixedBottomArrowIcon showIcon={showArrowDown}>
            {showArrowDown && (
              <span data-testid="arrow-down">
                <Icon size="sm" icon="largeAngleDown" title="Scroll up" alt="Scroll up" color="white" />
              </span>
            )}
          </StyledFixedBottomArrowIcon>
        )}
      </StyledMenuContentContainer>
    </StyledNavigation>
  );
};

export default BlueBar;
