import { Container } from "@nexthink/apollo-components";
import styled from "styled-components";
import { MENU_WIDTH_COLLAPSED } from "../Navigation/BlueBar.style";

export const StyledContainer = styled(Container)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: MENU_WIDTH_COLLAPSED,
  right: 0,
  bottom: 0,

  backgroundColor: theme.colors.apolloColorBackgroundDashboard,

  opacity: 1,

  alignItems: "center",
  justifyContent: "center",
}));
