import Spinner from "@nexthink/apollo-components/lib/components/loader/Spinner";
import { type FC, useEffect, useRef, useState } from "react";
import { LOADING_TRANSITION_DURATION, StyledLoadingOverlayContainer } from "./LoadingOverlay.style";

export interface LoadingOverlayProps {
  isVisible: boolean;
  removeBlueBarPadding?: boolean;
}

const LoadingOverlay: FC<LoadingOverlayProps> = ({ isVisible }) => {
  const [isMounted, setIsMounted] = useState(false);
  const timeoutRef = useRef(0);

  useEffect(() => {
    if (isVisible) {
      setIsMounted(true);
    } else {
      timeoutRef.current = window.setTimeout(() => {
        setIsMounted(false);
      }, LOADING_TRANSITION_DURATION);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isVisible]);

  return isMounted ? (
    <StyledLoadingOverlayContainer isVisible={isVisible}>
      <Spinner />
    </StyledLoadingOverlayContainer>
  ) : null;
};

export default LoadingOverlay;
