import { Box } from "@nexthink/apollo-components";
import {
  apolloFontFamilyBody,
  apolloFontSizeBody,
  apolloFontWeightBody,
  apolloLineHeightBody,
  apolloSpaceInset1X,
  apolloSpaceStack1X,
  apolloSpaceStack2X,
  apolloZindexDialog,
} from "@nexthink/apollo-components/lib/tokens";
import type { CSSProperties } from "react";
import styled from "styled-components";
import { IconPixelSizeFromSize } from "../components/Icon";
import Link from "./Link";
import MenuIconDialog from "./MenuIcon/MenuIconDialog";
import { TRANSITION_DELAY, TRANSITION_DURATION } from "./Panel/Panel.style";

export const MENU_WIDTH_COLLAPSED = 56;
export const MENU_WIDTH_EXPANDED = 210;

export const searchStyle: CSSProperties = {
  margin: `${apolloSpaceStack2X} 0`,
};

export const StyledNavigation = styled.nav<{
  isExpanded: boolean;
  isPanelOpened: boolean;
}>(({ theme, isExpanded, isPanelOpened }) => ({
  alignItems: "start",
  background: theme.colors.apolloColorBackgroundMainNavBar,
  boxSizing: "border-box",
  color: theme.colors.apolloColorTextPrimaryOnDark,
  display: "flex",
  alignContent: "flex-start",
  flexDirection: "column",
  flexWrap: "wrap",
  font: `${apolloFontWeightBody} ${apolloFontSizeBody}/${apolloLineHeightBody} ${apolloFontFamilyBody}`,
  height: "100%",
  position: "relative",
  zIndex: apolloZindexDialog,
  width: MENU_WIDTH_COLLAPSED,
  transition: `width ${TRANSITION_DURATION} linear 0s`,

  "&:focus-within": {
    width: MENU_WIDTH_EXPANDED,
    boxShadow: isPanelOpened ? "none" : theme.colors.apolloBoxShadowMainNavExpanded,
    transition: isExpanded
      ? "none"
      : `width ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}, box-shadow ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}`,
  },

  "&:hover": {
    width: MENU_WIDTH_EXPANDED,
    boxShadow: isPanelOpened ? "none" : theme.colors.apolloBoxShadowMainNavExpanded,
    transition: isExpanded
      ? "none"
      : `width ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}, box-shadow ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}`,
  },

  ...(isExpanded && {
    width: MENU_WIDTH_EXPANDED,
    transition: "none",
  }),

  ...(isExpanded &&
    !isPanelOpened && {
      boxShadow: theme.colors.apolloBoxShadowMainNavExpanded,
    }),
}));

export const StyledSkipToContentContainer = styled(Box)({
  position: "absolute",
  left: "-1000px",
  top: apolloSpaceStack1X,
  width: "inherit",
  zIndex: 2,

  "&:focus-within": {
    left: apolloSpaceStack1X,
  },
});

export const StyledMenuContentContainer = styled.div<{ isExpanded: boolean }>(({ isExpanded }) => ({
  overflow: "auto",
  position: "relative",
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",

  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },

  "&:focus-within": {
    ".child": {
      width: `calc(${MENU_WIDTH_EXPANDED} - "8px")`,
      transition: isExpanded
        ? "none"
        : `width ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}, box-shadow ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}`,
    },

    "#myaccount": {
      visibility: "visible",
      position: "inherit",
    },
  },

  "&:hover": {
    ".child": {
      width: `calc(${MENU_WIDTH_EXPANDED} - "8px")`,
      transition: isExpanded
        ? "none"
        : `width ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}, box-shadow ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}`,
    },

    "#myaccount": {
      visibility: "visible",
      transitionDelay: `${TRANSITION_DELAY}`,
      position: "inherit",
    },
  },

  ...(isExpanded && {
    "#myaccount": {
      visibility: "visible",
      position: "inherit",
    },
  }),
}));

export const StyledFixedTopContent = styled.ul<{ isExpanded: boolean }>(({ isExpanded, theme }) => ({
  listStyle: "none",
  position: "sticky",
  zIndex: 1,
  top: 0,
  background: theme.colors.apolloColorBackgroundMainNavBar,
  padding: 0,
  marginTop: 0,
  marginBottom: "2px",

  "&:hover .child": {
    width: `calc(${MENU_WIDTH_EXPANDED} - "8px")`,
    transition: isExpanded
      ? "none"
      : `width ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}, box-shadow ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}`,
  },

  ...(isExpanded && {
    width: `calc(${MENU_WIDTH_EXPANDED} - "8px")`,
    transition: "none",
  }),
}));

export const StyledScrollUpArrow = styled.div<{ showIcon: boolean }>(({ showIcon, theme }) => ({
  display: "none",
  position: "absolute",
  zIndex: 2,
  width: "100%",
  background: theme.colors.apolloColorBackgroundMainNavBar,

  ...(showIcon && {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "16px",
    boxShadow: `0px 4px 5px ${theme.colors.apolloColorStrokeMainNavBarSeparator}`,
  }),
}));

export const StyledFixedBottomArrowIcon = styled.div<{ showIcon: boolean }>(({ showIcon, theme }) => ({
  display: "flex",
  justifyContent: "center",
  position: "sticky",
  zIndex: 1,
  width: "100%",
  bottom: 0,
  background: theme.colors.apolloColorBackgroundMainNavBar,
  minHeight: "16px",

  ...(showIcon && {
    boxShadow: `0px -4px 5px ${theme.colors.apolloColorStrokeMainNavBarSeparator}`,
  }),
}));

export const StyledMainLogoContainer = styled.div({
  display: "block",
});

export const StyledMainLogo = styled(Link)<{
  isExpanded: boolean;
}>(({ theme, isExpanded }) => ({
  display: "flex",
  justifyContent: "center",
  width: IconPixelSizeFromSize.xl,
  height: IconPixelSizeFromSize.xl,
  marginBottom: 0,
  borderRadius: "20%",

  "&.focus-visible": {
    outline: `2px solid ${theme.colors.apolloColorStrokeFocusOnDark} !important`,
  },

  "&:hover": {
    width: `calc(${MENU_WIDTH_EXPANDED} - "8px")`,
    transition: isExpanded
      ? "none"
      : `width ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}, box-shadow ${TRANSITION_DURATION} linear ${TRANSITION_DELAY}`,
  },

  ...(isExpanded && {
    transition: "none",
  }),
}));

export const StyledPaddingWrapper = styled.section<{ isSearchItem?: boolean }>(({ isSearchItem }) => ({
  padding: `0 ${apolloSpaceInset1X}`,
  flexGrow: 1,
  display: "flex",

  ...(isSearchItem && {
    paddingTop: apolloSpaceInset1X,
    paddingBottom: "5px",
  }),
}));

export const StyledLogoAndAccountWrapper = styled.section<{ isSearchItem?: boolean }>(({ isSearchItem }) => ({
  flexGrow: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "relative",
  padding: apolloSpaceInset1X,

  ...(isSearchItem && {
    paddingBottom: "4px",
  }),
}));

export const StyledAccountContainer = styled.section<{ id: string }>({
  visibility: "hidden",
  position: "absolute",
});

export const StyledList = styled.ul({
  display: "flex",
  flexBasis: 0,
  flexDirection: "column",
  flexGrow: 1,
  listStyle: "none",
  margin: 0,
  padding: 0,
  width: "100%",

  "& li": {
    marginBottom: "4px",
  },
});

export const StyledMenuIconDialogSearch = styled(MenuIconDialog)({
  width: "100%",
  height: "40px",
});

export const StyledSeparator = styled.hr<{ isSearchItem?: boolean }>(({ theme, isSearchItem }) => ({
  display: "block",
  height: "1px",
  border: 0,
  borderTop: `1px solid ${theme.colors.apolloColorStrokeMainNavBarSeparator}`,
  margin: "6px 0 11px 0",
  padding: 0,
  ...(isSearchItem && { marginBottom: "0" }),
}));

export const StyledDiv = styled.div({
  display: "block",
  width: "100%",
});
