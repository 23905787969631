import { type FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

import usePortalService from "../hooks/usePortalService";
import type { PortalConfigDto } from "../services/types/portal-config";
import { useAuthContext } from "./AuthContext";
import { useProductShellContext } from "./ProductShellContext";

export type PortalContextProps = PortalConfigDto["globals"] &
  Pick<PortalConfigDto["userProfile"], "informationLevel" | "email" | "profileName"> & {
    isFeatureFlagEnabled: (id: string) => boolean;
  };

export const PortalContext = createContext<PortalContextProps | undefined>(undefined);

export const emptyContext: PortalContextProps = {
  theme: "LIGHT",
  version: "",
  withSaml: localStorage.getItem("nx-withSaml") === "true",
  withSso: false,
  informationLevel: "",
  email: "",
  profileName: "",
  isFeatureFlagEnabled: () => false,
};

const PortalProvider: FC = ({ children }) => {
  const { isAuthenticated, hasPortal, setPortalWithSaml } = useAuthContext();
  const { getConfig } = usePortalService();
  const { setLocale, setLang, setTheme } = useProductShellContext();
  const [config, setConfig] = useState<PortalConfigDto | null>(null);

  useEffect(() => {
    const getConfigFn = async () => {
      const response = await getConfig();
      setConfig(response);
      if (response) {
        setLocale(response.userProfile.locale.replace("_", "-"));
        setLang(response.userProfile.locale.replace(/_.*/, ""));
        setTheme(response.globals.theme === "DARK" ? "dark" : "light");
        localStorage.setItem("nx-withSaml", String(response.globals.withSaml));
        setPortalWithSaml(response.globals.withSaml);
      }
    };
    if (isAuthenticated && hasPortal) {
      getConfigFn();
    }
  }, [getConfig, isAuthenticated, hasPortal, setLang, setLocale, setTheme, setPortalWithSaml]);

  const isFeatureFlagEnabled = useCallback(
    (id: string): boolean => {
      const featureFlagId = id.toLowerCase();
      return config
        ? Object.entries(config.config.features).some(
            ([featureId, enabled]) => enabled && featureId.toLowerCase() === featureFlagId
          )
        : false;
    },
    [config]
  );

  const value = useMemo(() => {
    if (!config) {
      return emptyContext;
    }
    return {
      ...config.globals,
      profileName: config.userProfile.profileName,
      email: config.userProfile.email,
      informationLevel: config.userProfile.informationLevel,
      isFeatureFlagEnabled,
    };
  }, [config, isFeatureFlagEnabled]);

  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>;
};

const usePortalContext = (): PortalContextProps => {
  const context = useContext(PortalContext);
  if (context === undefined) {
    throw new Error("usePortalContext must be used within a PortalProvider");
  }
  return context;
};

export { PortalProvider, usePortalContext };
