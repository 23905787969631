import type { ProductShellSharedContext } from "@nexthink/product-shell-library";
import { type FC, useEffect, useState } from "react";
import { type ElementLoadError, loadCSSFile, loadJsFile, unloadCSSFileByUrl, unloadJsFileByUrl } from "../utils";

export interface UseDynamicScriptProps {
  url?: string;
  cssFiles?: string[];
  jsResources?: string[];
}
export interface UseDynamicScriptReturnType<T extends object = ProductShellSharedContext> {
  ready: boolean;
  error?: ElementLoadError;
  component?: FC<T>;
}

const useDynamicScript = <T extends object = ProductShellSharedContext>({
  url,
  cssFiles,
  jsResources,
}: UseDynamicScriptProps): UseDynamicScriptReturnType<T> => {
  const [state, setState] = useState<UseDynamicScriptReturnType<T>>({ ready: false });

  useEffect((): undefined | (() => void) => {
    if (!url) {
      return;
    }

    setState({ ready: false });
    let component: FC<T>;

    const retrieveCssFiles = () => {
      return cssFiles && Promise.all(cssFiles.map(loadCSSFile));
    };

    const retrieveJsResources = () => {
      return jsResources && Promise.all(jsResources.map(loadJsFile));
    };

    const retrieveJsFile = async () => {
      component = (await loadJsFile(url)) as FC<T>;
    };

    const run = async () => {
      try {
        await Promise.all([retrieveCssFiles(), retrieveJsResources(), retrieveJsFile()]);
        setState({ ready: true, component: component as FC<T> });
      } catch (error) {
        setState({ ready: true, error });
      }
    };

    run();

    return () => {
      unloadJsFileByUrl(url);
      if (cssFiles) {
        cssFiles.forEach(unloadCSSFileByUrl);
      }
      if (jsResources) {
        jsResources.forEach(unloadJsFileByUrl);
      }
    };
  }, [url, cssFiles, jsResources]);

  return state;
};

export default useDynamicScript;
