import { type Dispatch, type FC, type SetStateAction, createContext, useContext, useMemo, useState } from "react";
import { useGetIsFeatureEnabled } from "../hooks/useGetIsFeatureEnabled";
import type { MenuResponse } from "../services/types/menu";

export interface MenuContextProps {
  menu: MenuResponse | null;
  setMenu: Dispatch<SetStateAction<MenuResponse | null>>;
  isFeatureEnabled: (id: string) => boolean;
}

export const MenuContext = createContext<MenuContextProps | undefined>(undefined);

const MenuProvider: FC = ({ children }) => {
  const [menu, setMenu] = useState<MenuResponse | null>(null);
  const isFeatureEnabled = useGetIsFeatureEnabled(menu);

  const value = useMemo(
    (): MenuContextProps => ({
      menu,
      setMenu,
      isFeatureEnabled,
    }),
    [menu, isFeatureEnabled]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

const useMenuContext = (): MenuContextProps => {
  const context = useContext(MenuContext);
  if (context === undefined) {
    throw new Error("useMenuContext must be used within a MenuProvider");
  }
  return context;
};

export { MenuProvider, useMenuContext };
