import type { ProductShellState } from "@nexthink/product-shell-library";
import { type FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { reportError } from "../observability";
import type { ServiceConfig } from "../services/types/service-config";
import { ErrorTypes } from "../utils";

export interface ProductShellContextProps {
  serviceError: Error | undefined;
  setServiceError: (error: Error | undefined) => void;
  theme: "light" | "dark";
  setTheme: (theme: "light" | "dark") => void;
  lang: string;
  setLang: (lang: string) => void;
  setLocale: (lang: string) => void;
  locale: string;
  serviceConfig: ServiceConfig | null;
  setServiceConfig: (serviceConfig: ServiceConfig | null) => void;
  openedDialogId: string | null;
  openDialog: (dialogId: string) => void;
  closeDialog: () => void;
  setPageTitle: (title: string) => void;
  appState: ProductShellState;
  setAppState: (state: ProductShellState) => void;
}

export const ProductShellContext = createContext<ProductShellContextProps | undefined>(undefined);

const ProductShellProvider: FC = ({ children }) => {
  const [serviceConfig, setServiceConfig] = useState<ServiceConfig | null>(null);
  const [theme, setTheme] = useState<"light" | "dark">(
    (localStorage.getItem("nx-theme") as "light" | "dark") || "light"
  );
  const [lang, setLang] = useState<string>(localStorage.getItem("nx-lang") || "en");
  const [locale, setLocale] = useState<string>("en-CH");
  const [serviceError, setServiceError] = useState<Error | undefined>();
  const [appState, setAppState] = useState<ProductShellState>(["ready"]);
  const [openedDialogId, setOpenedDialogId] = useState<string | null>(null);

  useEffect(() => {
    // set lang in the html lang attribute
    document.querySelector("html")?.setAttribute("lang", lang);
    // set theme to body for default color
    document.querySelector("html")?.setAttribute("data-theme", theme);
  }, [lang, theme]);

  useEffect(() => {
    if (serviceError) {
      reportError(serviceError, {
        errorType: serviceConfig ? ErrorTypes.serviceError : ErrorTypes.startupError,
      });
    }
  }, [serviceError, serviceConfig]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const saveTheme = useCallback(
    (t) => {
      // pendo team relies on nx-theme to be in localStorage
      localStorage.setItem("nx-theme", t);
      setTheme(t);
    },
    [setTheme]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const saveLang = useCallback(
    (l) => {
      localStorage.setItem("nx-lang", l);
      setLang(l);
    },
    [setLang]
  );

  const setPageTitle = useCallback((title) => {
    if (title) {
      document.title = `${title} | Nexthink`;
    } else {
      document.title = "Nexthink";
    }
  }, []);

  const value = useMemo(
    (): ProductShellContextProps => ({
      serviceError,
      setServiceError,
      theme,
      setTheme: saveTheme,
      lang,
      locale,
      setLocale,
      setLang: saveLang,
      serviceConfig,
      setServiceConfig,
      openDialog: setOpenedDialogId,
      closeDialog: () => setOpenedDialogId(null),
      openedDialogId,
      setPageTitle,
      appState,
      setAppState,
    }),
    [serviceError, theme, saveTheme, lang, locale, saveLang, serviceConfig, openedDialogId, setPageTitle, appState]
  );

  return <ProductShellContext.Provider value={value}>{children}</ProductShellContext.Provider>;
};

const useProductShellContext = (): ProductShellContextProps => {
  const context = useContext(ProductShellContext);
  if (context === undefined) {
    throw new Error("useProductShellContext must be used within a ProductShellProvider");
  }
  return context;
};

export { ProductShellProvider, useProductShellContext };
