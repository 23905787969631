import Text from "@nexthink/apollo-components/lib/components/text/Text";
import type { FC, MouseEventHandler } from "react";
import { useHistory } from "react-router-dom";
import type { Menu } from "../../services/types/menu";
import { MenuActionType } from "../../services/types/menu-shared";
import { useProductShellNavigationContext } from "../ProductShellNavigationContext";
import { useGetMenuActions } from "../hooks/useGetMenuActions";
import { StyledPanelTitleContainer } from "./PanelTitle.style";
import { TooltipButton } from "./TooltipButton";

export interface PanelTitleProps {
  menu: Menu;
  id?: string;
}

const PanelTitle: FC<PanelTitleProps> = ({ menu, id }) => {
  const history = useHistory();
  const { closeMenu } = useProductShellNavigationContext();
  const menuActions = useGetMenuActions(menu.id);

  const createAction = menuActions.find((a) => a.type === MenuActionType.Create);
  const onCreateClick: MouseEventHandler = (e) => {
    if (!createAction) {
      return;
    }

    e?.preventDefault();
    closeMenu();
    history.push(createAction.url);
  };

  return (
    <StyledPanelTitleContainer>
      <Text id={id} variant="displaySmall">
        {menu.title}
      </Text>
      {createAction && !menu.isLoading && (
        <TooltipButton
          id={`btn-${createAction.title}`}
          disabled={createAction.disabled}
          tooltip={createAction.disabled ? createAction.disabledTooltip : undefined}
          tooltipPosition="topRight"
          aria-label={`${createAction.title} item in ${menu.title}`}
          as={createAction.disabled ? "button" : "a"}
          href={createAction.url}
          onClick={onCreateClick}
          size="small"
        >
          {createAction.title}
        </TooltipButton>
      )}
    </StyledPanelTitleContainer>
  );
};

export default PanelTitle;
