import type Command from "./command";
import type CommandBus from "./commandBus";
import type CommandHandler from "./commandHandler";
import UnknownCommandError from "./customError";

export default class InMemoryAsyncCommandBus implements CommandBus {
  private readonly commandMap: Record<string, CommandHandler<Command>>;
  constructor(commandMap: Record<string, CommandHandler<Command>>) {
    this.commandMap = { ...commandMap };
  }

  async dispatch(command: Command): Promise<void> {
    const commandHandler = this.commandMap[command.commandName];
    if (!commandHandler) {
      throw new UnknownCommandError(command.commandName);
    }
    await commandHandler(command);
  }
}
