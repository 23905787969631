import type { MenuTag } from "../../Navigation/util";
import { useGetMenuResponse } from "../../hooks/useGetMenuResponse";

const DEFAULT_TAG_COLOR = "none";

export const useGetMenuTags = (menuId: string): MenuTag[] => {
  const menuResponse = useGetMenuResponse();
  const allGroups = menuResponse?.groups;
  const tagsGroups = allGroups?.filter((group) => group.menuId === menuId && group.isDynamic === true) || [];
  return tagsGroups.map((tag) => ({
    id: tag.id,
    title: tag.title,
    menuId: tag.menuId,
    menuGroupId: tag.menuGroupId || "",
    color: tag.tagColor || DEFAULT_TAG_COLOR,
  }));
};
