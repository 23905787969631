import type { DialogProps } from "@nexthink/apollo-components/lib/components/dialog/Dialog";
import type { FC } from "react";
import type { DynamicComponentProps } from "../../ProductShellRouter/DynamicComponent";
import useDynamicScript from "../../hooks/useDynamicScript";
import { useGetApplyCacheBustingToUrl } from "../../hooks/useGetApplyCacheBustingToUrl";

export type SearchAppProps = Pick<
  DynamicComponentProps,
  "jsFile" | "history" | "theme" | "lang" | "observability" | "getToken"
> &
  Pick<DialogProps, "isOpen" | "onClose"> & { assistEnabled?: boolean; BEDefinedSuffix?: string; appVersion?: string };

const SearchApp: FC<SearchAppProps> = (props) => {
  const { applyCacheBustingToUrl } = useGetApplyCacheBustingToUrl();
  const { component: Component } = useDynamicScript<SearchAppProps>({
    url: applyCacheBustingToUrl(props.jsFile, props?.BEDefinedSuffix, props?.appVersion),
  });

  // FIXME figure out a way to set the RUM view back to the active app when global search is dismissed
  // useEffect(() => {
  //   observeMicroFrontEnd(SEARCH_APP_ID, props.appVersion);
  // }, [props.appVersion]); // RUM should only start a new view when the app loads and/or version changes

  if (!Component) {
    return null;
  }

  return <Component {...props} />;
};

export default SearchApp;
