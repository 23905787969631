import type { FC } from "react";
import type { MenuGroup as MenuGroupType } from "../../services/types/menu";
import { useGetMenuGroup } from "../hooks/useGetMenuGroup";
import { StyledMenuGroupContainer } from "./MenuGroup.style";
import MenuGroupCollapsible from "./MenuGroupCollapsible";
import MenuGroupTitleOnly from "./MenuGroupTitleOnly";

export interface MenuGroupProps {
  id: string;
  level: number;
}

export interface MenuGroupChild {
  menuGroup: MenuGroupType;
  level: number;
}

export const MenuGroup: FC<MenuGroupProps> = ({ id, level }) => {
  const menuGroup = useGetMenuGroup(id);

  if (level > 1 || !menuGroup) {
    return null; // we don't support more than 2 levels at this point ( 0, 1 )
  }

  const isTitleOnly = level === 0;

  return (
    <StyledMenuGroupContainer level={level}>
      {isTitleOnly ? (
        <MenuGroupTitleOnly menuGroup={menuGroup} level={level} />
      ) : (
        <MenuGroupCollapsible menuGroup={menuGroup} level={level} />
      )}
    </StyledMenuGroupContainer>
  );
};
