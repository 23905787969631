import DownloadFinderEn from "./dialogs/DownloadFinderDialog/locales/en.json";
import DownloadFinderFr from "./dialogs/DownloadFinderDialog/locales/fr.json";

import DeviceLocatorEn from "./dialogs/DeviceLocatorDialog/locales/en.json";
import DeviceLocatorFr from "./dialogs/DeviceLocatorDialog/locales/fr.json";

import NavigationEn from "./Navigation/locales/en.json";
import NavigationFr from "./Navigation/locales/fr.json";

import ErrorPageEn from "./errors/ErrorPage/locales/en.json";
import ErrorPageFr from "./errors/ErrorPage/locales/fr.json";

import ErrorBoundaryEn from "./errors/ErrorBoundary/locales/en.json";
import ErrorBoundaryFr from "./errors/ErrorBoundary/locales/fr.json";

import ErrorNotFoundEn from "./errors/ErrorNotFound/locales/en.json";
import ErrorNotFoundFr from "./errors/ErrorNotFound/locales/fr.json";

import LandingPageNoMenuEn from "./LandingPageNoMenu/locales/en.json";
import LandingPageNoMenuFr from "./LandingPageNoMenu/locales/fr.json";

import MainContainerEn from "./MainContainer/locales/en.json";
import MainContainerFr from "./MainContainer/locales/fr.json";

import NavPanelActionTextEn from "./Navigation/Panel/locales/en.json";

export const translations = {
  en: {
    ...DownloadFinderEn,
    ...DeviceLocatorEn,
    ...NavigationEn,
    ...ErrorPageEn,
    ...LandingPageNoMenuEn,
    ...ErrorBoundaryEn,
    ...ErrorNotFoundEn,
    ...MainContainerEn,
    ...NavPanelActionTextEn,
  },
  fr: {
    ...DownloadFinderFr,
    ...DeviceLocatorFr,
    ...NavigationFr,
    ...ErrorPageFr,
    ...LandingPageNoMenuFr,
    ...ErrorBoundaryFr,
    ...ErrorNotFoundFr,
    ...MainContainerFr,
  },
};
