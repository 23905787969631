import Box from "@nexthink/apollo-components/lib/components/box/Box";
import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import { apolloZindexDialogOverlay } from "@nexthink/apollo-components/lib/tokens";
import styled from "styled-components";
import { MENU_WIDTH_COLLAPSED } from "../Navigation/BlueBar.style";

export const StyledMenu = styled(Flex)(({ theme }) => ({
  height: "100%",
  position: "fixed",
  background: `linear-gradient(to right, ${theme.colors.apolloColorBackgroundMainNavBar} ${MENU_WIDTH_COLLAPSED}, ${theme.colors.apolloColorBackgroundDashboard} 0%);`,
  left: "0px",
  top: "0px",
  zIndex: apolloZindexDialogOverlay,
}));

export const StyledPSContentContainer = styled(Box)({
  position: "relative",
  height: "100%",
  overflow: "hidden",
  paddingLeft: MENU_WIDTH_COLLAPSED,
  width: "100%",
  "@media print": {
    paddingLeft: "0px",
    overflow: "visible !important",
    background: "none",
  },
});
