import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  DEVICE_LOCATOR_DIALOG_PATH,
  DOWNLOAD_FINDER_DIALOG_PATH,
  SEARCH_APP_DIALOG_PATH,
} from "../Navigation/constants";
import { useProductShellContext } from "../context/ProductShellContext";
import { useUserContext } from "../context/UserContext";
import { DEVICE_LOCATOR_DIALOG_ID } from "../dialogs/DeviceLocatorDialog/DeviceLocatorDialog";
import { DOWNLOAD_FINDER_DIALOG_ID } from "../dialogs/DownloadFinderDialog/DownloadFinderDialog";
import { SEARCH_APP_DIALOG_ID } from "../dialogs/SearchAppDialog/SearchAppDialog";

export const useHandleDialogRoutes = () => {
  const { openDialog } = useProductShellContext();
  const { hasFinderAccess } = useUserContext();
  const history = useHistory();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    switch (history.location.pathname) {
      case DEVICE_LOCATOR_DIALOG_PATH: {
        history.replace("/");
        openDialog(DEVICE_LOCATOR_DIALOG_ID);
        break;
      }
      case SEARCH_APP_DIALOG_PATH: {
        history.replace("/");
        openDialog(SEARCH_APP_DIALOG_ID);
        break;
      }
      case DOWNLOAD_FINDER_DIALOG_PATH: {
        if (hasFinderAccess) {
          history.replace("/");
          openDialog(DOWNLOAD_FINDER_DIALOG_ID);
        }
        break;
      }
    }
  }, [history, openDialog]);
};
