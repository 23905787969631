import type { FC } from "react";
import { useProductShellContext } from "../../context/ProductShellContext";
import { useProductShellNavigationContext } from "../ProductShellNavigationContext";
import { focusMainContent } from "../util";
import MenuIconBase from "./MenuIconBase";

export interface MenuIconDialogProps {
  icon: string;
  title: string;
  subtitle?: string;
  dialogId: string;
  id?: string;
  isSearchItem?: string;
}

const MenuIconDialog: FC<MenuIconDialogProps> = ({ icon, title, subtitle, dialogId, id, isSearchItem, ...rest }) => {
  const { openedDialogId, openDialog, closeDialog } = useProductShellContext();
  const { closeMenu } = useProductShellNavigationContext();

  const onClick = () => {
    openedDialogId === dialogId ? closeDialog() : openDialog(dialogId);
    closeMenu();
    focusMainContent();
  };

  return (
    <MenuIconBase
      icon={icon}
      title={title}
      subtitle={subtitle}
      hasPopup={false}
      isActive={false}
      isOpen={false}
      onClick={onClick}
      id={id}
      isSearchItem={isSearchItem}
      {...rest}
    />
  );
};

export default MenuIconDialog;
