import { useCallback } from "react";
import { useMenuContext } from "../context/MenuContext";
import useRefreshDynamicMenu from "./useRefreshDynamicMenu";
import useServices from "./useServices";

type UseRefreshMenuReturnType = { refreshMenu: (menuId?: string) => Promise<void> };

const useRefreshMenu = (): UseRefreshMenuReturnType => {
  const { getMenus } = useServices();
  const { refreshDynamicMenu } = useRefreshDynamicMenu();
  const { setMenu } = useMenuContext();

  const refreshMenu = useCallback(
    async (menuId?: string) => {
      if (menuId) {
        await refreshDynamicMenu(menuId);
      } else {
        const staticMenu = await getMenus();
        if (!staticMenu) {
          return;
        }
        setMenu(staticMenu);
        const allMenus = staticMenu.groupedMenus.flatMap((group) => group.menuItems);
        await Promise.all(
          (allMenus || []).map((menu) => {
            if (menu.apiUrl) {
              return refreshDynamicMenu(menu.id);
            }
            return Promise.resolve();
          })
        );
      }
    },
    [refreshDynamicMenu, getMenus, setMenu]
  );

  return { refreshMenu };
};

export default useRefreshMenu;
