import {
  type WithPortalAlignment,
  type WithPortalContentProps,
  type WithPortalOptions,
  type WithPortalProps,
  type WithPortalTargetProps,
  withPortal,
} from "@nexthink/apollo-components/lib/hoc/withPortal";
import type { ApolloComponent, OverlayComponent } from "@nexthink/apollo-components/lib/typing";
import {
  ActionTooltip,
  type ActionTooltipProps,
} from "@nexthink/apollo-widgets/lib/widgets/action-tooltip/ActionTooltip";
import type { FC, HTMLAttributes, ReactElement } from "react";
import type React from "react";
import { cloneElement } from "react";

export interface TooltipProps extends ApolloComponent, OverlayComponent, ActionTooltipProps {
  // overlay: ReactElement;
  alignment?: WithPortalAlignment;
}

const TargetContent: FC<WithPortalProps<HTMLElement> & HTMLAttributes<HTMLElement>> = ({
  children,
  forwardedRef,
  ...rest
}) => {
  const child = children as ReactElement;
  const mergeOnClick = (e: React.MouseEvent<HTMLElement>) => {
    child.props.onClick?.(e);
    rest.onClick?.(e);
  };

  return cloneElement(child, {
    ref: forwardedRef,
    ...rest,
    onClick: mergeOnClick,
  });
};

const getPopoverOptions = (props: TooltipProps): WithPortalOptions => {
  const { alignment } = props;

  return {
    alignment: alignment || "bottomLeft",
    offset: [-18, 10],
  };
};

const Tooltip: FC<TooltipProps> = (props) => {
  const { children, actions } = props;

  const Target: FC<WithPortalTargetProps<HTMLElement>> = ({
    forwardedRef,
    onClick,
    onKeyDown,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    tabIndex,
  }) => {
    return (
      <TargetContent
        forwardedRef={forwardedRef}
        {...{ onClick, onKeyDown, onFocus, onBlur, onMouseEnter, onMouseLeave, tabIndex }}
      >
        {children}
      </TargetContent>
    );
  };

  // The component to display in the popover
  const PopoverContent: FC<WithPortalContentProps<object>> = ({ tooltipPosition }): ReactElement => {
    return <ActionTooltip position={tooltipPosition} actions={actions} />;
  };

  const TargetwithPortal = withPortal<HTMLElement, object>(PopoverContent, getPopoverOptions(props))(Target);

  return <TargetwithPortal />;
};

export default Tooltip;
