import type { ProductShellSharedContext } from "@nexthink/product-shell-library";
import React, { type FC, memo } from "react";
import ErrorBoundary from "../errors/ErrorBoundary/ErrorBoundary";
import { ElementLoadError, ErrorTypes } from "../utils";
import useFederatedComponent from "./useFederatedComponent";

import { init } from "@module-federation/runtime/.";
import { useLocation } from "react-router-dom";
import { StyledContainer } from "../ProductShellRouter/DynamicComponent.style";
import ErrorPage from "../errors/ErrorPage/ErrorPage";
import { reportError } from "../observability";
import { apperancePageFederationConfig } from "./__mocks__/appearancePageFederationConfig";

export interface DynamicModuleProps extends ProductShellSharedContext {
  module: string;
  scope: string;
  remoteEntryUrl: string;
  version: string;
}

const DynamicModule = ({ module, scope, remoteEntryUrl, ...rest }: DynamicModuleProps) => {
  const { pathname } = useLocation();
  init(apperancePageFederationConfig);
  const { Component: FederatedComponent, errorLoading, ready } = useFederatedComponent(remoteEntryUrl, scope, module);

  return (
    <ErrorBoundary newRelicErrorType={ErrorTypes.microFrontendError}>
      <DynamicModulePure
        {...{
          ...rest,
          pathname,
          errorLoading,
          ready,
          module,
          scope,
          remoteEntryUrl,
          component: FederatedComponent ?? null,
        }}
      />
    </ErrorBoundary>
  );
};

export default DynamicModule;

export interface DynamicComponentType<T extends object = ProductShellSharedContext> {
  component: FC<T> | null;
}
export interface DynamicModulePureProps extends DynamicModuleProps, DynamicComponentType {
  pathname: string;
  errorLoading: boolean;
  ready: boolean;
}

const DynamicModulePure: FC<DynamicModulePureProps> = memo(function DynamicModulePure({
  errorLoading,
  ready,
  component: Component,
  ...rest
}) {
  if (!ready) {
    return <StyledContainer alignItems={"center"} />;
  }

  if (errorLoading || !Component) {
    const newRelicError = new ElementLoadError(`could not load ${rest.module}`, rest.module);
    reportError(newRelicError, { file: newRelicError.file, errorType: "fileLoad" });
    return <ErrorPage id="dynamic-content-loader-error" />;
  }

  return (
    <ErrorBoundary id="dynamic-error-boundary">
      <React.Suspense fallback={<StyledContainer alignItems={"center"} />}>
        {errorLoading ? `Error loading module "${module}"` : Component && <Component {...rest} />}
      </React.Suspense>
    </ErrorBoundary>
  );
});
