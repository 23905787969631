// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import Error from "@nexthink/apollo-components/lib/color-icons/Error";
import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Text from "@nexthink/apollo-components/lib/components/text/Text";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { apolloSpaceInset1X } from "@nexthink/apollo-components/lib/tokens";
import { type FC, useContext } from "react";

import { DEVICE_LOCATOR_TRANSLATION_NAMESPACE } from "./DeviceLocatorDialog";

export interface WarningsProps {
  warning: string[];
}

const Warnings: FC<WarningsProps> = ({ warning, ...rest }) => {
  const { t } = useNxTranslation();
  const theme = useContext(ThemeContext);
  let warningMsg: string;

  if (warning.length === 1) {
    warningMsg = t("enginesSearch_warning_single", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE, var: warning[0] });
  } else {
    const start = t("enginesSearch_warning_start_msg", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE });
    const extra = t("enginesSearch_warning_extra", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE });
    const and = t("enginesSearch_warning_last", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE });
    const endMsg = t("enginesSearch_warning_end_msg", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE });
    if (warning.length > 3) {
      // keep only the 3 first
      const parts = warning.slice(0, 3);
      warningMsg = `${start} ${parts.join(", ")} ${extra} ${endMsg}`;
    } else {
      const last = warning.pop();
      warningMsg = `${start} ${warning.join(", ")} ${and} ${last} ${endMsg}`;
    }
  }

  return (
    <>
      <Error />
      <Flex pl={apolloSpaceInset1X}>
        <Text id="device-locator-warning" color={theme.colors.apolloColorTextError} {...rest}>
          {warningMsg}
        </Text>
      </Flex>
    </>
  );
};

export default Warnings;
