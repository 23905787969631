import { ThemeContext, useUniqueId } from "@nexthink/apollo-components";
import { type FC, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  StyleSearchMenuIconBase,
  StyledAssistIconWrapper,
  StyledAssistPairIcon,
  StyledMenuIcon,
  StyledMenuIconBase,
  StyledMenuIconBaseContainer,
  StyledSubtitle,
  StyledTitle,
  StyledTitleWrapper,
} from "./MenuIconBase.style";

export interface MenuIconBaseProps {
  icon: string;
  title: string;
  subtitle?: string;
  href?: string;
  hasPopup: boolean;
  isActive: boolean;
  isOpen: boolean;
  onClick: () => void;
  id?: string;
  isSearchItem?: string;
  hideTitle?: boolean;
}

const MenuIconBase: FC<MenuIconBaseProps> = ({
  icon,
  title,
  subtitle,
  href,
  isActive,
  isOpen,
  hasPopup,
  onClick,
  id,
  hideTitle,
  isSearchItem,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  id = useUniqueId("ps-menu-icon", id);
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const isMenuSearch = !!isSearchItem;
  const isSearchAssistItem = isSearchItem && isSearchItem === "global-search-assist";

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSelectItem = () => {
    onClick();
    if (href && href !== history.location.pathname) {
      history.push(href);
    }
  };

  const isMenuIconFocusable: number = -1;

  const isMenuIconActive: boolean = (isHovered || isActive) && !isMenuSearch;

  return (
    // biome-ignore lint/a11y/useValidAriaRole: FIXME
    <StyledMenuIconBaseContainer
      isActive={isActive}
      role="none"
      isMenuSearch={isMenuSearch}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyPress={(e) => e.key === "Enter" && handleSelectItem()}
      onClick={handleSelectItem}
      tabIndex={0}
      data-testid="item-container"
      {...rest}
    >
      {isSearchAssistItem && (
        <StyleSearchMenuIconBase
          title={title}
          isActive={isActive}
          role="menuitem"
          aria-haspopup={hasPopup}
          aria-expanded={isOpen}
          id={id}
          to={href}
          {...(isActive && { "aria-current": true })}
        >
          <StyledAssistIconWrapper>
            <StyledAssistPairIcon
              title="animated"
              src={icon}
              tabIndex={isMenuIconFocusable}
              size="md"
              aria-hidden={true}
              color="white"
            />
          </StyledAssistIconWrapper>
        </StyleSearchMenuIconBase>
      )}

      {!isSearchAssistItem && (
        <StyledMenuIconBase
          title={title}
          isActive={isActive}
          role="menuitem"
          aria-haspopup={hasPopup}
          aria-expanded={isOpen}
          tabIndex={isMenuIconFocusable}
          id={id}
          to={href}
          isMenuSearch={isMenuSearch}
          {...(isActive && { "aria-current": true })}
        >
          <StyledMenuIcon
            title={title}
            src={icon}
            tabIndex={-1}
            size="md"
            color={theme.colors.apolloColorTextPrimaryOnDark}
            aria-hidden={true}
            isActive={isMenuIconActive}
          />
        </StyledMenuIconBase>
      )}

      {!hideTitle && (
        <StyledTitleWrapper id={`${id}-text`} aria-hidden={true} tabIndex={-1} to={href}>
          <StyledTitle title={title}>
            {title}
            {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
          </StyledTitle>
        </StyledTitleWrapper>
      )}
    </StyledMenuIconBaseContainer>
  );
};

export default MenuIconBase;
