import ApolloIcon from "@nexthink/apollo-components/lib/components/icons/Icon";
import type { CSSProperties } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { IconPixelSizeFromSize, type IconSize } from "./Icon";

export const StyledUrlIcon = styled.img<{ size: string; isHidden: boolean }>(({ size, isHidden }) => ({
  maxWidth: size,
  maxHeight: size,
  outline: "none",
  display: isHidden ? "none" : "block",
}));

export const StyledApolloIcon = styled(ApolloIcon)<{ color: string; size: IconSize; isHidden: boolean }>(
  ({ color, size, isHidden }) => ({
    "&&": {
      color,
      height: IconPixelSizeFromSize[size],
      width: IconPixelSizeFromSize[size],
    },

    display: isHidden ? "none" : "block",
  })
);

export const StyledSvg = styled(SVG)<{
  color: CSSProperties["fill"];
  size: CSSProperties["maxWidth"];
  isHidden: boolean;
}>(({ color, size, isHidden }) => ({
  fill: color,
  maxWidth: size,
  maxHeight: size,
  outline: "none",

  "& *": {
    fill: color,
  },
  display: isHidden ? "none" : "block",
}));
