import {
  type Dispatch,
  type FC,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useGetMenuResponse } from "../hooks/useGetMenuResponse";
import { getActiveItemsByUrl } from "./util";

export interface ProductShellNavigationActiveItems {
  // based on current URL
  activeMenuId?: string; // this changes depending on the url
  activeMenuGroupIds?: string[]; // could have more than one group active since it's a tree
  activeMenuItemId?: string;
}

export interface ProductShellNavigationContextProps {
  activeItems?: ProductShellNavigationActiveItems;

  // based on what the user clicks on
  openedMenuId: string | null;
  openMenu: (menuId: string) => void;
  closeMenu: () => void;

  // search query
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;

  isBlueBarExpanded: boolean;
  expandBlueBar: () => void;
  collapseBlueBar: () => void;
}

const ProductShellNavigationContext = createContext<ProductShellNavigationContextProps>({
  openedMenuId: null,
  openMenu: () => undefined,
  closeMenu: () => undefined,

  searchQuery: "",
  setSearchQuery: () => undefined,

  isBlueBarExpanded: false,
  expandBlueBar: () => undefined,
  collapseBlueBar: () => undefined,
});

export const ProductShellNavigationContextProvider: FC = ({ children }) => {
  const menuResponse = useGetMenuResponse();
  const [isBlueBarExpanded, setIsBlueBarExpanded] = useState<boolean>(false);
  const [openedMenuId, setOpenedMenuId] = useState<string | null>(null);
  const [activeItems, setActiveItems] = useState<ProductShellNavigationActiveItems>();
  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (menuResponse) {
      setActiveItems(getActiveItemsByUrl(menuResponse, location.pathname));
    }
  }, [menuResponse, location.pathname]);

  // reset search on menu change, but before the panel is rendered
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    setSearchQuery("");
  }, [openedMenuId]);

  const value = useMemo<ProductShellNavigationContextProps>(
    () => ({
      activeItems,
      openedMenuId,
      openMenu: (menuId: string) => {
        setIsBlueBarExpanded(true);
        setOpenedMenuId(menuId);
      },
      closeMenu: () => {
        setIsBlueBarExpanded(false);
        setOpenedMenuId(null);
      },
      searchQuery,
      setSearchQuery,
      isBlueBarExpanded,
      expandBlueBar: () => {
        if (!isBlueBarExpanded) {
          setIsBlueBarExpanded(true);
        }
      },
      collapseBlueBar: () => {
        if (openedMenuId === null) {
          setIsBlueBarExpanded(false);
        }
      },
    }),
    [isBlueBarExpanded, activeItems, openedMenuId, searchQuery]
  );

  return <ProductShellNavigationContext.Provider value={value}>{children}</ProductShellNavigationContext.Provider>;
};

export const useProductShellNavigationContext = (): ProductShellNavigationContextProps => {
  const context = useContext(ProductShellNavigationContext);
  if (context === undefined) {
    throw new Error("useProductShellNavigationContext must be used within a ProductShellNavigationContextProvider");
  }
  return context;
};
