import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { type FC, useEffect } from "react";
import type { Menu } from "../../services/types/menu";
import { MenuGroup } from "../MenuGroup/MenuGroup";
import { NAVIGATION_TRANSLATION_NAMESPACE } from "../constants";
import { useGetAllMenuGroupsForMenu } from "../hooks/useGetAllMenuGroupsForMenu";
import EmptyState from "./EmptyState/EmptyState";
import { StyledPanelCustomDashboardsSimpleContent } from "./PanelCustomDashboardsSimple.style";
import { StyledPanelContainer } from "./PanelDefault.style";
import PanelTitle from "./PanelTitle";

const PanelCustomDashboardsSimple: FC<{ menu: Menu }> = ({ menu }) => {
  const allGroups = useGetAllMenuGroupsForMenu(menu.id);
  const { t } = useNxTranslation();
  const rootGroups = allGroups.filter((g) => !g.menuGroupId);

  // Setting Focus on First Element
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (rootGroups.length > 0) {
      const firstGroup = allGroups.find((g) => g.menuGroupId === rootGroups[0].id);

      if (firstGroup) {
        const firstEl = document.getElementById(firstGroup.id)?.querySelectorAll("[role='menuitem']")?.[0];
        (firstEl as HTMLElement)?.focus();
      }
    }
  }, []);

  return (
    <StyledPanelContainer>
      <PanelTitle id="panel-title" menu={menu} />
      <StyledPanelCustomDashboardsSimpleContent>
        {rootGroups.length <= 0 && (
          <EmptyState
            type="empty"
            message={t("emptyPanel", { ns: NAVIGATION_TRANSLATION_NAMESPACE, title: menu.title.toLocaleLowerCase() })}
          />
        )}
        {rootGroups.map((group) => {
          const groupChildrenGroups = allGroups.filter((g) => g.menuGroupId === group.id) || [];
          return groupChildrenGroups.map((g) => <MenuGroup key={g.id} id={g.id} level={0} />);
        })}
      </StyledPanelCustomDashboardsSimpleContent>
    </StyledPanelContainer>
  );
};

export default PanelCustomDashboardsSimple;
