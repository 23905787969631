import { useCallback } from "react";

export const useGetApplyCacheBustingToUrl = (): {
  applyCacheBustingToUrl: (url: string, BEDefinedSuffix?: string, appVersion?: string) => string;
} => {
  const applyCacheBustingToUrl = useCallback(
    (url: string, BEDefinedSuffix: string = Date.now().toString(), appVersion?: string) => {
      const CDN_PATH_PREFIX = "/static/ui";

      if (url.startsWith(CDN_PATH_PREFIX)) {
        return url;
      }

      const cacheBustingSuffix = appVersion ?? BEDefinedSuffix;
      return `${url}?${cacheBustingSuffix}`;
    },
    []
  );
  return { applyCacheBustingToUrl };
};
