import property from "lodash/property";
import { useCallback } from "react";
import { useMenuContext } from "../context/MenuContext";
import type { MenuResponse } from "../services/types/menu";
import useProductShellService from "./useProductShellService";

type UseRefreshDynamicMenuReturnType = { refreshDynamicMenu: (menuId: string) => Promise<void> };

const emptyMenuResponse: MenuResponse = {
  apps: [],
  groupedMenus: [],
  menuActions: [],
  groups: [],
  items: [],
  itemActions: [],
  commandHandlers: {},
  eventSubscribers: {},
};

const setLoadingByMenuId = (
  menuResponse: MenuResponse | null,
  menuId: string,
  isLoading: boolean
): MenuResponse | null =>
  menuResponse
    ? {
        ...menuResponse,
        groupedMenus: menuResponse.groupedMenus.map((group) => ({
          ...group,
          menuItems: group.menuItems.map((item) => (item.id === menuId ? { ...item, isLoading } : item)),
        })),
      }
    : null;

const isDynamic = <T extends { isDynamic?: boolean }>(item: T) => Boolean(item.isDynamic);
const hasMenuId = <T extends { menuId: string }>(menuId: string, item: T) => item.menuId === menuId;
const setIsDynamicTrue = <T extends { isDynamic?: boolean }>(item: T) => ({ ...item, isDynamic: true });

const replaceMenuDynamicContentByMenuId = (
  menuResponse: MenuResponse,
  menuId: string,
  newMenuResponse: MenuResponse
) => {
  // remove previous menu dynamic children
  const dynamicMenuItems = menuResponse.items.filter((i) => hasMenuId(menuId, i) && isDynamic(i));
  const groups = menuResponse.groups.filter((i) => !hasMenuId(menuId, i) || !isDynamic(i));
  const menuActions = menuResponse.menuActions.filter((i) => !hasMenuId(menuId, i) || !isDynamic(i));
  const items = menuResponse.items.filter((i) => !hasMenuId(menuId, i) || !isDynamic(i));
  const itemActions = menuResponse.itemActions.filter(
    (i) => !dynamicMenuItems.map(property("id")).includes(i.menuItemId) || !isDynamic(i)
  );

  // add new menu items
  return {
    ...menuResponse,
    groups: groups.concat(newMenuResponse.groups.map(setIsDynamicTrue)),
    menuActions: menuActions.concat(newMenuResponse.menuActions.map(setIsDynamicTrue)),
    items: items.concat(newMenuResponse.items.map(setIsDynamicTrue)),
    itemActions: itemActions.concat(newMenuResponse.itemActions.map(setIsDynamicTrue)),
  };
};

const useRefreshDynamicMenu = (): UseRefreshDynamicMenuReturnType => {
  const { getProductShellDynamicMenu } = useProductShellService();
  const { setMenu } = useMenuContext();

  const refreshDynamicMenu = useCallback(
    async (menuId: string) => {
      setMenu((currentMenuResponse) => setLoadingByMenuId(currentMenuResponse, menuId, true));

      const dynamicMenuResponse = (await getProductShellDynamicMenu(menuId)) || emptyMenuResponse;

      setMenu((currentMenuResponse) => {
        return currentMenuResponse
          ? setLoadingByMenuId(
              replaceMenuDynamicContentByMenuId(currentMenuResponse, menuId, dynamicMenuResponse),
              menuId,
              false
            )
          : null;
      });
    },
    [getProductShellDynamicMenu, setMenu]
  );

  return { refreshDynamicMenu };
};

export default useRefreshDynamicMenu;
