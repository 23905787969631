import { type RumInitConfiguration, datadogRum } from "@datadog/browser-rum";
import isNil from "lodash/isNil";

declare global {
  let datadogSetup: RumInitConfiguration;
}

let isObservabilityEnabled: boolean;

try {
  isObservabilityEnabled = !isNil(datadogSetup); // datadogSetup is defined in PSS and loaded in the index.html
} catch {
  isObservabilityEnabled = false;
}

const serviceName = "product-shell-service";

export function initObservability(): void {
  if (isObservabilityEnabled) {
    datadogRum.init({
      ...datadogSetup,
      service: serviceName,
      proxy: ({ parameters }) => `${window.location.origin}/apigateway/api/v1/observability-proxy?${parameters}`,
    });
  }
}

export function observeMicroFrontEnd(nxAppId: string, version?: string): void {
  if (isObservabilityEnabled) {
    const isProductShell = nxAppId === serviceName;
    const rumServiceName = isProductShell ? serviceName : nxAppId;
    const rumServiceVersion = isProductShell ? datadogSetup.version : version;

    datadogRum.startView({ service: rumServiceName, version: rumServiceVersion });
  }
}

export function setObservabilityContext(context: Record<string, string>): void {
  datadogRum.setGlobalContext(context);
}

export function reportError(error: Error, tags?: Record<string, unknown>) {
  if (isObservabilityEnabled) {
    datadogRum.addError(error, tags);
  }
}

export function reportAction(actionName: string, tags?: Record<string, unknown>) {
  if (isObservabilityEnabled) {
    datadogRum.addAction(actionName, tags);
  }
}

export type ObservabilityMethods = {
  reportError: typeof reportError;
  reportAction: typeof reportAction;
};

export function observabilityForMicroFrontEnd(_nxAppId: string): ObservabilityMethods {
  return {
    reportError,
    reportAction,
  };
}
