import type { ProductShellSharedContext } from "@nexthink/product-shell-library";
import type { UserClaims } from "@okta/okta-auth-js";
import { type Dispatch, type FC, type SetStateAction, createContext, useContext, useMemo, useState } from "react";
declare global {
  interface Window {
    usingPortalAuth: boolean;
  }
}

export interface AuthContextProps {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>;
  getToken: ProductShellSharedContext["getToken"];
  setGetToken: Dispatch<SetStateAction<ProductShellSharedContext["getToken"]>>;
  // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
  logoutFn: () => Promise<void | boolean>;
  // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
  setLogoutFn: Dispatch<SetStateAction<() => Promise<void | boolean>>>;
  hasPortal: boolean;
  portalWithSaml: boolean;
  setPortalWithSaml: Dispatch<SetStateAction<boolean>>;
  oktaUserClaims: UserClaims | undefined;
  setOktaUserClaims: Dispatch<SetStateAction<UserClaims | undefined>>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [getToken, setGetToken] = useState<ProductShellSharedContext["getToken"]>(() => () => Promise.resolve(null));
  // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
  const [logoutFn, setLogoutFn] = useState<() => Promise<void | boolean>>(() => () => Promise.resolve(false));
  const [oktaUserClaims, setOktaUserClaims] = useState<UserClaims | undefined>();
  const [portalWithSaml, setPortalWithSaml] = useState<boolean>(localStorage.getItem("nx-withSaml") === "true");

  const value = useMemo(
    (): AuthContextProps => ({
      isAuthenticated,
      setIsAuthenticated,
      getToken,
      setGetToken,
      logoutFn,
      setLogoutFn,
      hasPortal: window.usingPortalAuth,
      portalWithSaml,
      setPortalWithSaml,
      oktaUserClaims,
      setOktaUserClaims,
    }),
    [getToken, logoutFn, isAuthenticated, portalWithSaml, oktaUserClaims]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuthContext };
