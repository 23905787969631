import type { DialogProps } from "@nexthink/apollo-components/lib/components/dialog/Dialog";
import React, { type FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SEARCH_APP_ID } from "../../Navigation/constants";
import { useAuthContext } from "../../context/AuthContext";
import { useProductShellContext } from "../../context/ProductShellContext";
import { useGetMenuResponse } from "../../hooks/useGetMenuResponse";
import useProductShellService from "../../hooks/useProductShellService";
import { observabilityForMicroFrontEnd } from "../../observability";
import SearchApp from "./SearchApp";

export const SEARCH_APP_DIALOG_ID = "search-app-dialog";
const USER_ASSIST_CLAIM = "nx_assist_nexthink_assist";
const ASSIST_LD_FLAG = "cause-detect-natural-language-search";

export type SearchAppDialogProps = Pick<DialogProps, "isOpen" | "onClose">;

const SearchAppDialog: FC<SearchAppDialogProps> = ({ isOpen, onClose }) => {
  const history = useHistory();
  const { lang, theme, serviceConfig } = useProductShellContext();
  const { isAuthenticated, getToken } = useAuthContext();
  const { validateClaims, getLDFlagValue } = useProductShellService();
  const [assistEnabled, setAssistEnabled] = React.useState(false);
  const menuResponse = useGetMenuResponse();
  const searchAppConfig = menuResponse?.apps.find((app) => app.id === SEARCH_APP_ID);
  const observability = observabilityForMicroFrontEnd(SEARCH_APP_ID);

  useEffect(() => {
    if (isAuthenticated) {
      Promise.all([
        validateClaims({ method: "hasAnyClaim", claims: [USER_ASSIST_CLAIM] }),
        getLDFlagValue(ASSIST_LD_FLAG),
      ]).then((result) => {
        setAssistEnabled(!result.includes(false));
      });
    }
  }, [validateClaims, getLDFlagValue, isAuthenticated]);

  if (!searchAppConfig || !isOpen) {
    return null;
  }

  return (
    <SearchApp
      isOpen={isOpen}
      onClose={onClose}
      jsFile={searchAppConfig.jsFile}
      BEDefinedSuffix={serviceConfig?.cacheBustingSuffix}
      appVersion={searchAppConfig?.version}
      theme={theme}
      lang={lang}
      history={history}
      assistEnabled={assistEnabled}
      observability={observability}
      getToken={getToken}
    />
  );
};

export default SearchAppDialog;
