import Icon from "@nexthink/apollo-components/lib/components/icons";
import FocusTrap from "focus-trap-react";
import type { KeyboardEventHandler, ReactElement } from "react";
import type { Menu } from "../../services/types/menu";
import { PanelType } from "../../services/types/menu-shared";
import { useProductShellNavigationContext } from "../ProductShellNavigationContext";
import { MY_ACCOUNT_MENU_ID } from "../constants";
import { useGetBlueBarMenu } from "../hooks/useGetMenu";
import { StyledCloseIcon, StyledPanelContainer, StyledPanelOverlay } from "./Panel.style";
import PanelCustomDashboards from "./PanelCustomDashboards";
import PanelDefault from "./PanelDefault";
import PanelMyAccount from "./PanelMyAccount";

const CloseButton = ({
  keyDownFn,
  closeFn,
}: {
  keyDownFn: KeyboardEventHandler;
  closeFn: () => void;
}): ReactElement => {
  return (
    <StyledCloseIcon
      aria-label={"Close submenu panel"} // missing tranlation
      // biome-ignore lint/a11y/useSemanticElements: styled components
      role="button"
      tabIndex={0} // ??
      onClick={() => closeFn()}
      onKeyDown={keyDownFn}
    >
      <Icon name="close" id="close-panel-btn" style={{ cursor: "pointer" }} />
    </StyledCloseIcon>
  );
};

export const PanelContentComponent = ({ menu }: { menu: Menu }): ReactElement => {
  if (menu.id === MY_ACCOUNT_MENU_ID) {
    return <PanelMyAccount />;
  }
  if (menu.panelType === PanelType.Custom_Dashboards) {
    return <PanelCustomDashboards menu={menu} />;
  }
  return <PanelDefault menu={menu} />;
};

export const Panel = () => {
  const { openedMenuId, closeMenu } = useProductShellNavigationContext();
  const menu = useGetBlueBarMenu(openedMenuId || "");
  const isOpen = Boolean(menu) && !menu?.url;

  const handlePanelKeyDown: KeyboardEventHandler = (event) => {
    const closeEvents: string[] = ["Esc", "Escape"];
    const isCloseEvent = closeEvents.includes(event.key);
    if (isCloseEvent) {
      returnFocus();
      closeMenu();
    }
  };

  const returnFocus = () => {
    const mainContent = document.getElementById(`MNV_${openedMenuId}`);
    if (mainContent) {
      mainContent.focus();
    }
  };

  const handleCloseButtonKeyDown: KeyboardEventHandler = (event) => {
    const closeEvents: string[] = ["Enter", " "];
    const isCloseEvent = closeEvents.includes(event.key);
    if (isCloseEvent) {
      closeMenu();
    }
  };

  return (
    <StyledPanelOverlay
      isOpen={isOpen}
      aria-hidden={!isOpen}
      aria-modal={true}
      aria-label="Submenu panel" //missing translation
      // biome-ignore lint/a11y/useSemanticElements: styled components
      role="dialog"
      onClick={() => closeMenu()}
      onKeyDown={handlePanelKeyDown}
    >
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{
          escapeDeactivates: true,
          clickOutsideDeactivates: true,
          fallbackFocus: "div[role=button]",
          returnFocusOnDeactivate: false,
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
      >
        <StyledPanelContainer id="menu-panel-container" isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
          <CloseButton keyDownFn={handleCloseButtonKeyDown} closeFn={closeMenu} />
          {menu && <PanelContentComponent menu={menu} />}
        </StyledPanelContainer>
      </FocusTrap>
    </StyledPanelOverlay>
  );
};
