import Box from "@nexthink/apollo-components/lib/components/box/Box";
import type { FC } from "react";
import BlueBar from "./BlueBar";
import { Panel } from "./Panel/Panel";
import { ProductShellNavigationContextProvider } from "./ProductShellNavigationContext";

export const Navigation: FC = () => {
  return (
    <ProductShellNavigationContextProvider>
      <Box position="relative" id="nx-navigation">
        <BlueBar />
        <Panel />
      </Box>
    </ProductShellNavigationContextProvider>
  );
};

export default Navigation;
