import { Link, useNxTranslation } from "@nexthink/apollo-components";
import { type FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "styled-components";
import ErrorPage from "../ErrorPage/ErrorPage";

import NxTrans from "@nexthink/apollo-components/lib/i18n/NxTrans";
import ImageDark from "./image-dark.svg";
import ImageLight from "./image-light.svg";

const ERROR_NOT_FOUND_TRANSLATION_NAMESPACE = "error-404";

const ErrorNotFound: FC = () => {
  const { t } = useNxTranslation();
  const themeContext = useContext(ThemeContext);
  const history = useHistory();

  const description = (
    <NxTrans ns={ERROR_NOT_FOUND_TRANSLATION_NAMESPACE} i18nKey={"description"}>
      1
      <Link
        onClick={(e) => {
          e.preventDefault();
          history.goBack();
        }}
        to={"#"}
      >
        2
      </Link>
    </NxTrans>
  );

  return (
    <ErrorPage
      id="undefined-route-error"
      message={t("message", { ns: ERROR_NOT_FOUND_TRANSLATION_NAMESPACE })}
      description={description}
      image={themeContext.name === "light" ? <ImageLight /> : <ImageDark />}
    />
  );
};

export default ErrorNotFound;
