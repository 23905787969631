import { useNxTranslation } from "@nexthink/apollo-components";
import type { ReactElement } from "react";
import { useProductShellNavigationContext } from "../../Navigation/ProductShellNavigationContext";
import { NAVIGATION_TRANSLATION_NAMESPACE } from "../../Navigation/constants";
import { useGetMenuActions } from "../../Navigation/hooks/useGetMenuActions";
import { useShouldMenuHaveSearch } from "../../Navigation/hooks/useShouldMenuHaveSearch";
import type { Menu } from "../../services/types/menu";
import { MenuActionType } from "../../services/types/menu-shared";
import EmptyState, { EmptyStateType } from "./EmptyState/EmptyState";

const PanelState = ({ menu }: { menu: Menu }): ReactElement | null => {
  const { t } = useNxTranslation();
  const shouldHaveSearch = useShouldMenuHaveSearch(menu);
  const actions = useGetMenuActions(menu.id);
  const navigationContext = useProductShellNavigationContext();

  const overviewAction = actions.find((a) => a.type === MenuActionType.Overview);

  const isEmptySearch = navigationContext.searchQuery === "";
  const isEmptyState = !overviewAction && (!shouldHaveSearch || (shouldHaveSearch && isEmptySearch));

  if (isEmptyState) {
    return (
      <EmptyState
        type={EmptyStateType.Empty}
        message={t("emptyPanel", { ns: NAVIGATION_TRANSLATION_NAMESPACE, title: menu.title.toLocaleLowerCase() })}
      />
    );
  }
  const isNotResultState = shouldHaveSearch && !isEmptySearch;
  if (isNotResultState) {
    return (
      <EmptyState type={EmptyStateType.No_Result} message={t("noResults", { ns: NAVIGATION_TRANSLATION_NAMESPACE })} />
    );
  }
  return null;
};

export default PanelState;
