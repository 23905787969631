import type { FC } from "react";
import { MenuItem } from "../MenuItem/MenuItem";
import { useGetMenuGroups } from "../hooks/useGetMenuGroups";
import { useGetMenuItemsByGroupList } from "../hooks/usetGetMenuItemsByGroupList";
import { getMenuItemProps } from "../util";
import { MenuGroup, type MenuGroupChild } from "./MenuGroup";
import { StyledMenuGroupTitleContainer, StyledMenuGroupTitleTitle } from "./MenuGroupTitleOnly.style";

const MenuGroupTitleOnly: FC<MenuGroupChild> = ({ menuGroup, level }) => {
  const groups = useGetMenuGroups(menuGroup.menuId, menuGroup.id);
  const items = useGetMenuItemsByGroupList(menuGroup.menuId, [menuGroup.id]);

  return (
    <StyledMenuGroupTitleContainer role="menu" aria-label={menuGroup.title} id={menuGroup.id}>
      <StyledMenuGroupTitleTitle variant="eyeBrowSmall">
        <span>{menuGroup.title}</span>
      </StyledMenuGroupTitleTitle>
      {items.map((i) => (
        <MenuItem key={i.id} {...getMenuItemProps(i)} />
      ))}
      {groups.map((g) => (
        <MenuGroup key={g.id} id={g.id} level={level + 1} />
      ))}
    </StyledMenuGroupTitleContainer>
  );
};

export default MenuGroupTitleOnly;
