import type { CancelToken } from "axios";
import { useCallback } from "react";
import { useAuthContext } from "../context/AuthContext";
import type { MenuResponse } from "../services/types/menu";
import usePortalService from "./usePortalService";
import useProductShellService from "./useProductShellService";

export type useServicesReturnedType = {
  getMenus: (cancelToken?: CancelToken) => Promise<MenuResponse | null>;
};

const useServices = (): useServicesReturnedType => {
  const { hasPortal } = useAuthContext();
  const { getPortalMenus } = usePortalService();
  const { getProductShellStaticMenus } = useProductShellService();

  const getMenus = useCallback(
    async (cancelToken?: CancelToken) => {
      const portalMenuResponse = hasPortal ? await getPortalMenus(cancelToken) : null;
      return getProductShellStaticMenus(portalMenuResponse, cancelToken);
    },
    [getPortalMenus, hasPortal, getProductShellStaticMenus]
  );

  return {
    getMenus,
  };
};

export default useServices;
