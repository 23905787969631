import { type FC, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useGetServiceConfig } from "../hooks/useGetServiceConfig";
import usePortalService from "../hooks/usePortalService";
import type { User } from "../services/types/service-config";
import { useAuthContext } from "./AuthContext";

export interface UserContextProps {
  user: User | undefined;
  hasFinderAccess: boolean;
}

const emptyUser = {
  username: "",
  fullName: "",
  email: "",
  mtpRoles: [""],
  dataPrivacy: "",
  viewDomain: "",
  userType: "",
};

export const UserContext = createContext<UserContextProps | undefined>(undefined);

const UserProvider: FC = ({ children }) => {
  const { hasPortal } = useAuthContext();
  const serviceConfig = useGetServiceConfig();
  const [hasFinderAccess, setHasFinderAccess] = useState<boolean>(false);
  const { getAccountInfo } = usePortalService();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const checkFinderAccess = async () => {
      const accountInfo = await getAccountInfo();
      if (accountInfo?.hasFinderAccess) {
        setHasFinderAccess(true);
      }
    };

    if (hasPortal) {
      checkFinderAccess();
    }
  }, [hasPortal]);

  const value = useMemo(
    (): UserContextProps => ({
      user: serviceConfig?.user ?? emptyUser,
      hasFinderAccess,
    }),
    [serviceConfig?.user, hasFinderAccess]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

const useUserContext = (): UserContextProps => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUserContext };
