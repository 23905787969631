import type { CancelToken } from "axios";
import { useCallback } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useProductShellContext } from "../context/ProductShellContext";
import PortalService, { type PortalApiResult } from "../services/portalService";
import type { UserPreferencesResponse } from "../services/types/account-info";
import type { DeviceLocatorResponse, FinderUrl, SourceType } from "../services/types/device-locator";
import type { PortalConfigDto } from "../services/types/portal-config";
import type { PortalMenuResponse } from "../services/types/portal-menu";
import { redirectToLogin, redirectToLogout } from "../utils";
import { resetNxCache, useCachedMethod } from "./useCachedMethod";
import { useSendLogoutToAmplify } from "./useSendToAmplify";

const portalService = new PortalService();

const CSRF_TOKEN_EXPIRY_MS = 3 * 1000; // 3 seconds
const PORTAL_TOKEN_CACHE_EXPIRY_MS = 5 * 60 * 1000; // 5 minutes

export type usePortalServiceReturnedType = {
  getPortalMenus: (cancelToken?: CancelToken) => Promise<PortalMenuResponse | null>;
  getAccountInfo: () => Promise<UserPreferencesResponse | null>;
  getCSRFToken: () => Promise<Record<string, string> | null>;
  deviceLocatorSearch: (search: string) => Promise<DeviceLocatorResponse | null>;
  getFinderAccessUrl: (engineId: number, sourceName: string, sourceType: SourceType) => Promise<FinderUrl | null>;
  logoutPortal: () => Promise<void>;
  getFinderUpdateLink: (arch: string) => Promise<string | undefined>;
  getConfig: (cancelToken?: CancelToken) => Promise<PortalConfigDto | null>;
  getPortalToken: (cancelToken?: CancelToken | undefined) => Promise<string | null>;
};

const usePortalService = (): usePortalServiceReturnedType => {
  const { setServiceError } = useProductShellContext();
  const { setIsAuthenticated, portalWithSaml } = useAuthContext();
  const amplifyLogoutFn = useSendLogoutToAmplify();

  const logoutPortal = useCallback(async () => {
    await portalService.logout();
    redirectToLogout(portalWithSaml);
    setIsAuthenticated(false);
  }, [portalWithSaml, setIsAuthenticated]);

  const processResult = useCallback(
    <T,>(result: PortalApiResult<T>, serviceName: string) => {
      if (result.isStatusUnauthorized()) {
        amplifyLogoutFn();
        resetNxCache();
        setIsAuthenticated(false);
        redirectToLogin(portalWithSaml);
      } else if (result.hasError() && !result.isAxiosCancel()) {
        setServiceError(
          new Error(`${serviceName} response error, resultStatus=${JSON.stringify(result.resultStatus)}`)
        );
      }
    },
    [setIsAuthenticated, setServiceError, amplifyLogoutFn, portalWithSaml]
  );

  const getTokenMethod = useCallback(
    async (cancelToken?: CancelToken) => {
      const response = await portalService.getAuthToken(cancelToken);
      processResult(response, "getToken");

      return response.result ? response.result.token : null;
    },
    [processResult]
  );

  const getPortalMenus = useCallback(
    async (cancelToken?: CancelToken): Promise<PortalMenuResponse | null> => {
      const portalMenu = await portalService.getMenus(cancelToken);
      processResult(portalMenu, "getPortalMenus");

      return portalMenu.result;
    },
    [processResult]
  );

  const getConfig = useCallback(async () => {
    const response = await portalService.getConfig();
    processResult(response, "getConfig");
    return response.result;
  }, [processResult]);

  const getCSRFTokenMethod = useCallback(async () => {
    const response = await portalService.getCSRFToken();
    processResult(response, "getCSRFToken");
    return response.result;
  }, [processResult]);

  // this is used to check the user session validity on PortalAuth
  const getAccountInfo = useCallback(async () => {
    const response = await portalService.getAccountInfo();
    processResult(response, "getAccountInfo");
    return response.result;
  }, [processResult]);

  const deviceLocatorSearch = useCallback(async (search) => {
    const response = await portalService.searchEngines(search);
    return response.result;
  }, []);

  const getFinderAccessUrl = useCallback(async (engineId, sourceName, sourceType) => {
    const response = await portalService.getFinderAccessUrl(engineId, sourceName, sourceType);
    return response.result;
  }, []);

  const getFinderUpdateLink = useCallback(async (arch: string): Promise<string | undefined> => {
    const response = await portalService.getFinderUpdateLink(arch);
    return response.result?.url;
  }, []);

  const getCSRFToken = useCachedMethod(getCSRFTokenMethod, CSRF_TOKEN_EXPIRY_MS, "csrf-token");
  const getPortalToken = useCachedMethod(getTokenMethod, PORTAL_TOKEN_CACHE_EXPIRY_MS, "portal-token");

  return {
    getPortalToken,
    getPortalMenus,
    getCSRFToken,
    getAccountInfo,
    deviceLocatorSearch,
    getFinderAccessUrl,
    logoutPortal,
    getFinderUpdateLink,
    getConfig,
  };
};

export default usePortalService;
