import { Text } from "@nexthink/apollo-components";
import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import {
  apolloFontSizeEyebrowSmall,
  apolloFontWeightDisplaySmall,
  apolloSpaceInset2X,
} from "@nexthink/apollo-components/lib/tokens";
import styled from "styled-components";

export const StyledTabList = styled(Flex)({
  paddingBottom: apolloSpaceInset2X,
});

export const StyledMenuGroupTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.apolloColorTextSecondary,
  textTransform: "uppercase",
  fontSize: apolloFontSizeEyebrowSmall,
  fontStyle: "normal",
  fontWeight: apolloFontWeightDisplaySmall,

  "& > span": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
