import { type FC, useContext, useEffect, useState } from "react";

import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Button from "@nexthink/apollo-components/lib/components/button/Button";
import Dialog, { type DialogProps } from "@nexthink/apollo-components/lib/components/dialog/Dialog";
import { H3 } from "@nexthink/apollo-components/lib/components/heading/Heading";
import Select from "@nexthink/apollo-components/lib/components/select/Select";
import Text from "@nexthink/apollo-components/lib/components/text/Text";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { apolloSpaceInset2X, apolloSpaceInset5X } from "@nexthink/apollo-components/lib/tokens";
import usePortalService from "../../hooks/usePortalService";
import { navigateToUrl } from "../../utils";

export const DOWNLOAD_FINDER_TRANSLATION_NAMESPACE = "download-finder-page";
export const DOWNLOAD_FINDER_DIALOG_ID = "download-finder-dialog";
export type FINDER_ARCH = "x86" | "x64";

export type Option = {
  value: FINDER_ARCH;
  label: string;
};

type DownloadFinderDialogProps = Pick<DialogProps, "isOpen" | "onClose">;

const DownloadFinderDialog: FC<DownloadFinderDialogProps> = ({ isOpen, onClose }) => {
  const theme = useContext(ThemeContext);
  const { getFinderUpdateLink } = usePortalService();
  const [finderArch, setFinderArch] = useState<FINDER_ARCH>("x64");
  const { t } = useNxTranslation();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        document.getElementById("download-finder-dialog-install")?.focus();
      }, 50);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const downloadFinder = async () => {
    const url = await getFinderUpdateLink(finderArch);
    url && navigateToUrl(url);
    onClose?.();
  };

  const onArchChange = (option?: Option) => {
    option && setFinderArch(option.value);
  };

  const options: Option[] = [
    {
      value: "x64",
      label: t("arch_64", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE }),
    },
    {
      value: "x86",
      label: t("arch_32", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE }),
    },
  ];

  const actions = [
    <Button
      id="download-finder-dialog-install"
      aria-label={t("install_button_caption", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE })}
      key={"save"}
      onClick={downloadFinder}
    >
      {t("install_button_caption", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE })}
    </Button>,
    <Button
      id="download-finder-dialog-close"
      aria-label={t("cancel_button_caption", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE })}
      key={"close"}
      onClick={onClose}
      variant={"secondary"}
    >
      {t("cancel_button_caption", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE })}
    </Button>,
  ];

  return (
    <Dialog
      id={DOWNLOAD_FINDER_DIALOG_ID}
      width={700}
      isOpen={isOpen}
      header={<H3>{t("finderInstall_dialog_title", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE })}</H3>}
      actions={actions}
      onClose={onClose}
    >
      <Flex
        bg={theme.colors.apolloColorBackgroundDashboard}
        borderRadius={"4px"}
        p={apolloSpaceInset2X}
        width={"100%"}
        justifyContent={"space-between"}
      >
        <Flex flex={1} bg={theme.colors.apolloColorBackgroundContainer} p={apolloSpaceInset5X} flexDirection="column">
          <H3>{t("settings_title", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE })}</H3>
          <Flex py={apolloSpaceInset2X}>
            <Select options={options} disablePortal currentValue={finderArch} onChange={onArchChange} />
          </Flex>
          <Text variant="subStrong" color={theme.colors.apolloColorTextSecondary}>
            {t("install_instructions", { ns: DOWNLOAD_FINDER_TRANSLATION_NAMESPACE })}
          </Text>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DownloadFinderDialog;
