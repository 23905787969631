import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Link from "@nexthink/apollo-components/lib/components/link/Link";
import {
  type CellRendererParams,
  Table,
  TableColumn,
  type ValueFormatterParams,
} from "@nexthink/apollo-components/lib/components/table";
import Text from "@nexthink/apollo-components/lib/components/text/Text";
import type { NxTranslation } from "@nexthink/apollo-components/lib/i18n/i18n";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { type FC, useCallback, useContext, useMemo } from "react";

import {
  DateTimeFormat,
  type DateTimeFormatterOptions,
  FormatterTypes,
  dataFormatUtilsWithLanguage,
} from "@nexthink/apollo-components";
import { useProductShellContext } from "../../context/ProductShellContext";
import { useUserContext } from "../../context/UserContext";
import usePortalService from "../../hooks/usePortalService";
import type { DeviceLocatorResult, SourceType } from "../../services/types/device-locator";
import { DEVICE_LOCATOR_TRANSLATION_NAMESPACE } from "./DeviceLocatorDialog";
import Tooltip from "./Tooltip";

export interface ResultsProps {
  list: DeviceLocatorResult[];
}

type DeviceLocatorResultExtended = DeviceLocatorResult & { isValidLastLoginUser: boolean };

export const MAX_NUMBER_ROW = 10;

export const processResult = (list: DeviceLocatorResult[], t: NxTranslation["t"]): DeviceLocatorResultExtended[] => {
  return list
    .map((r) => {
      let lastLogin: string;
      let isValidLastLoginUser = false;
      if (r.platform === "mac_os" || r.platform === "mobile") {
        lastLogin = "n/a";
      } else if (!r.lastLoginUser) {
        lastLogin = "-";
      } else if (r.lastLoginUser === "-") {
        lastLogin = t("enginesSearch_anonymized_user", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE });
      } else {
        lastLogin = r.lastLoginUser;
        isValidLastLoginUser = true;
      }
      return { ...r, lastLoginUser: lastLogin, isValidLastLoginUser };
    })
    .slice(0, 10);
};

export const dateFormatter = ({ value }: ValueFormatterParams, locale: string): string => {
  if (typeof value !== "string") {
    return "-";
  }

  const dateTimeFormatter = dataFormatUtilsWithLanguage.getFormatter(FormatterTypes.DATE_TIME);
  const dateTimeFormat = (dateTime: string, dateTimeOptions?: DateTimeFormatterOptions) =>
    dateTimeFormatter.format(dateTime, { locale, ...dateTimeOptions }).map(
      (v) => v.formatted,
      (e) => e
    );
  const dateString = dateTimeFormat(value, { formatType: DateTimeFormat.DATE_NUMERIC });
  const timeString = dateTimeFormat(value, { formatType: DateTimeFormat.TIME_LONG });

  return `${dateString} ${timeString}`;
};

export const nameFormatter = ({ value }: ValueFormatterParams, t: NxTranslation["t"]): string => {
  if (typeof value !== "string") {
    return "-";
  }

  return value ? value : t("unknown", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE });
};

const Results: FC<ResultsProps> = ({ list, ...rest }) => {
  const theme = useContext(ThemeContext);
  const { t } = useNxTranslation();
  const { locale } = useProductShellContext();
  const { hasFinderAccess } = useUserContext();
  const { getFinderAccessUrl } = usePortalService();

  const dateFormatterFn = useCallback((p) => dateFormatter(p, locale), [locale]);
  const nameFormatterFn = useCallback((p) => nameFormatter(p, t), [t]);
  const data = useMemo(() => processResult(list, t), [list, t]);

  const openInFinder = async (engineId: number, sourceName: string, itemType: SourceType) => {
    const response = await getFinderAccessUrl(engineId, sourceName, itemType);
    if (response?.url) {
      window.open(response.url, "_blank");
    }
  };

  const renderDeviceOrUserCell = (source: SourceType) =>
    function deviceOrUserCellRenderer(params: CellRendererParams) {
      if (
        hasFinderAccess &&
        params.dataItem.engineId !== -1 &&
        (source === "source" || params.dataItem.isValidLastLoginUser)
      ) {
        return (
          <Tooltip
            actions={[
              {
                name: t("investigate_finder", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE }),
                onClick: () => openInFinder(params.dataItem.engineId, String(params.valueFormatted), source),
              },
            ]}
          >
            <Link onClick={() => openInFinder(params.dataItem.engineId, String(params.valueFormatted), source)}>
              {params.valueFormatted}
            </Link>
          </Tooltip>
        );
      }

      //display text
      return <>{params.valueFormatted}</>;
    };

  return list.length ? (
    <Flex flexDirection={"column"} flex={1} {...rest}>
      <Table data={data} width={"100%"}>
        <TableColumn
          field="source"
          title={t("enginesSearch_result_source_caption", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE })}
          valueFormatter={nameFormatterFn}
          cellRenderer={renderDeviceOrUserCell("source")}
        />
        <TableColumn
          field="lastLoginUser"
          title={t("enginesSearch_result_lastuser_caption", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE })}
          valueFormatter={nameFormatterFn}
          cellRenderer={renderDeviceOrUserCell("user")}
        />
        <TableColumn
          field="engineLabel"
          title={t("enginesSearch_result_engine_caption", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE })}
        />
        <TableColumn
          field="lastSeen"
          title={t("enginesSearch_result_lastseen_caption", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE })}
          valueFormatter={dateFormatterFn}
        />
      </Table>
      {list.length > MAX_NUMBER_ROW && (
        <Text color={theme.colors.apolloColorVizUnknown}>
          ...
          <br />
          {t("enginesSearch_more_result", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE })}
        </Text>
      )}
    </Flex>
  ) : (
    <Text>{t("enginesSearch_no_result", { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE })}</Text>
  );
};

export default Results;
