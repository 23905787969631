import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import useProductShellService from "./useProductShellService";

export const AMPLIFY_CLAIM = "nx_amplify_view";

export const useIsAmplifyUser = (): boolean | undefined => {
  const { isAuthenticated } = useAuthContext();
  const [isAmplifyUser, setIsAmplifyUser] = useState<boolean | undefined>();
  const { validateClaims } = useProductShellService();

  useEffect(() => {
    const checkIfAmplifyUser = async () => {
      setIsAmplifyUser(await validateClaims({ method: "hasAnyClaim", claims: [AMPLIFY_CLAIM] }));
    };

    if (isAuthenticated) {
      checkIfAmplifyUser();
    }
  }, [isAuthenticated, validateClaims]);

  return isAmplifyUser;
};
