import { Text, ThemeContext } from "@nexthink/apollo-components";
import uniqueId from "lodash/uniqueId";
import { useContext } from "react";

export const HighlightedText = ({
  text,
  highlight,
  inverseOrder,
}: {
  text: string | undefined;
  highlight: string;
  inverseOrder?: boolean;
}) => {
  const themeContext = useContext(ThemeContext);
  let searchRegex = /(?:)/;
  try {
    searchRegex = new RegExp(`(${highlight})`, "gi");
  } catch (_) {
    // "An special character was not possible to highlight on the search:
  }
  const parts = (!!text && text.split(searchRegex)) || [];

  return (
    <>
      {parts.map((part, index) => {
        const HighlightedPart = (
          <Text
            color={
              inverseOrder ? themeContext.colors.apolloColorTextTertiary : themeContext.colors.apolloColorTextPrimary
            }
            data-testid={`highlighted_text_${index}`}
            variant="bodySmallStrong"
            key={uniqueId()}
          >
            {part}
          </Text>
        );
        const finalText = inverseOrder ? HighlightedPart : part;
        const initialText = inverseOrder ? part : HighlightedPart;
        return part.toLowerCase() === highlight.toLowerCase() ? initialText : finalText;
      })}
    </>
  );
};
