import { useProductShellContext } from "../context/ProductShellContext";
import type { Menu, MenuResponse } from "../services/types/menu";
import { filterOutDialogMenus } from "../utils";
import { useGetMenuResponse } from "./useGetMenuResponse";

export const getFirstUrlForMenu = (menuResponse: MenuResponse, menu: Menu): string | undefined => {
  if (menu.url) {
    return menu.url;
  }

  const firstMenuAction = menuResponse.menuActions.find((item) => item.menuId === menu.id);

  if (firstMenuAction) {
    return firstMenuAction.url;
  }

  const firstMenuItem = menuResponse.items.find((item) => item.menuId === menu.id);

  if (firstMenuItem) {
    return firstMenuItem.url;
  }
};

export const useDefaultRoute = (): string | undefined => {
  const menuResponse = useGetMenuResponse();
  const { serviceConfig } = useProductShellContext();

  if (!menuResponse) {
    return;
  }

  if (serviceConfig?.user.landingPage && serviceConfig.user.landingPage !== "/") {
    return serviceConfig.user.landingPage;
  }

  const flatMenus = menuResponse.groupedMenus.flatMap((group) => group.menuItems);

  for (const menu of flatMenus.filter(filterOutDialogMenus)) {
    const firstUrl = getFirstUrlForMenu(menuResponse, menu);

    if (firstUrl) {
      return firstUrl;
    }
  }
};
