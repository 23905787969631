import { Box } from "@nexthink/apollo-components";
import { apolloSpaceStack1X, apolloSpaceStackHalfX } from "@nexthink/apollo-components/lib/tokens";
import { isEmpty } from "lodash";
import orderBy from "lodash/orderBy";
import { type FC, useCallback, useEffect, useState } from "react";
import { useProductShellNavigationContext } from "../../Navigation/ProductShellNavigationContext";
import type { MenuItem as MenuItemType } from "../../services/types/menu";
import { MenuItem } from "../MenuItem/MenuItem";
import { type MenuTag, getMenuItemProps, getTaggedMenuItems, getUntaggedMenuItems } from "../util";
import MenuTagCollapsible from "./MenuTagCollapsible";

type TagItemDictionary = { [key: string]: MenuItemType[] };

const PanelDetailsWithTags: FC<{
  menuTags: MenuTag[];
  menuItems: MenuItemType[];
  menuGroupId?: string;
}> = ({ menuTags, menuItems, menuGroupId }) => {
  const [currentTag, setCurrentTag] = useState<string | undefined>();

  const taggedMenuItems: TagItemDictionary = getTaggedMenuItems(menuTags, menuItems, menuGroupId);
  const untaggedMenuItems = getUntaggedMenuItems(menuItems, menuGroupId);
  const navigationContext = useProductShellNavigationContext();
  const activeItems = navigationContext?.activeItems;

  const getActiveTagName = useCallback(
    (items: MenuItemType[]) => {
      if (items.length <= 0 || menuItems.length <= 0) {
        return undefined;
      }
      const activeItemId = activeItems?.activeMenuItemId;
      const activeGroupId = activeItems?.activeMenuGroupIds?.[0];

      const activeGroupIsPresent = activeGroupId !== undefined;

      let currentItem: MenuItemType | undefined;
      if (activeGroupIsPresent) {
        currentItem = items.find((item: MenuItemType) => item.id === activeItemId && menuGroupId === activeGroupId);
      } else {
        currentItem = items.find((item: MenuItemType) => item.id === activeItemId);
      }

      const firstTag = currentItem?.tags && orderBy(currentItem?.tags, [(tag) => tag.name.toLowerCase()])[0];
      if (firstTag) {
        return firstTag?.name;
      }
      const [firstMenuItem] = menuItems;

      const storedValue = localStorage.getItem(firstMenuItem.menuId);
      const savedTags = storedValue ? JSON.parse(storedValue) : [];
      const hasMenuGroupId = !isEmpty(menuGroupId);
      const storedTag = hasMenuGroupId
        ? savedTags.find((tag: MenuTag) => tag.menuGroupId === menuGroupId)
        : savedTags[0];
      return storedTag?.title;
    },
    [menuItems, activeItems, menuGroupId]
  );

  useEffect(() => {
    const activeTag = getActiveTagName(menuItems);
    setCurrentTag(activeTag);
  }, [menuItems, getActiveTagName]);

  return (
    <Box pt={apolloSpaceStack1X} pb={apolloSpaceStack1X}>
      {Object.entries(taggedMenuItems).map((tagItem) => {
        const [tagTitle, items] = tagItem;
        const menuTag = menuTags.find((tag) => tag.title === tagTitle);
        const itemsCount = items.length;
        return itemsCount > 0 ? (
          <MenuTagCollapsible
            key={menuTag?.id}
            menuTag={menuTag}
            menuItems={items}
            setActiveTag={setCurrentTag}
            selectedTag={currentTag}
          />
        ) : null;
      })}
      <Box pt={apolloSpaceStackHalfX}>
        {untaggedMenuItems.map((item: MenuItemType) => (
          <MenuItem key={item.id} {...getMenuItemProps(item)} />
        ))}
      </Box>
    </Box>
  );
};

export default PanelDetailsWithTags;
