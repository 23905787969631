import axios, { type AxiosRequestConfig, type CancelToken } from "axios";
import type { UserPreferencesResponse } from "./types/account-info";
import type { DeviceLocatorResponse, FinderUrl, SourceType } from "./types/device-locator";
import type { PortalConfigDto } from "./types/portal-config";
import type { PortalMenuResponse } from "./types/portal-menu";

export interface PortalApiResponse<T> {
  resultStatus: ResultStatus;
  result: T | null;
}
export interface ResultStatus {
  code: number;
  description: string;
  details?: string;
  errors?: Record<string, Array<ValidationError>>;
}

export interface ValidationError {
  field: string;
  code: string;
  message: string;
}

export interface AuthTokenDto {
  token: string;
}

export class PortalApiResult<T> {
  public static error(resultStatus: ResultStatus): PortalApiResult<null> {
    return new PortalApiResult(resultStatus, null);
  }

  public static valid<R>(result: R): PortalApiResult<R> {
    return new PortalApiResult({ code: 0, description: "request successful" }, result);
  }

  public static cancel(): PortalApiResult<null> {
    return new PortalApiResult({ code: -1, description: "request canceled" }, null);
  }

  public constructor(
    public readonly resultStatus: ResultStatus,
    public readonly result: T
  ) {}

  public hasError(): boolean {
    return this.result === null;
  }

  public isStatusUnauthorized(): boolean {
    return this.resultStatus.code === 401;
  }

  public isAxiosCancel(): boolean {
    return this.resultStatus.code === -1;
  }
}

export interface PortalServiceProps {
  getAuthToken(cancelToken?: CancelToken): Promise<PortalApiResult<AuthTokenDto | null>>;
  getMenus(cancelToken?: CancelToken): Promise<PortalApiResult<PortalMenuResponse | null>>;
  getCSRFToken(): Promise<PortalApiResult<Record<string, string> | null>>;
  getAccountInfo(cancelToken?: CancelToken): Promise<PortalApiResult<UserPreferencesResponse | null>>;
  searchEngines(search: string, cancelToken?: CancelToken): Promise<PortalApiResult<DeviceLocatorResponse | null>>;
  getFinderAccessUrl(
    engineId: number,
    sourceName: string,
    sourceType: SourceType,
    cancelToken?: CancelToken
  ): Promise<PortalApiResult<FinderUrl | null>>;
  getFinderUpdateLink(arch: string, cancelToken?: CancelToken): Promise<PortalApiResult<Record<string, string> | null>>;
  getConfig(cancelToken?: CancelToken): Promise<PortalApiResult<PortalConfigDto | null>>;
  logout(): void;
}

export default class PortalService implements PortalServiceProps {
  private readonly portalServletURL = "/PortalServlet";
  private readonly xAuthToken = "x-auth-token";
  private readonly getAuthTokenQuery = "getAuthToken";
  private readonly getMenusQuery = "getMenus";
  private readonly getAccountInfoQuery = "getUserPreferences";
  private readonly searchEnginesQuery = "searchEngines";
  private readonly getFinderSourceViewAccessUrlQuery = "getFinderSourceViewAccessUrl";
  private readonly getFinderUserViewAccessUrlQuery = "getFinderUserViewAccessUrl";
  private readonly getFinderUpdateLinkQuery = "getFinderUpdateLink";
  private readonly getConfigQuery = "getConfig";

  public async post<T>(params: URLSearchParams, cancelToken?: CancelToken): Promise<PortalApiResult<T | null>> {
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-auth-token": this.getLocalToken(),
      },
      cancelToken,
    };

    return axios
      .post<PortalApiResponse<T>>(this.portalServletURL, params, config)
      .then((response) => {
        // the result can be null: Error in BE (result = null, resultStatus code = 1, description = "Failed")
        if (response.status === 200 && response.data.resultStatus.code === 0 && response.data.result) {
          response.headers[this.xAuthToken] && this.setLocalToken(response.headers[this.xAuthToken]);
          return PortalApiResult.valid<T>(response.data.result);
        }
        return PortalApiResult.error(response.data.resultStatus);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return PortalApiResult.cancel();
        }
        const r = error.response || {};
        this.setLocalToken("");
        return PortalApiResult.error({ code: r.status, description: r.statusText });
      });
  }

  public getAuthToken(cancelToken?: CancelToken): Promise<PortalApiResult<AuthTokenDto | null>> {
    return this.post<AuthTokenDto>(this.setParams(this.getAuthTokenQuery), cancelToken);
  }

  public getMenus(cancelToken?: CancelToken): Promise<PortalApiResult<PortalMenuResponse | null>> {
    return this.post<PortalMenuResponse>(this.setParams(this.getMenusQuery), cancelToken);
  }

  public getConfig(cancelToken?: CancelToken): Promise<PortalApiResult<PortalConfigDto | null>> {
    return this.post<PortalConfigDto>(this.setParams(this.getConfigQuery), cancelToken);
  }

  public getCSRFToken(): Promise<PortalApiResult<Record<string, string> | null>> {
    return axios
      .post(
        "/PortalServlet",
        new URLSearchParams({
          query: "dummyToken",
        })
      )
      .then((response) => {
        // update x-auth-token in localStorage
        response.headers[this.xAuthToken] && this.setLocalToken(response.headers[this.xAuthToken]);
        return PortalApiResult.valid(response.data.result);
      })
      .catch((error) => {
        const r = error.response;
        this.setLocalToken("");
        return PortalApiResult.error({ code: r.status, description: r.statusText });
      });
  }

  public getAccountInfo(cancelToken?: CancelToken): Promise<PortalApiResult<UserPreferencesResponse | null>> {
    return this.post<UserPreferencesResponse>(this.setParams(this.getAccountInfoQuery), cancelToken);
  }

  public searchEngines(
    search: string,
    cancelToken?: CancelToken
  ): Promise<PortalApiResult<DeviceLocatorResponse | null>> {
    return this.post<DeviceLocatorResponse>(
      this.setParams(this.searchEnginesQuery, {
        searchToken: search,
      }),
      cancelToken
    );
  }

  public getFinderAccessUrl(
    engineId: number,
    sourceName: string,
    sourceType: SourceType,
    cancelToken?: CancelToken
  ): Promise<PortalApiResult<FinderUrl | null>> {
    return this.post<FinderUrl>(
      this.setParams(
        sourceType === "source" ? this.getFinderSourceViewAccessUrlQuery : this.getFinderUserViewAccessUrlQuery,
        {
          engineID: String(engineId),
          ...(sourceType === "source" ? { source: sourceName } : { user: sourceName }),
        }
      ),
      cancelToken
    );
  }

  public async logout(): Promise<void> {
    await axios
      .post("/login/logout")
      .catch(() => undefined) // silently fail
      .finally(() => this.setLocalToken(""));
  }

  public getFinderUpdateLink(
    arch: string,
    cancelToken?: CancelToken
  ): Promise<PortalApiResult<Record<string, string> | null>> {
    return this.post(this.setParams(this.getFinderUpdateLinkQuery, { arch }), cancelToken);
  }

  private setParams(query: string, params?: Record<string, string>): URLSearchParams {
    return new URLSearchParams({ query, ...params });
  }

  private getLocalToken(): string {
    return localStorage.getItem("token") !== null ? `${localStorage.getItem("token")}` : "";
  }

  public setLocalToken(token: string): void {
    localStorage.setItem("token", token);
  }
}
