import { loadRemote } from "@module-federation/runtime";
import React, { type ComponentType, type LazyExoticComponent, useState } from "react";
import useDynamicScript from "./useDynamicScript";

interface DynamicComponentType {
  default: ComponentType;
}

const loadComponent = (scope: string, module: string): (() => Promise<DynamicComponentType>) => {
  return async (): Promise<DynamicComponentType> => {
    // Initializes the share scope. This fills it with known provided modules from this build and all remotes
    const component: DynamicComponentType = (await loadRemote(`${scope}/${module.slice(2)}`)) as DynamicComponentType;
    return component;
  };
};

const componentCache = new Map();
const useFederatedComponent = (remoteUrl: string, scope: string, module: string) => {
  const key = `${remoteUrl}-${scope}-${module}`;
  // biome-ignore lint/suspicious/noExplicitAny: FIXME
  const [Component, setComponent] = useState<null | LazyExoticComponent<ComponentType<any>>>(null);

  const { ready, errorLoading } = useDynamicScript(remoteUrl);
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only recalculate when key changes
  React.useEffect(() => {
    if (Component) {
      setComponent(null);
    }
  }, [key]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: key includes all dependencies (scope/module)
  React.useEffect(() => {
    if (ready && !Component) {
      const RemoteComponent = React.lazy(loadComponent(scope, module));
      componentCache.set(key, RemoteComponent);
      setComponent(RemoteComponent);
    }
  }, [Component, ready, key]);

  return { errorLoading, ready, Component };
};

export default useFederatedComponent;
