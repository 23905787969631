import type { UserOptions } from "@module-federation/runtime/dist/src/type";
import React from "react";
import type { DynamicModuleType } from "../types";

const isDevelopment = process.env.NODE_ENV === "development";
const host = location.hostname;
const remoteEntryUrl = `https://${host}/nxaws/ui/ps-appearance-page/remoteEntry.js?v=[Date.now()]`;
const localRemoteEntryUrl = `http://${host}:3001/remoteEntry.js?v=[Date.now()]`;

const entryUrl = isDevelopment ? localRemoteEntryUrl : remoteEntryUrl;
//TODO move to do configmap
export const apperancePageFederationConfig: UserOptions = {
  name: "app1",
  version: "1.2.3",
  remotes: [
    {
      name: "appearancePageMfe",
      entry: entryUrl,
      version: "1.2.3",
    },
  ],
  shared: {
    react: {
      version: "17.0.2",
      scope: "default",
      lib: () => React,
      shareConfig: {
        singleton: true,
        requiredVersion: "17.0.2",
      },
    },
    "react-dom": {
      version: "17.0.2",
      scope: "default",
      lib: () => "ReactDOM",
      shareConfig: {
        singleton: true,
        requiredVersion: "17.0.2",
      },
    },
    "@nexthink/apollo-components": {
      version: "7.21.0",
      scope: "default",
      lib: () => "@nexthink/apollo-components",
      shareConfig: {
        singleton: true,
        requiredVersion: "7.21.0",
      },
    },
  },
};

export const dynamicComponentData: DynamicModuleType = {
  url: entryUrl,
  scope: "appearancePageMfe",
  module: "./AppearanceApp",
  version: "1.0.0",
};
