import { usePrevious } from "@nexthink/apollo-components/lib/hooks/usePrevious";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { usePortalContext } from "../context/PortalContext";
import { useProductShellContext } from "../context/ProductShellContext";
import { reportError } from "../observability";
import { useGetInfinityTransitionState } from "./useGetInfinityTransitionState";
import { useGetMenuResponse } from "./useGetMenuResponse";

export const loadScript = () => {
  const scriptTag = document.createElement("script");
  scriptTag.id = "nx-pendo-script";
  scriptTag.type = "application/javascript";
  scriptTag.async = true;

  // Pendo snippet
  scriptTag.textContent = `(function (apiKey) {
      (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = [];
          v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
          for (w = 0, x = v.length; w < x; ++w) (function (m) {
              o[m] = o[m] || function () {
                  o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
          y = e.createElement(n);
          y.async = !0;
          y.src = 'https://content.insights.nexthink.com/agent/static/' + apiKey + '/pendo.js';
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
  })('1a612c3e-c872-4dbb-4aba-3a4830d1951a');`;

  if (!document.head.querySelector("#nx-pendo-script")) {
    document.head.appendChild(scriptTag);
  }
};
const updatePendo = (options: pendo.Identity) => {
  try {
    pendo.updateOptions(options);
  } catch (e) {
    reportError(e, { feature: "pendoUpdateOptions" });
  }
};

export const usePendo = () => {
  const [installed, setInstalled] = useState(false);
  const [ready, setReady] = useState(false);
  const retries = useRef(0);
  const maxRetries = 20;

  const state = useGetInfinityTransitionState();
  const { hasPortal } = useAuthContext();
  const { theme, serviceConfig } = useProductShellContext();
  const { version: portalVersion, profileName } = usePortalContext();
  const menuResponse = useGetMenuResponse();

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  (window as any).productShellInfo = {
    windowTheme: theme,
    userEmail: serviceConfig?.user?.email,
  };

  const pendoUpdateProps = useMemo(() => {
    if (!menuResponse || !ready) {
      return {};
    }
    const keys = [
      ...menuResponse.groupedMenus.flatMap((group) => group.menuItems.map((menuItem) => `access_${menuItem.id}`)),
      ...menuResponse.menuActions.filter((action) => action.type === "config").map((action) => `manage_${action.id}`),
      ...menuResponse.items.filter((menuItem) => menuItem.menuId === "admin").map((menuItem) => `admin_${menuItem.id}`),
    ];

    return {
      id: serviceConfig?.productId,
      ...(serviceConfig?.demoSettings.enabled && {
        email: serviceConfig.user?.email,
        ...(hasPortal && { profile_name: profileName }),
        mtp_roles: serviceConfig.user?.mtpRoles,
        browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
      ...keys.reduce(
        (acc, key) => {
          // replace / and - in item names with _
          const k = key.replace(/[/-]/g, "_");
          acc[k] = true;
          return acc;
        },
        {} as Record<string, boolean>
      ),
    };
  }, [
    menuResponse,
    ready,
    serviceConfig?.productId,
    serviceConfig?.demoSettings.enabled,
    serviceConfig?.user.email,
    profileName,
    serviceConfig?.user.mtpRoles,
    hasPortal,
  ]);
  const previousPendoUpdateProps = usePrevious(pendoUpdateProps);

  useEffect(() => {
    loadScript();

    const interval = setInterval(() => {
      const isInDOM = pendo?.isReady && !pendo.isReady();

      if (isInDOM) {
        setInstalled(true);
        clearInterval(interval);
      }

      retries.current++;
      if (retries.current >= maxRetries) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const fn = () => {
      const canBeInitialized = installed && !ready && serviceConfig?.isTelemetryEnabled && serviceConfig?.productId;

      if (canBeInitialized) {
        try {
          pendo.initialize({
            visitor: { id: serviceConfig.productId },
            account: {
              id: serviceConfig.licenseId,
              aws_cluster: serviceConfig.cluster,
              aws_region: serviceConfig.region,
              ...(state.length > 0 && { infinity_transition_state: state }),
              ...(portalVersion && { portal_version: portalVersion }),
              ...(serviceConfig.mtpVersion && { stp_version: serviceConfig.version }),
              ...(serviceConfig.mtpVersion && { mtp_version: serviceConfig.mtpVersion }),
            },
          });
          setReady(true);
        } catch (e) {
          reportError(e, { feature: "pendoInitialize" });
        }
      }
    };

    fn();
  }, [ready, installed, portalVersion, serviceConfig, state]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const canBeUpdated =
      pendoUpdateProps &&
      serviceConfig &&
      serviceConfig.productId &&
      Object.keys(pendoUpdateProps).length &&
      !isEqual(pendoUpdateProps, previousPendoUpdateProps);

    if (canBeUpdated) {
      updatePendo({
        visitor: { ...pendoUpdateProps },
        account: {
          id: serviceConfig?.licenseId,
          aws_cluster: serviceConfig.cluster,
          aws_region: serviceConfig.region,
          ...(state.length > 0 && { infinity_transition_state: state }),
          ...(portalVersion && { portal_version: portalVersion }),
          ...(serviceConfig.version && { stp_version: serviceConfig.version }),
          ...(serviceConfig.mtpVersion && { mtp_version: serviceConfig.mtpVersion }),
        },
      });
    }
  }, [pendoUpdateProps, previousPendoUpdateProps]);
};

export default usePendo;
