import uniqBy from "lodash/uniqBy";
import { useGetMenuResponse } from "../../hooks/useGetMenuResponse";
import type { MenuItem } from "../../services/types/menu";
import type { MenuResponse } from "../../services/types/menu";
import { useProductShellNavigationContext } from "../ProductShellNavigationContext";
import { getItemsByTagName } from "../util";
import { useGetFilterItemsBySearchQuery } from "./useGetFilterItemsBySearchQuery";

export const useGetMenuItemsByGroupList = (menuId: string, menuGroupIds?: (string | undefined)[]): MenuItem[] => {
  const navigationContext = useProductShellNavigationContext();
  const menuResponse = useGetMenuResponse();
  const filterBySearchQuery = useGetFilterItemsBySearchQuery();

  const currentMenu = findCurrentMenu(menuResponse, menuId);
  const hasEnabledTags = currentMenu?.tags;
  let menuItems: MenuItem[] = [];

  if (menuGroupIds?.length === 1 || menuGroupIds === undefined) {
    const [menuGroupId] = menuGroupIds || [];
    menuItems =
      menuResponse?.items
        .filter((item) => item.menuId === menuId && item.menuGroupId === menuGroupId)
        .filter(filterBySearchQuery) || [];
  } else {
    menuItems =
      menuResponse?.items
        .filter((i) => i.menuId === menuId && i.menuGroupId && menuGroupIds?.includes(i.menuGroupId))
        .filter(filterBySearchQuery) || [];
  }

  if (hasEnabledTags) {
    const menuItemsMatchedTag = getItemsByTagName(navigationContext?.searchQuery, menuResponse?.items || [], menuId);
    return uniqBy([...(menuItemsMatchedTag || []), ...menuItems], "id");
  }

  return menuItems;
};

const findCurrentMenu = (menuResponse: MenuResponse | null, menuId: string) => {
  return menuResponse?.groupedMenus.flatMap((group) => group.menuItems).find((menu) => menu.id === menuId);
};
