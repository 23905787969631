import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { type FC, type ReactElement, useContext } from "react";

import { apolloSpaceInset3X, apolloSpaceStack1X, apolloSpaceStack3X } from "@nexthink/apollo-components/lib/tokens";
import EmptyDark from "./empty-dark.svg";
import EmptyLight from "./empty-light.svg";
import NoResDark from "./no-results-dark.svg";
import NoResLight from "./no-results-light.svg";

import Box from "@nexthink/apollo-components/lib/components/box/Box";
import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Text from "@nexthink/apollo-components/lib/components/text/Text";

export interface EmptyStateProps {
  message: string;
  type?: "empty" | "no-result";
}

export enum EmptyStateType {
  Empty = "empty",
  No_Result = "no-result",
}

const StateImage = ({ themeName, type }: { themeName: string; type: "empty" | "no-result" }): ReactElement | null => {
  const isLightTheme = themeName === "light";
  if (type === EmptyStateType.Empty) {
    return isLightTheme ? <EmptyLight /> : <EmptyDark />;
  }
  if (type === EmptyStateType.No_Result) {
    return isLightTheme ? <NoResLight /> : <NoResDark />;
  }
  return null;
};

const EmptyState: FC<EmptyStateProps> = ({ message, type = "empty", ...rest }) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Flex p={apolloSpaceInset3X} flexDirection="column" alignItems="center" {...rest}>
      <Box my={apolloSpaceStack3X}>
        <StateImage themeName={themeContext.name} type={type} />
      </Box>
      <Box mb={apolloSpaceStack1X} role="alert">
        <Text color={themeContext.colors.apolloColorTextSecondary}>{message}</Text>
      </Box>
    </Flex>
  );
};

export default EmptyState;
