import { isEqual } from "lodash";
import { type FC, useEffect } from "react";
import { useMenuContext } from "../../context/MenuContext";
import useRefreshMenu from "../../hooks/useRefreshMenu";
import useServices from "../../hooks/useServices";
import type { Menu } from "../../services/types/menu";
import { useGetMenuGroups } from "../hooks/useGetMenuGroups";
import PanelCustomDashboardsSimple from "./PanelCustomDashboardsSimple";
import PanelCustomDashboardsTabbed from "./PanelCustomDashboardsTabbed";

const PanelCustomDashboards: FC<{ menu: Menu }> = ({ menu }) => {
  const { getMenus } = useServices();
  const { refreshMenu } = useRefreshMenu();
  const { menu: contextMenu } = useMenuContext();

  const rootGroups = useGetMenuGroups(menu.id, undefined);
  const shouldShowTabs = rootGroups.length > 1;

  useEffect(() => {
    refreshMenuIfNeeded();
  }, []);

  const refreshMenuIfNeeded = async () => {
    const menuFromOrigin = await getMenus();
    if (!menuFromOrigin) {
      return;
    }
    const filteredItemsFromContext = contextMenu?.items.filter((item) => item.menuId === menu.id);
    const filteredItemsFromOrigin = menuFromOrigin.items.filter((item) => item.menuId === menu.id);
    if (!isEqual(filteredItemsFromContext, filteredItemsFromOrigin)) {
      await refreshMenu();
    }
  };

  return shouldShowTabs ? <PanelCustomDashboardsTabbed menu={menu} /> : <PanelCustomDashboardsSimple menu={menu} />;
};

export default PanelCustomDashboards;
