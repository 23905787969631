import { Box, Flex } from "@nexthink/apollo-components";
import { Tab, TabPanel } from "@nexthink/apollo-components/lib/components/tabs";
import { apolloSpaceInsetHalfX } from "@nexthink/apollo-components/lib/tokens";
import { type FC, useEffect, useState } from "react";
import { useGetMenuTags } from "../../Navigation/hooks/useGetMenuTags";
import { useGetMenuItemsByGroupList } from "../../Navigation/hooks/usetGetMenuItemsByGroupList";
import type { Menu, MenuGroup as MenuGroupType, MenuItem as MenuItemType } from "../../services/types/menu";
import { MenuGroup } from "../MenuGroup/MenuGroup";
import { MenuItem } from "../MenuItem/MenuItem";
import { useGetAllMenuGroupsForMenu } from "../hooks/useGetAllMenuGroupsForMenu";
import { type MenuTag, getMenuItemProps } from "../util";
import { StyledTabs } from "./PanelCustomDashboards.style";
import { StyledPanelContent } from "./PanelDefault.style";
import PanelDetailsWithTags from "./PanelDetailsWithTags";
import { StyledMenuGroupTitle, StyledTabList } from "./PanelTabbed.style";

enum TabName {
  All = "All",
  Private = "Private",
  Shared = "Shared",
}

const AllItemsTabPanelDetails = ({
  rootGroups,
  tags,
  items,
  hasTags,
}: {
  rootGroups: MenuGroupType[];
  tags: MenuTag[];
  items: MenuItemType[];
  hasTags: boolean;
}) => {
  return (
    <TabPanel key={TabName.All} tab={TabName.All}>
      <StyledPanelContent>
        {hasTags
          ? rootGroups.map((group) => (
              <Flex p={apolloSpaceInsetHalfX} key={group.id} id="flexbox-column" flexDirection="column">
                <Box key={group.title}>
                  <StyledMenuGroupTitle>{group.title}</StyledMenuGroupTitle>
                </Box>
                <Box key={group.id}>
                  <PanelDetailsWithTags menuTags={tags} menuItems={items} menuGroupId={group.id} />
                </Box>
              </Flex>
            ))
          : rootGroups.map((group) => <MenuGroup key={group.id} id={group.id} level={0} />)}
      </StyledPanelContent>
    </TabPanel>
  );
};

const PanelTabbed: FC<{ menu: Menu }> = ({ menu }) => {
  const { tags: hasTags = false } = menu;
  const allGroups = useGetAllMenuGroupsForMenu(menu.id);
  const rootGroups = allGroups.filter((group) => !group.menuGroupId);
  const [selectedTab, setSelectedTab] = useState(TabName.All);
  const menuGroupIdList: string[] = allGroups.map((group) => group.id);
  const items = useGetMenuItemsByGroupList(menu.id, menuGroupIdList);
  const tags = useGetMenuTags(menu.id);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (selectedTab) {
      const tabGroups = allGroups.filter((g) => g.menuGroupId === selectedTab) || [];
      const hasTabGroups = tabGroups.length > 0;
      if (hasTabGroups) {
        const panel = document.getElementById(`panel-${tabGroups[0].menuGroupId}`);
        const firstElement = panel?.querySelectorAll("[role='menuitem']")?.[0];
        (firstElement as HTMLElement)?.focus();
      }
    }
  }, [menu.id]);

  const isAllTabSelected = selectedTab === TabName.All;
  const hasItems = items.length > 0;

  return (
    <StyledTabs>
      <StyledTabList>
        {hasItems && (
          <Tab
            key={TabName.All}
            id={TabName.All}
            selected={isAllTabSelected}
            onSelected={(): void => setSelectedTab(TabName.All)}
          >
            {TabName.All}
          </Tab>
        )}
        {rootGroups.map((group) => (
          <Tab
            key={group.id}
            id={group.id}
            selected={selectedTab === group.id}
            onSelected={(): void => setSelectedTab(group.id as TabName)}
          >
            {group.id.toLowerCase().includes(TabName.Private.toLowerCase()) ? TabName.Private : TabName.Shared}
          </Tab>
        ))}
      </StyledTabList>

      {/* Show groups and items in All tab content */}
      {isAllTabSelected && (
        <AllItemsTabPanelDetails rootGroups={rootGroups} tags={tags} items={items} hasTags={hasTags} />
      )}

      {/* Show only items in Shared/Private tab content */}
      {rootGroups.map((group) => (
        <TabPanel key={group.id} tab={group.id}>
          <StyledPanelContent>
            {hasTags ? (
              <PanelDetailsWithTags menuTags={tags} menuItems={items} menuGroupId={group.id} />
            ) : (
              items
                .filter((item) => item.menuGroupId?.toLowerCase().includes(group.id))
                .map((item) => <MenuItem key={item.id} {...getMenuItemProps(item)} />)
            )}
          </StyledPanelContent>
        </TabPanel>
      ))}
    </StyledTabs>
  );
};
export default PanelTabbed;
