import { useMemo } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useProductShellContext } from "../context/ProductShellContext";
import { setObservabilityContext } from "../observability";
import useProductShellService from "./useProductShellService";

export const useRefreshServiceConfig = (): { refreshServiceConfig: () => Promise<void> } => {
  const { isAuthenticated } = useAuthContext();
  const { getServiceConfig } = useProductShellService();
  const { setServiceConfig } = useProductShellContext();

  return useMemo(
    () => ({
      refreshServiceConfig: async () => {
        if (isAuthenticated) {
          const config = await getServiceConfig();
          if (config) {
            setObservabilityContext({ userId: config.productId, licenseId: config.licenseId });
          }
          setServiceConfig(config);
        }
      },
    }),
    [isAuthenticated, getServiceConfig, setServiceConfig]
  );
};
