import axios, { type AxiosRequestConfig, type AxiosRequestHeaders, type CancelToken, type Method } from "axios";
import type { MenuResponse } from "./types/menu";
import type { PortalMenuResponse } from "./types/portal-menu";
import type { ServiceConfig } from "./types/service-config";

// copy paste form Portal service ResultStatus / ValidationError / ProductShellApiResponse / ProductShellApiResult
interface ValidationError {
  field: string;
  code: string;
  message: string;
}

export interface ResultStatus {
  code: number;
  description: string;
  details?: string;
  errors?: Record<string, Array<ValidationError>>;
}

export interface ProductShellApiResponse<T> {
  resultStatus: ResultStatus;
  result: T | null;
}

export type ValidateClaimsBody =
  | { method: "hasAnyClaim" | "hasAllClaims"; claims: string[] }
  | { method: "hasPatternClaim"; patternClaim: string }
  | { method: "hasClaimValue"; claim: string; value: string };

export interface LDFlagValue {
  [id: string]: boolean;
}

export type ValidateClaimsResponse = { result: boolean };

// copy paste form PortalApiResult
export class ProductShellApiResult<T> {
  public static error(resultStatus: ResultStatus): ProductShellApiResult<null> {
    return new ProductShellApiResult(resultStatus, null);
  }

  public static valid<U>(result: U): ProductShellApiResult<U> {
    return new ProductShellApiResult({ code: 0, description: "request successful" }, result);
  }

  public static cancel(): ProductShellApiResult<null> {
    return new ProductShellApiResult({ code: -1, description: "request canceled" }, null);
  }

  public constructor(
    public readonly resultStatus: ResultStatus,
    public readonly result: T
  ) {}

  public hasError(): boolean {
    return this.result === null && this.resultStatus.code !== undefined && this.resultStatus.code !== -1;
  }

  public isStatusUnauthorized(): boolean {
    return this.resultStatus.code === 401;
  }

  public isAxiosCancel(): boolean {
    return this.resultStatus.code === -1;
  }
}

export interface ProductShellServiceProps {
  setApiAuthInterceptorFn(tokenFn: (cancelToken?: CancelToken) => Promise<string | null>): void;
  setLang(lang: string): void;
  getMenu(
    portalMenuResponse: PortalMenuResponse,
    cancelToken?: CancelToken
  ): Promise<ProductShellApiResult<MenuResponse | null>>;
  getLDFlagValue(flag: string, cancelToken?: CancelToken): Promise<ProductShellApiResult<LDFlagValue | null>>;
  getServiceConfig(cancelToken?: CancelToken): Promise<ProductShellApiResult<ServiceConfig | null>>;
  validateClaims(
    data: ValidateClaimsBody,
    cancelToken?: CancelToken
  ): Promise<ProductShellApiResult<ValidateClaimsResponse | null>>;
  getDynamicMenu(menuId: string, cancelToken?: CancelToken): Promise<ProductShellApiResult<MenuResponse | null>>;
}

export const makeAuthInterceptor = (tokenFn: () => Promise<string | null>) => {
  return async (config: AxiosRequestConfig) => ({
    ...config,
    headers: {
      ...(config.headers || {}),
      Authorization: `Bearer ${await tokenFn()}`,
    } as AxiosRequestHeaders,
  });
};

export default class ProductShellService implements ProductShellServiceProps {
  private readonly usingPortalAuth = window.usingPortalAuth;
  private readonly api;
  private readonly apiUrl = "/apigateway/api/v1/product-shell";
  private readonly getMenuQuery = "/menu";
  private readonly getMenuLogoQuery = "/static/product-shell/menu-logo";
  private readonly getDynamicMenuQuery = "/menu/dynamic";
  private readonly getLDFlagValueQuery = "/flag";
  private readonly getServiceConfigQuery = "/config";
  private readonly validateClaimsQuery = "/claims";
  private lang = "en";

  public constructor() {
    this.api = axios.create({
      baseURL: this.apiUrl,
    });
  }

  public setApiAuthInterceptorFn(tokenFn: (cancelToken?: CancelToken) => Promise<string | null>) {
    // add interceptor only once
    if ((this.api.interceptors.request as unknown as { handlers: object[] }).handlers.length === 0) {
      this.api.interceptors.request.use(makeAuthInterceptor(tokenFn));
    }
  }

  public setLang(lang: string) {
    this.lang = lang;
  }

  private async request<T, U = unknown>(
    method: Method,
    url: string,
    data?: U,
    cancelToken?: CancelToken
  ): Promise<ProductShellApiResult<T | null>> {
    const config: AxiosRequestConfig = {
      url,
      method,
      data,
      headers: {
        "Content-Type": "application/json",
        "Content-Language": this.lang,
      },
      cancelToken,
    };

    return this.api
      .request<ProductShellApiResponse<T>>(config)
      .then((response) => {
        // the result can be null: Error in BE (result = null, resultStatus code = 1, description = "Failed")
        if (response.status === 200 && response.data.resultStatus.code === 0 && response.data.result) {
          return ProductShellApiResult.valid<T>(response.data.result);
        }
        return ProductShellApiResult.error(response.data.resultStatus);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          return ProductShellApiResult.cancel();
        }
        const r = error.response || {};
        return ProductShellApiResult.error({ code: r.status, description: r.statusText });
      });
  }

  // copy from PortalService as well
  private get<T>(url: string, cancelToken?: CancelToken): Promise<ProductShellApiResult<T | null>> {
    return this.request<T>("get", url, undefined, cancelToken);
  }

  private post<T, U>(url: string, data: U, cancelToken?: CancelToken): Promise<ProductShellApiResult<T | null>> {
    return this.request<T, U>("post", url, data, cancelToken);
  }

  public getMenu(
    portalMenuResponse: PortalMenuResponse | null,
    cancelToken?: CancelToken
  ): Promise<ProductShellApiResult<MenuResponse | null>> {
    if (this.usingPortalAuth && !portalMenuResponse) {
      return Promise.resolve(ProductShellApiResult.error({ code: -1, description: "PortalResponse is null" }));
    }
    return this.post<MenuResponse, PortalMenuResponse | Record<string, never>>(
      this.getMenuQuery,
      portalMenuResponse || {},
      cancelToken
    );
  }

  public async getMenuLogo(cancelToken?: CancelToken): Promise<string> {
    const response = await this.api.get(this.getMenuLogoQuery, { responseType: "blob", cancelToken });
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(response.data);
    });
  }

  public getDynamicMenu(
    menuId: string,
    cancelToken?: CancelToken
  ): Promise<ProductShellApiResult<MenuResponse | null>> {
    return this.get<MenuResponse>(`${this.getDynamicMenuQuery}/${menuId}`, cancelToken);
  }

  public getLDFlagValue(flag: string, cancelToken?: CancelToken): Promise<ProductShellApiResult<LDFlagValue | null>> {
    return this.get<LDFlagValue>(`${this.getLDFlagValueQuery}/${flag}`, cancelToken);
  }

  public getServiceConfig(cancelToken?: CancelToken): Promise<ProductShellApiResult<ServiceConfig | null>> {
    return this.get<ServiceConfig>(`${this.getServiceConfigQuery}`, cancelToken);
  }

  public validateClaims(
    data: ValidateClaimsBody,
    cancelToken?: CancelToken
  ): Promise<ProductShellApiResult<ValidateClaimsResponse | null>> {
    return this.post<ValidateClaimsResponse, ValidateClaimsBody>(`${this.validateClaimsQuery}`, data, cancelToken);
  }
}
