import { triggerUpdateSignal } from "../Navigation/hooks/useLogoUpdateTrigger";
import type DomainEvent from "../eventDrivenBusEvents/eventBus/domainEvent";

export default async function updateImageOnImageSet(event: DomainEvent & { type: string }) {
  if (event.type !== "menu-logo" || !triggerUpdateSignal.updateSignal) {
    return;
  }

  triggerUpdateSignal.updateSignal();
  await Promise.resolve();
}
