import Text from "@nexthink/apollo-components/lib/components/text/Text";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { type FC, useCallback, useContext } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { useUserContext } from "../../context/UserContext";
import { DOWNLOAD_FINDER_DIALOG_ID } from "../../dialogs/DownloadFinderDialog/DownloadFinderDialog";
import { useGetMenuResponse } from "../../hooks/useGetMenuResponse";
import { useSendLogoutToAmplify } from "../../hooks/useSendToAmplify";
import { MenuItem } from "../MenuItem/MenuItem";
import {
  MY_ACCOUNT_INSTALL_FINDER_ITEM_ID,
  MY_ACCOUNT_ITEM_TITLE_KEY,
  MY_ACCOUNT_LOGOUT_TITLE_KEY,
  MY_ACCOUNT_MENU_ID,
  MY_ACCOUNT_MY_ACCOUNT_ITEM_ID,
  MY_ACCOUNT_TITLE_KEY,
  MY_ACCOUNT_URL,
  NAVIGATION_TRANSLATION_NAMESPACE,
} from "../constants";
import { useGetMenuItemsByGroupList } from "../hooks/usetGetMenuItemsByGroupList";
import { StyledPanelContainer, StyledPanelContent } from "./PanelDefault.style";
import { StyledPanelTitleContainer } from "./PanelTitle.style";

const PanelMyAccount: FC = () => {
  const { user } = useUserContext();
  const theme = useContext(ThemeContext);
  const { t } = useNxTranslation();
  const { logoutFn: logout } = useAuthContext();
  const amplifyLogoutFn = useSendLogoutToAmplify();
  const items = useGetMenuItemsByGroupList(MY_ACCOUNT_MENU_ID);
  const menu = useGetMenuResponse();

  const myAccountMenuItem = menu?.apps.find((app) => app.id === MY_ACCOUNT_MY_ACCOUNT_ITEM_ID);
  const installFinderMenuItem = items.find((item) => item.id === MY_ACCOUNT_INSTALL_FINDER_ITEM_ID);

  const handleOnClick = useCallback(async () => {
    amplifyLogoutFn();
    await logout();
  }, [amplifyLogoutFn, logout]);

  return (
    <StyledPanelContainer>
      <StyledPanelTitleContainer>
        <Text variant="displaySmall">
          {user?.fullName || t(MY_ACCOUNT_ITEM_TITLE_KEY, { ns: NAVIGATION_TRANSLATION_NAMESPACE })}
        </Text>
      </StyledPanelTitleContainer>
      <StyledPanelContent role="menu">
        {myAccountMenuItem && (
          <MenuItem
            id={`${MY_ACCOUNT_MENU_ID}-my-account`}
            url={MY_ACCOUNT_URL}
            title={t(MY_ACCOUNT_TITLE_KEY, { ns: NAVIGATION_TRANSLATION_NAMESPACE })}
          />
        )}
        {installFinderMenuItem && (
          <MenuItem
            id={`${MY_ACCOUNT_MENU_ID}-download-finder`}
            dialogId={DOWNLOAD_FINDER_DIALOG_ID}
            title={installFinderMenuItem.title}
          />
        )}
        <MenuItem
          id={`${MY_ACCOUNT_MENU_ID}-logout`}
          title={t(MY_ACCOUNT_LOGOUT_TITLE_KEY, { ns: NAVIGATION_TRANSLATION_NAMESPACE })}
          color={theme.colors.apolloColorTextDestructive}
          onClick={handleOnClick}
        />
      </StyledPanelContent>
    </StyledPanelContainer>
  );
};

export default PanelMyAccount;
