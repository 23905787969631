import Box from "@nexthink/apollo-components/lib/components/box/Box";
import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import {
  apolloSpaceInset2X,
  apolloSpaceInset3X,
  apolloSpaceInset6X,
  apolloZindexDialogOverlay,
} from "@nexthink/apollo-components/lib/tokens";
import styled from "styled-components";

import { MENU_WIDTH_EXPANDED } from "../BlueBar.style";

export const TRANSITION_DURATION = ".2s";
export const TRANSITION_DELAY = ".3s";
export const PANEL_ITEM_HEIGHT = 24;

export const StyledPanelOverlay = styled.div<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  background: theme.colors.apolloColorBackgroundMainNavMenuOverlay,
  opacity: isOpen ? 1 : 0,
  fontSize: "1rem",
  height: "100%",
  left: 0,
  marginLeft: MENU_WIDTH_EXPANDED,
  position: "fixed",
  right: 0,
  top: 0,
  transition: isOpen
    ? `opacity ${TRANSITION_DURATION}` // when showing, visibility true from the start for auto-focus
    : `visibility ${TRANSITION_DURATION}, opacity ${TRANSITION_DURATION}`, // when hiding, "animate" visibility
  visibility: isOpen ? "visible" : "hidden",
  zIndex: apolloZindexDialogOverlay,
}));

export const StyledPanelContainer = styled(Flex)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  flexDirection: "column",
  background: theme.colors.apolloColorBackgroundMainNavMenu,
  boxShadow: theme.colors.apolloBoxShadowDialog,
  height: "100%",
  overflow: "hidden",
  padding: `${apolloSpaceInset6X} ${apolloSpaceInset3X} ${apolloSpaceInset2X} ${apolloSpaceInset2X}`,
  transition: `transform ${TRANSITION_DURATION}`,
  transform: `translateX(${isOpen ? 0 : -100}%)`,
  width: 360,

  "& li": {
    listStyle: "none",
  },
}));

export const StyledCloseIcon = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "14px",
  top: "16px",
  "&:focus": {
    outline: `1px solid ${theme.colors.apolloColorStrokeFocus}`,
    boxShadow: theme.colors.apolloBoxShadowFormElementFocusHighlight,
  },
}));
