import Link, { type LinkProps } from "@nexthink/apollo-components/lib/components/link";
import { trapEvent } from "@nexthink/apollo-components/lib/utils/EventHelpers";
import { type KeyboardEvent, type MouseEvent, forwardRef } from "react";
import { useHistory } from "react-router-dom";

export default forwardRef<HTMLElement, LinkProps>(function ForwardLink(props, ref) {
  const history = useHistory();
  const { onClick, ...rest } = props;

  const handleOnClick = (e: MouseEvent | KeyboardEvent): void => {
    if (props.to && props.to !== history.location.pathname) {
      history.push(props.to);
    }
    onClick?.(e);
  };

  const handleOnKeyDown = (e: KeyboardEvent): void => {
    if (e.key === "Enter" || e.key === " ") {
      trapEvent(e);
      handleOnClick(e);
    }
  };

  return <Link ref={ref} onClick={handleOnClick} onKeyDown={handleOnKeyDown} {...rest} />;
});
