import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import type { ApolloColor } from "@nexthink/apollo-components/lib/tokens";
import { type FC, type KeyboardEventHandler, type MouseEventHandler, useContext } from "react";
import Icon from "../../components/Icon";
import { useProductShellContext } from "../../context/ProductShellContext";
import type { GenericMenuConfigComponent, MenuConfigComponentResponse } from "../../services/types/menu-shared";
import { useProductShellNavigationContext } from "../ProductShellNavigationContext";
import { HighlightedText } from "./HighlightedText";
import {
  StyledIconContainer,
  StyledMenuItem,
  StyledMenuItemContainer,
  StyledMenuItemText,
  StyledOverviewIcon,
} from "./MenuItem.style";

export type MenuItemProps = MenuConfigComponentResponse<GenericMenuConfigComponent> & {
  dialogId?: string;
  color?: ApolloColor;
  onClick?: MouseEventHandler | KeyboardEventHandler;
  isAction?: boolean;
  isOverviewIcon?: boolean;
};

export const MenuItem: FC<MenuItemProps> = ({
  id,
  title,
  url,
  icon,
  dialogId,
  color,
  onClick,
  isAction,
  isOverviewIcon,
}) => {
  const theme = useContext(ThemeContext);
  const navigationContext = useProductShellNavigationContext();
  const activeItems = navigationContext?.activeItems;
  const closeMenu = navigationContext?.closeMenu;
  const isEmptySearch = navigationContext.searchQuery === "";

  const { openDialog } = useProductShellContext();
  const isActive = activeItems?.activeMenuItemId === id;

  const handleOnClick = () => {
    if (dialogId) {
      openDialog(dialogId);
    }
    closeMenu();
  };

  return (
    // biome-ignore lint/a11y/useValidAriaRole: FIXME
    <StyledMenuItemContainer role="none">
      <StyledMenuItem
        aria-current={isActive}
        id={id}
        to={url}
        onClick={onClick || handleOnClick}
        role="menuitem"
        isActive={isActive}
        tabIndex={0}
      >
        {/* {icon && !isOverviewIcon ? (
          <StyledIconContainer>
            <Icon title={title} icon={icon} color={color} />
          </StyledIconContainer>
        ) : (
          <StyledIconContainer>
            <StyledOverviewIcon color={color} src={icon} />
          </StyledIconContainer>
        )} */}
        {icon && (
          <StyledIconContainer>
            {isOverviewIcon ? (
              <StyledOverviewIcon color={color} src={icon} />
            ) : (
              <Icon title={title} icon={icon} color={color} />
            )}
          </StyledIconContainer>
        )}
        <StyledMenuItemText title={title} variant="bodySmall" color={color || theme.colors.apolloColorTextPrimary}>
          {isEmptySearch || isAction ? (
            title
          ) : (
            <HighlightedText text={title} highlight={navigationContext.searchQuery} />
          )}
        </StyledMenuItemText>
      </StyledMenuItem>
    </StyledMenuItemContainer>
  );
};
