import { subscriptions } from "../subscribers/eventSubscriptions";
import { loadJsFile } from "../utils";
import type Command from "./commandBus/command";
import type CommandBus from "./commandBus/commandBus";
import type CommandHandler from "./commandBus/commandHandler";
import InMemoryAsyncCommandBus from "./commandBus/inMemoryAsyncCommandBus";
import type DomainEvent from "./eventBus/domainEvent";
import type EventBus from "./eventBus/eventBus";
import type EventSubscriber from "./eventBus/eventSubscriber";
import InMemoryAsyncEventBus from "./eventBus/inMemoryAsyncEventBus";
import type HandlersAndSubscribersMaps from "./handlersAndSubscribersMaps";

let commandBus: InMemoryAsyncCommandBus;
let localEventBus: InMemoryAsyncEventBus;

export async function loadCommandHandlers(config: Record<string, string>) {
  const registry: Record<string, CommandHandler<Command>> = {};

  for (const [name, handler] of Object.entries(config)) {
    const commandHandler = (await loadJsFile(handler)) as CommandHandler<Command>;

    if (!commandHandler) {
      throw new Error(`Command handler not found: ${handler} .`);
    }

    registry[name] = commandHandler;
  }

  return registry;
}

export async function loadEventSubscribers(config: Record<string, Array<string>>) {
  const registry: Record<string, EventSubscriber<DomainEvent>[]> = subscriptions;

  for (const [name, handlers] of Object.entries(config)) {
    const subscribers: EventSubscriber<DomainEvent>[] = await Promise.all(
      handlers.map(async (handler) => {
        const eventSubscriber = (await loadJsFile(handler)) as EventSubscriber<DomainEvent>;

        if (!eventSubscriber) {
          throw new Error(`Event subscriber not found: ${handler}`);
        }

        return eventSubscriber;
      })
    );

    registry[name] = subscribers;
  }

  return registry;
}

async function initializeEventDrivenBuses(maps: HandlersAndSubscribersMaps): Promise<void> {
  if (!commandBus || !localEventBus) {
    const commandHandlers = await loadCommandHandlers(maps.commandHandlers);
    commandBus = new InMemoryAsyncCommandBus(commandHandlers);

    const eventSubscribers = await loadEventSubscribers(maps.eventSubscribers);
    localEventBus = new InMemoryAsyncEventBus(eventSubscribers);
  }
}

function eventDrivenBusesForMFE(): { commandBus: CommandBus; localEventBus: EventBus } {
  return {
    commandBus,
    localEventBus,
  };
}

export type { CommandBus, EventBus };
export { eventDrivenBusesForMFE, initializeEventDrivenBuses };
