import uniqBy from "lodash/uniqBy";
import { useProductShellNavigationContext } from "../../Navigation/ProductShellNavigationContext";
import { getGroupsByTagNameAndMenuId } from "../../Navigation/util";
import { useGetMenuResponse } from "../../hooks/useGetMenuResponse";
import type { MenuGroup, MenuResponse } from "../../services/types/menu";
import { type FilterBySearchQuery, useGetFilterItemsBySearchQuery } from "./useGetFilterItemsBySearchQuery";

export const groupHasChildren = (
  menuResponse: MenuResponse,
  menuGroupId: string,
  filterBySearchQuery: FilterBySearchQuery
): boolean =>
  (
    menuResponse?.groups.filter(
      (gChild) => gChild.menuGroupId === menuGroupId && groupHasChildren(menuResponse, gChild.id, filterBySearchQuery)
    ) || []
  ).length +
    (menuResponse?.items.filter((item) => item.menuGroupId === menuGroupId).filter(filterBySearchQuery) || []).length >
  0;

export const useGetAllMenuGroupsForMenu = (menuId: string): MenuGroup[] => {
  const navigationContext = useProductShellNavigationContext();
  const filterBySearchQuery = useGetFilterItemsBySearchQuery();
  const menuResponse = useGetMenuResponse();
  const currentMenu = findCurrentMenu(menuResponse, menuId);
  const hasEnabledTags = currentMenu?.tags;

  const menuGroups =
    menuResponse?.groups
      .filter((group) => group.menuId === menuId)
      .filter((group) => groupHasChildren(menuResponse, group.id, filterBySearchQuery)) || [];

  if (hasEnabledTags) {
    const groupsForTag = getGroupsByTagNameAndMenuId(menuResponse, navigationContext.searchQuery, menuId);
    return uniqBy([...groupsForTag, ...menuGroups], "id");
  }
  return menuGroups;
};

const findCurrentMenu = (menuResponse: MenuResponse | null, menuId: string) => {
  if (!menuResponse) {
    return null;
  }
  return menuResponse.groupedMenus.flatMap((group) => group.menuItems).find((menu) => menu.id === menuId);
};
