import { Link, useNxTranslation } from "@nexthink/apollo-components";
import NxTrans from "@nexthink/apollo-components/lib/i18n/NxTrans";
import type { ProductShellSharedContext } from "@nexthink/product-shell-library";
import { type FC, type ReactNode, useContext, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { useProductShellContext } from "../../context/ProductShellContext";
// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
import Error from "./Error";

import ImageDark from "./image-dark.svg";
import ImageLight from "./image-light.svg";

export interface ErrorPageProps extends Partial<ProductShellSharedContext> {
  id?: string;
  message?: string;
  description?: ReactNode;
  image?: ReactNode;
}

const ERROR_PAGE_TRANSLATION_NAMESPACE = "error-page";

const ErrorPage: FC<ErrorPageProps> = ({ id = "product-shell-error", message, description, image, ...rest }) => {
  const { t } = useNxTranslation();
  const { setPageTitle } = useProductShellContext();
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    setPageTitle(t("title", { ns: ERROR_PAGE_TRANSLATION_NAMESPACE }));
  }, [setPageTitle, t]);

  const defaultDescription = (
    <NxTrans ns={ERROR_PAGE_TRANSLATION_NAMESPACE} i18nKey={"description"}>
      1
      <Link
        onClick={(e) => {
          e.preventDefault();
          location.reload();
        }}
        to={"#"}
      >
        2
      </Link>
    </NxTrans>
  );

  return (
    <Error
      id={id}
      message={message || t("message", { ns: ERROR_PAGE_TRANSLATION_NAMESPACE })}
      description={description || defaultDescription}
      image={image || (themeContext.name === "light" ? <ImageLight /> : <ImageDark />)}
      {...rest}
    />
  );
};

export default ErrorPage;
