import Text from "@nexthink/apollo-components/lib/components/text/Text";
import { apolloSpaceInline1X } from "@nexthink/apollo-components/lib/tokens";
import type { CSSProperties } from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import Link from "../Link";
import { PANEL_ITEM_HEIGHT } from "../Panel/Panel.style";

export const StyledMenuItemContainer = styled.div({ width: "100%" });

export const StyledMenuItem = styled(Link)<{ isActive: boolean }>(({ theme, isActive }) => ({
  "&&": {
    display: "flex",
    alignItems: "center",
    position: "relative",
    border: "1px solid transparent",
    boxSizing: "border-box",
    borderRadius: "4px",
    cursor: "pointer",

    height: PANEL_ITEM_HEIGHT,
    paddingLeft: apolloSpaceInline1X,
    outline: "none",
    textDecoration: "none",
    color: theme.colors.apolloColorTextPrimary,

    overflow: "hidden",

    ...(isActive && {
      background: theme.colors.apolloColorBackgroundMainNavMenuHover,

      "&::before": {
        background: theme.colors.apolloColorStrokeSelected,
        content: `""`,
        width: "2px",
        position: "absolute",
        top: "-1px",
        left: 0,
        bottom: "-1px",
      },
    }),

    "&:hover": {
      background: theme.colors.apolloColorBackgroundMainNavMenuHover,
    },
  },
}));

export const StyledIconContainer = styled.span({
  display: "inline-flex",
  paddingRight: apolloSpaceInline1X,
});

export const StyledOverviewIcon = styled(SVG)<{ color: CSSProperties["fill"] }>(({ color }) => ({
  fill: color,
  outline: "none",
  "& *": {
    fill: color,
  },
  "& #icon-inactive": {
    display: "block",
  },

  "& #icon-active": {
    display: "none",
  },
}));

export const StyledMenuItemText = styled(Text)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});
