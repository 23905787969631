import nxI18n from "@nexthink/apollo-components/lib/i18n/i18n";
import ThemeProvider from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { type FC, memo, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainContainer from "./MainContainer/MainContainer";
import PSGlobalStyle from "./PSGlobalStyle";
import Auth from "./auth/Auth";
import { AuthProvider } from "./context/AuthContext";
import { MenuProvider } from "./context/MenuContext";
import { PortalProvider } from "./context/PortalContext";
import {
  type ProductShellContextProps,
  ProductShellProvider,
  useProductShellContext,
} from "./context/ProductShellContext";
import ErrorBoundary from "./errors/ErrorBoundary/ErrorBoundary";
import { observeMicroFrontEnd } from "./observability";
import { translations } from "./translations";
import { ErrorTypes } from "./utils";

const ProductShellMemoized: FC<Pick<ProductShellContextProps, "lang" | "theme">> = memo(function ProductShellMemoized({
  lang,
  theme,
}) {
  nxI18n.init(lang, translations);

  return (
    <ThemeProvider theme={theme}>
      <PSGlobalStyle />
      <ErrorBoundary newRelicErrorType={ErrorTypes.errorBoundaryError}>
        <Router>
          <Auth>
            <MenuProvider>
              <MainContainer />
            </MenuProvider>
          </Auth>
        </Router>
      </ErrorBoundary>
    </ThemeProvider>
  );
});

const ProductShell: FC = () => {
  const { theme, lang } = useProductShellContext();

  useEffect(() => {
    observeMicroFrontEnd("product-shell-service");
  }, []); // RUM should only start a new view once per load

  return <ProductShellMemoized lang={lang} theme={theme} />;
};

const ProductShellWrapper: FC = () => (
  <AuthProvider>
    <ProductShellProvider>
      <PortalProvider>
        <ProductShell />
      </PortalProvider>
    </ProductShellProvider>
  </AuthProvider>
);

export default ProductShellWrapper;
