import { Flex, Link, withNxTranslation } from "@nexthink/apollo-components";
import NxTrans from "@nexthink/apollo-components/lib/i18n/NxTrans";
import type { ApolloTheme } from "@nexthink/apollo-components/lib/theme/Theme";
import { Component, type ErrorInfo, type ReactNode } from "react";
import { withTheme } from "styled-components";
import { reportError } from "../../observability";
import { ErrorTypes } from "../../utils";
import ErrorPage from "../ErrorPage/ErrorPage";

interface Props {
  children: ReactNode;
  newRelicErrorType: ErrorTypes;
  theme: ApolloTheme;
  t: (key: string, option: object) => string;
}

interface State {
  error?: Error;
}

const ERROR_BOUNDARY_TRANSLATION_NAMESPACE = "error-boundary";

class ErrorBoundary extends Component<Props, State> {
  public state: State = {};
  private readonly description = (
    <NxTrans ns={ERROR_BOUNDARY_TRANSLATION_NAMESPACE} i18nKey={"description"}>
      1
      <Link
        onClick={(e) => {
          e.preventDefault();
          location.reload();
        }}
        to={"#"}
      >
        2
      </Link>
    </NxTrans>
  );

  public static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    reportError(error, { componentStack: errorInfo.componentStack, errorType: this.props.newRelicErrorType });
  }

  public render() {
    if (this.state.error) {
      return (
        <Flex bg={this.props.theme.colors.apolloColorBackgroundDashboard} flexDirection={"column"} height={"100%"}>
          <ErrorPage
            id={
              this.props.newRelicErrorType === ErrorTypes.errorBoundaryError
                ? "error-boundary"
                : "microfrontend-error-boundary"
            }
            {...(this.props.newRelicErrorType === ErrorTypes.errorBoundaryError && {
              message: this.props.t("message", { ns: ERROR_BOUNDARY_TRANSLATION_NAMESPACE }),
              description: this.description,
            })}
          />
        </Flex>
      );
    }

    return this.props.children;
  }
}

export default withTheme(withNxTranslation(ErrorBoundary));
