import Icon from "@nexthink/apollo-components/lib/components/icons/Icon";
import Text from "@nexthink/apollo-components/lib/components/text/Text";
import {
  apolloSpaceInline1X,
  apolloSpaceInlineHalfX,
  apolloSpaceInsetHalfX,
} from "@nexthink/apollo-components/lib/tokens";
import { getValueFromPixelString } from "@nexthink/apollo-components/lib/utils/TokenHelpers";
import styled from "styled-components";
import { PANEL_ITEM_HEIGHT, TRANSITION_DURATION } from "../Panel/Panel.style";

export const StyledMenuGroupCollapsibleTitle = styled(Text)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: PANEL_ITEM_HEIGHT,
  overflow: "hidden",
  cursor: "pointer",

  border: "1px solid transparent",
  borderRadius: "4px",

  "&:hover": {
    background: theme.colors.apolloColorBackgroundButtonTertiaryHover,
  },

  "& > span": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export const StyleMenuGroupCollapsibleCollapseIcon = styled(Icon)({
  marginRight: apolloSpaceInlineHalfX,
});

export const StyledMenuGroupCollapsibleContent = styled.ul<{
  isExpanded: boolean;
  childrenCount: number;
}>(({ isExpanded, childrenCount }) => ({
  transition: `height ${TRANSITION_DURATION}`,
  height: isExpanded ? childrenCount * PANEL_ITEM_HEIGHT : 0,
  visibility: isExpanded ? "visible" : "hidden",
  overflow: "hidden",

  "&&": {
    paddingLeft: getValueFromPixelString(apolloSpaceInline1X) + getValueFromPixelString(apolloSpaceInsetHalfX),
  },
}));
