import {
  type ProductShellSharedContext,
  type ProductShellState,
  onProductShellContextChange,
} from "@nexthink/product-shell-library";
import { useCallback, useEffect, useMemo } from "react";
import type { RouteComponentProps } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { useMenuContext } from "../context/MenuContext";
import { usePortalContext } from "../context/PortalContext";
import { useProductShellContext } from "../context/ProductShellContext";
import { useGetMtpFlags } from "./useGetMtpFlags";
import { useGetServiceConfig } from "./useGetServiceConfig";
import useProductShellService from "./useProductShellService";
import useRefreshMenu from "./useRefreshMenu";
import { useRefreshServiceConfig } from "./useRefreshServiceConfig";

// changes in the props here should be propagated to SearchAppDialog
export const useGetDynamicComponentProps = (
  routeComponentProps: RouteComponentProps
): ProductShellSharedContext | undefined => {
  const { getLDFlagValue, validateClaims } = useProductShellService();
  const { getToken } = useAuthContext();
  const { theme, setTheme, lang, setPageTitle, setAppState } = useProductShellContext();
  const serviceConfig = useGetServiceConfig();
  const { isFeatureEnabled } = useMenuContext();
  const { isFeatureFlagEnabled } = usePortalContext();
  const { refreshMenu } = useRefreshMenu();
  const { refreshServiceConfig } = useRefreshServiceConfig();
  const mtpFlags = useGetMtpFlags();

  const claimsValidationMethods = useMemo(
    () => ({
      hasAllClaims: (claims: string[]): Promise<boolean> => validateClaims({ method: "hasAllClaims", claims }),
      hasAnyClaim: (claims: string[]): Promise<boolean> => validateClaims({ method: "hasAnyClaim", claims }),
      hasPatternClaim: (patternClaim: string): Promise<boolean> =>
        validateClaims({ method: "hasPatternClaim", patternClaim }),
      hasClaimValue: (claim: string, claimValue: string): Promise<boolean> =>
        validateClaims({ method: "hasClaimValue", claim, value: claimValue }),
    }),
    [validateClaims]
  );

  const setProductShellState = useCallback((...state: ProductShellState) => setAppState(state), [setAppState]);

  // se we can't do propsAreEqual on useMemo we will do an effect
  // this will make sure we're not re-creating the props unnecessarily
  const value = useMemo(
    () => ({
      ...routeComponentProps,
      lang,
      theme,
      setTheme,
      getToken,
      getLDFlagValue,
      ...claimsValidationMethods,
      isFeatureEnabled,
      isFeatureFlagEnabled,
      refreshMenu,
      refreshServiceConfig,
      setPageTitle,
      hasNoFinder: mtpFlags.noFinder,
      hasNoEngines: mtpFlags.noEngines,
      hasNoPortal: mtpFlags.noPortal,
      hasNoV6Permissions: mtpFlags.noV6Permissions,
      mtpOnly: serviceConfig.mtpOnly,
      dataRetention: serviceConfig.dataRetention,
      demoSettings: serviceConfig.demoSettings,
      mapbox: serviceConfig.map,
      setProductShellState,
    }),
    [
      lang,
      theme,
      setTheme,
      getToken,
      getLDFlagValue,
      claimsValidationMethods,
      isFeatureEnabled,
      isFeatureFlagEnabled,
      refreshMenu,
      refreshServiceConfig,
      setPageTitle,
      mtpFlags.noFinder,
      mtpFlags.noEngines,
      mtpFlags.noPortal,
      mtpFlags.noV6Permissions,
      routeComponentProps,
      serviceConfig.mtpOnly,
      serviceConfig.dataRetention,
      serviceConfig.demoSettings,
      serviceConfig.map,
      setProductShellState,
    ]
  );

  useEffect(() => {
    onProductShellContextChange(value);
  }, [value]);

  return value;
};
