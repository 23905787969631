import Text from "@nexthink/apollo-components/lib/components/text/Text";
import { apolloSpaceInset1X, apolloSpaceStack2X } from "@nexthink/apollo-components/lib/tokens";
import styled from "styled-components";
import { PANEL_ITEM_HEIGHT } from "../Panel/Panel.style";

const StyledList = styled.ul({
  display: "flex",
  flexBasis: 0,
  flexDirection: "column",
  flexGrow: 1,
  listStyle: "none",
  margin: 0,
  padding: 0,
  width: "100%",
});

export const StyledMenuGroupTitleContainer = styled(StyledList)({
  paddingBottom: apolloSpaceStack2X,
});

export const StyledMenuGroupTitleTitle = styled(Text)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.colors.apolloColorTextSecondary,
  textTransform: "uppercase",
  height: PANEL_ITEM_HEIGHT,
  paddingLeft: apolloSpaceInset1X,

  "& > span": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));
