import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, SecureRoute, Security, useOktaAuth } from "@okta/okta-react";
import type { RestoreOriginalUriFunction } from "@okta/okta-react/bundles/types/OktaContext";
import type React from "react";
import { type FC, useCallback, useEffect, useRef } from "react";
import { Route, useHistory } from "react-router-dom";
import LoadingOverlay from "../ProductShellRouter/LoadingOverlay";
import { useAuthContext } from "../context/AuthContext";
import ErrorPage from "../errors/ErrorPage/ErrorPage";
import useProductShellService from "../hooks/useProductShellService";
import { reportError } from "../observability";
import { ErrorTypes } from "../utils";

const OKTA_LOGIN_REDIRECT_URI = "/login/callback";
const OKTA_ISSUER_URI = "/oauth2/default";

const getIssuer = () => {
  let devOktaIssuer: string | undefined;
  try {
    devOktaIssuer = process.env.OKTA_AUTH_URL;
  } catch {
    devOktaIssuer = undefined;
  }

  const hostname = location.host.split(".");
  if (!hostname[0].includes("localhost")) {
    hostname[0] += "-login";
  }

  return devOktaIssuer ?? `${location.protocol}//${hostname.join(".")}`;
};

const oktaAuthConfig = new OktaAuth({
  issuer: getIssuer() + OKTA_ISSUER_URI,
  clientId: "product-shell",
  redirectUri: window.location.origin + OKTA_LOGIN_REDIRECT_URI,
  scopes: ["openid", "email", "profile", "offline_access"],
});

const handleLogin = async (oktaAuth: OktaAuth) => {
  if (!oktaAuth.authStateManager.getAuthState()) {
    return;
  }

  const isAfterLogout = new URLSearchParams(window.location.search).get("state") === "logout";
  const isLoginRoute = window.location.pathname === "/login";
  const isLoginCallbackRoute = window.location.pathname === OKTA_LOGIN_REDIRECT_URI;
  if (!isLoginCallbackRoute) {
    if (!isLoginRoute && !isAfterLogout) {
      await oktaAuth.signInWithRedirect({
        extraParams: {
          customerIdp: "true",
        },
      });
    } else {
      await oktaAuth.signInWithRedirect({ originalUri: window.location.origin });
    }
  }
};

const CustomError: React.ComponentType<{ error: Error }> = (error) => {
  reportError(error.error, { feature: "oktaLoginCallback", errorType: ErrorTypes.startupError });
  return <ErrorPage id="product-shell-error-state" />;
};

const CustomLoginCallback = () => {
  return <LoginCallback errorComponent={CustomError} loadingElement={<LoadingOverlay isVisible={true} />} />;
};

export const SecuredComp: FC = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const { isAuthenticated, setIsAuthenticated, setGetToken, setLogoutFn, setOktaUserClaims } = useAuthContext();
  const oktaTokenRef = useRef<string | null>(null);
  const { setPSApiAuthInterceptorFn } = useProductShellService();

  const getOktaToken = useCallback(() => {
    return Promise.resolve(oktaTokenRef.current);
  }, []);

  const signOut = useCallback(async () => {
    await oktaAuth.signOut({ state: "logout" });
    setIsAuthenticated(false);
  }, [oktaAuth, setIsAuthenticated]);

  useEffect(() => {
    oktaTokenRef.current = authState?.accessToken?.accessToken ?? null;
  }, [authState?.accessToken?.accessToken]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const authenticatedUser = authState?.isAuthenticated && oktaTokenRef.current;
    if (authenticatedUser) {
      setGetToken(() => getOktaToken);
      // FIXME use getToken for setting the interceptor instead
      setPSApiAuthInterceptorFn(() => getOktaToken());
      setLogoutFn(() => signOut);
      setOktaUserClaims(authState?.idToken?.claims);
      setIsAuthenticated(true);
    }
  }, []);

  return isAuthenticated ? <>{children}</> : null;
};

const OktaAuthComponent: FC = (props) => {
  const history = useHistory();
  const restoreOriginalUri: RestoreOriginalUriFunction = (_oktaAuth, originalUri) => {
    const url = toRelativeUrl(originalUri || "/", window.location.origin);
    history.replace(url);
  };

  return (
    <Security oktaAuth={oktaAuthConfig} restoreOriginalUri={restoreOriginalUri}>
      <SecureRoute onAuthRequired={handleLogin} path="/" render={() => <SecuredComp {...props} />} />
      <Route path={OKTA_LOGIN_REDIRECT_URI} component={CustomLoginCallback} />
    </Security>
  );
};

export default OktaAuthComponent;
