import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Spinner from "@nexthink/apollo-components/lib/components/loader/Spinner";
import { ThemeContext } from "@nexthink/apollo-components/lib/theme/ThemeProvider";
import { type FC, useContext, useEffect } from "react";
import { useGetMenuTags } from "../../Navigation/hooks/useGetMenuTags";
import type { Menu, MenuGroup as MenuGroupType, MenuItem as MenuItemType } from "../../services/types/menu";
import { MenuActionType, PanelType } from "../../services/types/menu-shared";
import { MenuGroup } from "../MenuGroup/MenuGroup";
import { MenuItem } from "../MenuItem/MenuItem";
import { useGetMenuActions } from "../hooks/useGetMenuActions";
import { useGetMenuGroups } from "../hooks/useGetMenuGroups";
import { useShouldMenuHaveSearch } from "../hooks/useShouldMenuHaveSearch";
import { useGetMenuItemsByGroupList } from "../hooks/usetGetMenuItemsByGroupList";
import { type MenuTag, getFirstFocusableElementId, getMenuItemProps } from "../util";
import { StyledPanelContainer, StyledPanelContent, StyledPanelTopContainer } from "./PanelDefault.style";
import PanelDetailsWithTags from "./PanelDetailsWithTags";
import PanelSearch from "./PanelSearch";
import PanelState from "./PanelState";
import PanelTabbed from "./PanelTabbed";
import PanelTitle from "./PanelTitle";

export const getSearchIdForMenuId = (menuId: string) => `${menuId}-search`;

const GeneralPanel = ({
  items,
  groups,
  tags,
  enableTags,
}: {
  items: MenuItemType[];
  groups: MenuGroupType[];
  tags: MenuTag[];
  enableTags: boolean;
}) => {
  const hasTags = enableTags && tags?.length > 0;
  return (
    <StyledPanelContent>
      {hasTags ? (
        <PanelDetailsWithTags menuTags={tags || []} menuItems={items} />
      ) : (
        <>
          {items.map((item) => (
            <MenuItem key={item.id} {...getMenuItemProps(item)} />
          ))}
          {groups.map((group) => (
            <MenuGroup key={group.id} id={group.id} level={0} />
          ))}
        </>
      )}
    </StyledPanelContent>
  );
};

const PanelDefault: FC<{ menu: Menu }> = ({ menu }) => {
  const { id: menuId, tags: enableTags = false } = menu;
  const menuGroupId = undefined;
  const groups = useGetMenuGroups(menuId, menuGroupId);
  const tags = useGetMenuTags(menuId);
  const items = useGetMenuItemsByGroupList(menuId);
  const actions = useGetMenuActions(menuId);

  const shouldHaveSearch = useShouldMenuHaveSearch(menu);

  const hasItems = items.length > 0;
  const hasGroups = groups.length > 0;
  const hasChildren = hasItems || hasGroups;

  const theme = useContext(ThemeContext);

  const configureActions = actions.filter((a) => a.type === MenuActionType.Config);
  const overviewAction = actions.find((a) => a.type === MenuActionType.Overview);
  const isTabbedPanel = menu.panelType === PanelType.Tabbed;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const elementInfo = getFirstFocusableElementId(menu.id, shouldHaveSearch, items, groups, overviewAction);
    if (!elementInfo) {
      return;
    }
    const focusableElement = document.getElementById(elementInfo.elementId);
    if (elementInfo?.isGroup) {
      const firstGroup = focusableElement?.getElementsByTagName("a")?.[0];
      if (firstGroup) {
        firstGroup.focus();
      }
    } else {
      focusableElement?.focus();
    }
  }, [menuId]);

  return (
    <StyledPanelContainer>
      <PanelTitle id="panel-title" menu={menu} />

      {menu.isLoading ? (
        <Flex flex={1} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          {(overviewAction || configureActions) && (
            <StyledPanelTopContainer>
              {overviewAction && (
                <MenuItem
                  {...getMenuItemProps(overviewAction)}
                  isAction={true}
                  isOverviewIcon={true}
                  icon={menu.icon}
                  color={theme.colors.apolloColorTextPrimary}
                />
              )}
              {configureActions?.map((action) => (
                <MenuItem key={action.id} isAction={true} {...getMenuItemProps(action)} icon="cog" />
              ))}
            </StyledPanelTopContainer>
          )}

          {shouldHaveSearch && <PanelSearch id={getSearchIdForMenuId(menuId)} />}

          {!hasChildren && <PanelState menu={menu} />}

          {isTabbedPanel ? (
            <PanelTabbed menu={menu} />
          ) : (
            <GeneralPanel items={items} groups={groups} enableTags={enableTags} tags={tags} />
          )}
        </>
      )}
    </StyledPanelContainer>
  );
};

export default PanelDefault;
