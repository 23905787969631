import { useCallback, useEffect } from "react";
import { useAuthContext } from "../context/AuthContext";
import { useProductShellContext } from "../context/ProductShellContext";
import { reportError } from "../observability";
import { useIsAmplifyUser } from "./useIsAmplifyUser";

export const ACTION_LOGOUT = "logout";

export type AmplifyParameterType = { token: string } | { action: string };
export type AmplifyResponseType = { success: boolean; message: string } | undefined;

export const sendToAmplify = (extensionId: string, data: AmplifyParameterType): void => {
  if (window.chrome && chrome.runtime) {
    chrome.runtime.sendMessage<AmplifyParameterType, AmplifyResponseType>(extensionId, data, (response) => {
      if (!response || !response.success) {
        const error: string = response
          ? response.message
          : chrome.runtime.lastError?.message || "amplify unknown issue";
        reportError(new Error(error), { feature: "sendToAmplify" });
      }
    });
  }
};

export const useSendLogoutToAmplify = () => {
  const { serviceConfig } = useProductShellContext();

  return useCallback(() => {
    if (serviceConfig?.amplifyExtensionId) {
      sendToAmplify(serviceConfig?.amplifyExtensionId, { action: ACTION_LOGOUT });
    }
  }, [serviceConfig?.amplifyExtensionId]);
};

const useSendToAmplify = (): void => {
  const { serviceConfig } = useProductShellContext();
  const { getToken, hasPortal, isAuthenticated } = useAuthContext();
  const isAmplifyUser = useIsAmplifyUser();

  const sendToAmplifyCallback = useCallback(async () => {
    if (serviceConfig?.amplifyExtensionId) {
      const token = await getToken();
      token && sendToAmplify(serviceConfig?.amplifyExtensionId, { token });
    }
  }, [getToken, serviceConfig?.amplifyExtensionId]);

  useEffect(() => {
    if (isAmplifyUser && hasPortal && isAuthenticated) {
      sendToAmplifyCallback();
    }
  }, [isAmplifyUser, hasPortal, sendToAmplifyCallback, isAuthenticated]);
};

export default useSendToAmplify;
