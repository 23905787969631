import { Tab, TabList, TabPanel } from "@nexthink/apollo-components/lib/components/tabs";
import { type FC, useEffect, useState } from "react";
import type { Menu } from "../../services/types/menu";
import { MenuGroup } from "../MenuGroup/MenuGroup";
import { useGetAllMenuGroupsForMenu } from "../hooks/useGetAllMenuGroupsForMenu";
import { StyledPanelContent, StyledTabs } from "./PanelCustomDashboards.style";
import PanelTitle from "./PanelTitle";

const PanelCustomDashboardsTabbed: FC<{ menu: Menu }> = ({ menu }) => {
  const allGroups = useGetAllMenuGroupsForMenu(menu.id);
  const rootGroups = allGroups.filter((g) => !g.menuGroupId);

  const [selectedTab, setSelectedTab] = useState(rootGroups[0]?.id);

  // Setting Focus on First Element
  useEffect(() => {
    if (selectedTab) {
      const tabGroups = allGroups.filter((g) => g.menuGroupId === selectedTab) || [];
      if (tabGroups.length > 0) {
        const panel = document.getElementById(`panel-${tabGroups[0].menuGroupId}`);
        const firstEl = panel?.querySelectorAll("[role='menuitem']")?.[0];
        (firstEl as HTMLElement)?.focus();
      }
    }
  });

  return (
    <StyledTabs>
      <PanelTitle menu={menu} />
      <TabList>
        {rootGroups.map((group) => (
          <Tab
            key={group.id}
            id={group.id}
            selected={selectedTab === group.id}
            onSelected={(): void => setSelectedTab(group.id)}
          >
            {group.title}
          </Tab>
        ))}
      </TabList>
      {rootGroups.map((group) => {
        const groupChildrenGroups = allGroups.filter((g) => g.menuGroupId === group.id) || [];
        const childrenElements = groupChildrenGroups.map((g) => <MenuGroup key={g.id} id={g.id} level={0} />);

        return (
          <TabPanel key={group.id} tab={group.id}>
            <StyledPanelContent>{childrenElements}</StyledPanelContent>
          </TabPanel>
        );
      })}
    </StyledTabs>
  );
};

export default PanelCustomDashboardsTabbed;
