import { useMemo } from "react";
import { useProductShellContext } from "../context/ProductShellContext";

export const useGetServiceConfigString = (): string => {
  const { serviceConfig } = useProductShellContext();

  return useMemo(() => {
    if (!serviceConfig) {
      return "";
    }

    if (serviceConfig.mtpOnly) {
      return serviceConfig.mtpVersion;
    }

    return `${serviceConfig.mtpVersion} (${serviceConfig.version})`;
  }, [serviceConfig]);
};
