import type { ApolloTheme } from "@nexthink/apollo-components/lib/theme/Theme";
import { createGlobalStyle } from "styled-components";

// needs the polyfill focus-visible
// Temporary global style to make sure that the menu focus is not overriden by feature team global style
//TODO should be removed when all feature team have apollo-components 6.80.0+ [APO-1376]
const PSGlobalStyle = createGlobalStyle(({ theme }: { theme: ApolloTheme }) => ({
  ".js-focus-visible #product-shell-menu *:focus:not(.focus-visible)": {
    outline: "none",
  },

  ".js-focus-visible #product-shell-menu .focus-visible": {
    outline: `1px solid ${theme.colors.apolloColorStrokeFocus}`,
    outlineOffset: "-1px",
    boxShadow: theme.colors.apolloBoxShadowFormElementFocusHighlight,
  },

  ".js-focus-visible #product-shell-menu .focus-visible[data-focus-border]": {
    border: `1px solid ${theme.colors.apolloColorStrokeFocus}`,
    boxSizing: "border-box",
    outline: "none",
  },
}));

export default PSGlobalStyle;
