import type DomainEvent from "./domainEvent";
import type EventBus from "./eventBus";
import type EventSubscriber from "./eventSubscriber";

export default class InMemoryAsyncEventBus implements EventBus {
  private readonly subscriberMap: Record<string, EventSubscriber<DomainEvent>[]>;
  constructor(subscriberMap: Record<string, EventSubscriber<DomainEvent>[]>) {
    this.subscriberMap = { ...subscriberMap };
  }

  async publish(events: DomainEvent[]): Promise<void> {
    for (const event of events) {
      if (!this.subscriberMap[event.eventName]) {
        continue;
      }
      for (const handler of this.subscriberMap[event.eventName]) {
        try {
          await handler(event);
        } catch (error) {
          console.warn(`Error while handling event: ${event.eventName}: ${error}`);
        }
      }
    }
  }
}
