export const NAVIGATION_TRANSLATION_NAMESPACE = "navigation";

export const ALERTS_MENU_ID = "alerts";
export const SEARCH_TITLE_KEY = "searchTitle";
export const SKIP_TO_MAIN_CONTENT_TITLE_KEY = "skipToMainContentTitle";

export const MY_ACCOUNT_MENU_ID = "my-account-menu";
export const MY_ACCOUNT_MY_ACCOUNT_ITEM_ID = "NxArmAccount";
export const MY_ACCOUNT_INSTALL_FINDER_ITEM_ID = "my-account-menu-install-finder";
export const MY_ACCOUNT_TITLE_KEY = "myAccountTitle";
export const MY_ACCOUNT_ITEM_TITLE_KEY = "myAccountMenuItemTitle";
export const MY_ACCOUNT_URL = "/admin/admin/arm/account";
export const MY_ACCOUNT_DOWNLOAD_FINDER_TITLE_KEY = "downloadFinderTitle";
export const MY_ACCOUNT_LOGOUT_TITLE_KEY = "logoutTitle";

export const DEVICE_LOCATOR_DIALOG_PATH = "/dialog/search";
export const SEARCH_APP_DIALOG_PATH = "/search"; // IS THIS A THING ??
export const DOWNLOAD_FINDER_DIALOG_PATH = "/dialog/finderInstall";

export const PANEL_SEARCH_MIN_MENU_ITEMS_COUNT = 5;

export const SEARCH_APP_ID = "NxGlobalSearch";
export const SEARCH_MENU_ID = ["global-search", "global-search-assist"];

export const DEVICE_LOCATOR_MENU_ID = "device-locator-menu";

export const CHECK_SESSION_FLAG = "productShell-checkIdleSession";
