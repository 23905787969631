import { TooltipText, WithTooltip } from "@nexthink/apollo-components";
import Button, { type ButtonProps } from "@nexthink/apollo-components/lib/components/button/Button";
import type { TooltipPosition } from "@nexthink/apollo-components/lib/components/tooltip/Tooltip";
import useUniqueId from "@nexthink/apollo-components/lib/hooks/useUniqueId";
import type { FC } from "react";
import { StyledTooltipButtonContainer } from "./TooltipButton.style";

export interface TooltipProps {
  id?: string;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
}
export type TooltipButtonProps = TooltipProps & ButtonProps;

export const TooltipButton: FC<TooltipButtonProps> = ({ tooltip, id, ...buttonProps }) => {
  const computedId = useUniqueId(id);

  const component = (
    <StyledTooltipButtonContainer id={computedId}>
      <Button id={`${computedId}-button`} {...buttonProps} />
    </StyledTooltipButtonContainer>
  );

  return tooltip && buttonProps.disabled ? (
    <WithTooltip
      tooltipContents={<TooltipText id={`${computedId}-tooltip`}>{tooltip}</TooltipText>}
      alignment="bottomRight"
      trigger="hover"
      spacing="compact"
      maxWidth="240px"
    >
      {component}
    </WithTooltip>
  ) : (
    component
  );
};
