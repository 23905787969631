import { useState } from "react";

export const triggerUpdateSignal: { updateSignal?: () => void } = {};

export function useLogoUpdateTrigger() {
  const [logoUpdateSignal, setLogoUpdateSignal] = useState(0);

  const updateSignal = () => {
    setLogoUpdateSignal((prev) => prev + 1);
  };

  triggerUpdateSignal.updateSignal = updateSignal;

  return { logoUpdateSignal };
}
