import type { FC } from "react";

import { useProductShellNavigationContext } from "../ProductShellNavigationContext";
import { useGetBlueBarMenu } from "../hooks/useGetMenu";
import { focusMainContent } from "../util";
import MenuIconBase from "./MenuIconBase";

export interface MenuIconProps {
  menuId: string;
  //For the new menu this is required, so when the old menu is depecated remove the optional.
  hideTitle?: boolean;
}

const MenuIcon: FC<MenuIconProps> = ({ menuId, hideTitle }) => {
  const menu = useGetBlueBarMenu(menuId);

  const { activeItems, openedMenuId, openMenu, closeMenu } = useProductShellNavigationContext();

  const isActive = openedMenuId ? openedMenuId === menuId : activeItems?.activeMenuId === menuId;
  const isOpen = openedMenuId === menuId;

  if (!menu) {
    return null;
  }

  const onClick = () => {
    if (menu.url) {
      closeMenu();
      focusMainContent();
    } else {
      openedMenuId === menuId ? closeMenu() : openMenu(menuId);
    }
  };

  return (
    <MenuIconBase
      id={menuId ? `MNV_${menuId}` : undefined}
      icon={menu.icon}
      title={menu.title}
      subtitle={menu.subtitle}
      href={menu.url}
      hasPopup={!menu.url}
      isActive={isActive}
      isOpen={isOpen}
      onClick={onClick}
      hideTitle={hideTitle}
    />
  );
};

export default MenuIcon;
