import Box from "@nexthink/apollo-components/lib/components/box/Box";
import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Button from "@nexthink/apollo-components/lib/components/button/Button";
import Dialog, { type DialogProps } from "@nexthink/apollo-components/lib/components/dialog/Dialog";
import { H3 } from "@nexthink/apollo-components/lib/components/heading/Heading";
import Input from "@nexthink/apollo-components/lib/components/input/Input";
import Spinner from "@nexthink/apollo-components/lib/components/loader/Spinner";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { apolloSpaceInline1X, apolloSpaceInset3X } from "@nexthink/apollo-components/lib/tokens";
import { type ChangeEventHandler, type FC, useState } from "react";

import { usePortalContext } from "../../context/PortalContext";
import usePortalService from "../../hooks/usePortalService";
import type { DeviceLocatorResponse } from "../../services/types/device-locator";
import Results from "./Results";
import Warnings from "./Warnings";

export type DeviceLocatorProps = Pick<DialogProps, "isOpen" | "onClose">;
export const DEVICE_LOCATOR_TRANSLATION_NAMESPACE = "device-locator";
export const DEVICE_LOCATOR_DIALOG_ID = "device-locator-dialog";

const DeviceLocatorDialog: FC<DeviceLocatorProps> = ({ isOpen, onClose }) => {
  const { t } = useNxTranslation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchResult, setSearchResult] = useState<DeviceLocatorResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { deviceLocatorSearch } = usePortalService();
  const { informationLevel } = usePortalContext();

  const header = (
    <H3>
      {t("enginesSearch_dialog_title", {
        ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE,
      })}
    </H3>
  );

  const handleOnClose = () => {
    setSearchValue("");
    setSearchResult(null);
    setLoading(false);
    onClose?.();
  };

  const handleSearch = async () => {
    setLoading(true);
    const result = await deviceLocatorSearch(searchValue);
    setLoading(false);
    setSearchResult(result);
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.currentTarget.value);
  };

  return (
    <Dialog
      displayCloseIcon={true}
      header={header}
      id={DEVICE_LOCATOR_DIALOG_ID}
      isOpen={isOpen}
      onClose={handleOnClose}
      width={710}
    >
      <Flex flex={1} flexDirection="column">
        <Flex>
          <Input
            aria-label="search input"
            autoFocus={true}
            onChange={handleOnChange}
            onPressEnter={handleSearch}
            placeholder={t(
              informationLevel === "personal" ? "enginesSearch_personal_input" : "enginesSearch_pseudonymous_input",
              { ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE }
            )}
            size={"large"}
            value={searchValue}
          />
          <Box ml={apolloSpaceInline1X}>
            <Button
              aria-label="search for device or user"
              id="device-locator-search"
              disabled={!searchValue.trim()}
              onClick={handleSearch}
            >
              {t("enginesSearch_search_button", {
                ns: DEVICE_LOCATOR_TRANSLATION_NAMESPACE,
              })}
            </Button>
          </Box>
        </Flex>
        <Flex pt={apolloSpaceInset3X}>
          {loading && (
            <Flex overflow={"hidden"} justifyContent={"center"} flex={1}>
              <Spinner size="bodyXlarge" />
            </Flex>
          )}
          {searchResult?.list && <Results list={searchResult.list} />}
          {searchResult?.warning && <Warnings warning={searchResult.warning} />}
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default DeviceLocatorDialog;
