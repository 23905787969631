import orderBy from "lodash/orderBy";
import type { BcsTag } from "services/types/bcs-shared";
import type { Menu, MenuAction, MenuGroup, MenuItem, MenuResponse } from "../services/types/menu";
import type { MenuItemProps } from "./MenuItem/MenuItem";
import type { ProductShellNavigationActiveItems } from "./ProductShellNavigationContext";

export type MenuTag = { id: string; title: string; color: string; menuId: string; menuGroupId?: string };

export const getMenuItemProps = <T extends MenuItemProps>(item: T): MenuItemProps => ({
  id: item.id,
  title: item.title,
  url: item.url,
  icon: item.icon,
});

export const getActiveItemsByUrl = (menuResponse: MenuResponse, url: string): ProductShellNavigationActiveItems => {
  const activeMenuItem = getActiveMenuItemByUrl(menuResponse, url);
  const activeMenuAction = getActiveMenuActionByUrl(menuResponse, url);
  const activeMenuItemId = activeMenuItem?.id || activeMenuAction?.id;

  const activeMenuGroupIds = activeMenuItem ? getActiveMenuGroupIdsByMenuItem(menuResponse, activeMenuItem) : [];

  const activeMenuId = activeMenuItem?.menuId || activeMenuAction?.menuId || getActiveMenuIdByUrl(menuResponse, url);

  return { activeMenuItemId, activeMenuGroupIds, activeMenuId };
};

export const getActiveMenuItemByUrl = (menuResponse: MenuResponse, url: string): MenuItem | undefined => {
  const cleanUrl = url.startsWith("/nxsm/") ? `/${url.split("/").slice(2, 5).join("/")}` : url;
  const exactMatchMenuItem = menuResponse.items.find((i) => i.url === cleanUrl);
  if (exactMatchMenuItem) {
    return exactMatchMenuItem;
  }
  const partialMatches = menuResponse.items.filter((i) => isPrefix(i.url, cleanUrl));
  partialMatches.sort((i1, i2) => i2.url.length - i1.url.length);

  return partialMatches[0];
};

export const getActiveMenuActionByUrl = (menuResponse: MenuResponse, url: string): MenuAction | undefined =>
  menuResponse.menuActions.find((i) => i.url === url || isPrefix(i.url, url));

export const getActiveMenuGroupIdsByMenuItem = (menuResponse: MenuResponse, activeMenuItem: MenuItem): string[] =>
  getParentMenuGroupId(menuResponse, activeMenuItem.menuGroupId, []);

const getParentMenuGroupId = (
  menuResponse: MenuResponse,
  menuGroupId: string | undefined,
  allGroupIds: string[]
): string[] => {
  if (menuGroupId) {
    const group = menuResponse.groups.find((g) => g.id === menuGroupId);

    if (group) {
      allGroupIds.push(group.id);
      return getParentMenuGroupId(menuResponse, group.menuGroupId, allGroupIds);
    }
  }

  return allGroupIds;
};

export const isPrefix = (prefix: string, s: string) => s.substring(0, prefix.length) === prefix;

export const isRouteHandledByPrefixes = (url: string, prefixes?: string[]): boolean =>
  Boolean(prefixes?.find((route) => isPrefix(route, url)));

export const flattenGroupedMenus = (menuResponse: MenuResponse): Menu[] => {
  return menuResponse.groupedMenus.flatMap((group) => group.menuItems);
};

export const getActiveMenuIdByUrl = (menuResponse: MenuResponse, url: string): string | undefined => {
  const menus = flattenGroupedMenus(menuResponse);
  for (const menu of menus) {
    if (isRouteHandledByPrefixes(url, menu.extraRoutePrefixes) || (menu.url && isPrefix(menu.url, url))) {
      return menu.id;
    }
  }
  return undefined;
};

export const getPageTitle = (menuResponse: MenuResponse, activeItems: ProductShellNavigationActiveItems): string => {
  const menus = flattenGroupedMenus(menuResponse);
  const menuTitle = menus.find((m) => m.id === activeItems.activeMenuId)?.title || "";

  const menuItemTitle: string = menuResponse.items.find((i) => i.id === activeItems.activeMenuItemId)?.title || "";

  return menuTitle && menuItemTitle ? `${menuTitle} : ${menuItemTitle}` : `${menuTitle}${menuItemTitle}`;
};

export const getTaggedMenuItems = (tags: MenuTag[], menuItems: MenuItem[], menuGroupId?: string | undefined) => {
  type TagItemDicitionary = { [key: string]: MenuItem[] };

  const taggedMenuItems: TagItemDicitionary = {};
  const hasMenuGroupId = !!menuGroupId;

  const hasItemTag = (tagName: string, item: MenuItem) => {
    return item.tags?.some((tag: BcsTag) => tag.name === tagName);
  };
  const filterByGroup = (item: MenuItem, groupId: string | undefined) =>
    hasMenuGroupId ? item.menuGroupId === groupId : true;

  const orderedTags = orderBy(tags, [(tag) => tag.title.toLowerCase()]);
  for (const tag of orderedTags) {
    const filteredItems = menuItems.filter(
      (item: MenuItem) => hasItemTag(tag.title, item) && filterByGroup(item, menuGroupId)
    );
    if (filteredItems.length > 0) {
      taggedMenuItems[tag.title] = filteredItems;
    }
  }
  return taggedMenuItems;
};

export const getUntaggedMenuItems = (menuItems: MenuItem[], menuGroupId?: string) => {
  const hasMenuGroupId = !!menuGroupId;
  const filterByGroup = (item: MenuItem, groupId: string | undefined) =>
    hasMenuGroupId ? item.menuGroupId === groupId : true;

  return orderBy(
    menuItems.filter((item: MenuItem) => item.tags?.length === 0 && filterByGroup(item, menuGroupId)),
    [(menuItem: MenuItem) => menuItem.title.toLowerCase()]
  );
};

export const filterTagsByName = (tags: BcsTag[], tagName: string) => {
  return tags.filter((tag: BcsTag) => tag.name.toLowerCase().includes(tagName.toLowerCase()));
};

export const getItemsByTagName = (tagName: string, items: MenuItem[], menuId: string) => {
  const currentMenuItems = items.filter((item: MenuItem) => item.menuId === menuId);

  return currentMenuItems.reduce((acc: MenuItem[], item: MenuItem) => {
    const matchingTags = filterTagsByName(item.tags || [], tagName);

    if (matchingTags.length > 0) {
      const itemCopy = {
        ...item,
        tags: item.title.toLowerCase().includes(tagName.toLowerCase()) ? item.tags : matchingTags,
      };
      acc.push(itemCopy);
    }

    return acc;
  }, []);
};

export const getGroupsByTagNameAndMenuId = (
  menuResponse: MenuResponse | null,
  tagName: string,
  menuId: string
): MenuGroup[] => {
  const itemsWithTagMatchingSearchQuery = getItemsByTagName(tagName, menuResponse?.items || [], menuId);
  const groupIdsFromItems = itemsWithTagMatchingSearchQuery?.map((item) => item.menuGroupId) || [];

  return (
    menuResponse?.groups
      .filter((group) => group.menuId === menuId)
      .filter((group) => groupIdsFromItems.includes(group.id)) || []
  );
};

export const getFirstFocusableElementId = (
  menuId: string,
  shouldHaveSearch: boolean,
  items: MenuItem[],
  groups: MenuGroup[],
  overviewAction?: MenuItem
): { elementId: string; isGroup?: boolean } | null => {
  const getSearchIdForMenuId = (id: string) => `${id}-search`;
  if (shouldHaveSearch) {
    return { elementId: getSearchIdForMenuId(menuId) };
  }
  if (overviewAction) {
    const action = getMenuItemProps(overviewAction);
    return { elementId: action.id };
  }
  if (items.length > 0) {
    const [fistItem] = items;
    return { elementId: fistItem.id };
  }
  if (groups.length > 0) {
    const [firstGroup] = groups;
    return { elementId: firstGroup.id, isGroup: true };
  }
  return null;
};

export const focusMainContent = () => {
  const mainContent = document.getElementById("product-shell-content");

  if (mainContent) {
    mainContent.style.outlineWidth = "0px";
    mainContent.setAttribute("tabindex", "0");
    mainContent.focus();
    mainContent.setAttribute("tabindex", "-1");
  }
};
