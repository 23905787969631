import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import Input from "@nexthink/apollo-components/lib/components/input/Input";
import { useNxTranslation } from "@nexthink/apollo-components/lib/i18n/useNxTranslation";
import { apolloSpaceInline1X, apolloSpaceInset1X } from "@nexthink/apollo-components/lib/tokens";
import type { ChangeEvent, FC } from "react";
import { useProductShellNavigationContext } from "../ProductShellNavigationContext";
import { NAVIGATION_TRANSLATION_NAMESPACE, SEARCH_TITLE_KEY } from "../constants";

export interface PanelSearchProps {
  id: string;
}
const PanelSearch: FC<PanelSearchProps> = ({ id }) => {
  const { t } = useNxTranslation();
  const { searchQuery, setSearchQuery } = useProductShellNavigationContext();

  const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Flex flex={0} pb={apolloSpaceInset1X} pl={apolloSpaceInline1X}>
      <Input
        id={id}
        prefix="search"
        placeholder={t(SEARCH_TITLE_KEY, { ns: NAVIGATION_TRANSLATION_NAMESPACE })}
        value={searchQuery}
        onChange={handleOnSearch}
      />
    </Flex>
  );
};

export default PanelSearch;
