import { useProductShellContext } from "../context/ProductShellContext";

export function useGetInfinityTransitionState(): string {
  const { serviceConfig: config } = useProductShellContext();

  if (!config) {
    return "";
  }

  if (!config.mtpOnly && !config.noEngines && !config.noFinder) {
    return "phase_1";
  }

  if (!config.mtpOnly && !config.noEngines && config.noFinder) {
    return "phase_2";
  }

  if (!config.mtpOnly && config.noEngines && config.noFinder) {
    return "phase_3";
  }

  if (config.mtpOnly && config.mtpPov) {
    return "phase_4";
  }

  if (config.mtpOnly && config.noPortal) {
    return "phase_5";
  }
  return "";
}
