import { Box, Icon, Text } from "@nexthink/apollo-components";
import {
  apolloFontWeightBodyStrong,
  apolloSpaceInline1X,
  apolloSpaceInline3X,
  apolloSpaceInlineHalfX,
  apolloSpaceInsetHalfX,
} from "@nexthink/apollo-components/lib/tokens";
import { getValueFromPixelString } from "@nexthink/apollo-components/lib/utils/TokenHelpers";
import styled from "styled-components";

export const StyledMenuTagContent = styled.ul<{
  isExpanded: boolean;
}>(({ isExpanded }) => ({
  visibility: isExpanded ? "visible" : "hidden",
  overflow: "hidden",
  marginTop: apolloSpaceInsetHalfX,
  marginBottom: apolloSpaceInsetHalfX,
  "&&": {
    paddingLeft: getValueFromPixelString(apolloSpaceInline1X) + getValueFromPixelString(apolloSpaceInsetHalfX),
  },
}));

export const StyleMenuTagCollapsibleCollapseIcon = styled(Icon)({
  marginRight: apolloSpaceInlineHalfX,
});

export const StyledMenuTag = styled(Text)<{ isEmptySearchQuery: boolean }>(({ isEmptySearchQuery, theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  height: apolloSpaceInline3X,

  cursor: "pointer",
  color: theme.colors.apolloColorTextPrimary,

  "&:hover": {
    cursor: isEmptySearchQuery ? "pointer" : "default",
    background: theme.colors.apolloColorBackgroundMainNavMenuHover,
  },
}));

export const StyledTagText = styled(Text)({
  fontWeight: apolloFontWeightBodyStrong,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  width: "auto",
  maxWidth: "260px",
  display: "inline-block",
  webkitBoxOrient: "vertical",
  marginRight: apolloSpaceInsetHalfX,
});

export const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
});
