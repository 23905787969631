import Flex from "@nexthink/apollo-components/lib/components/box/Flex";
import { apolloSpaceInset1X, apolloSpaceStack2X } from "@nexthink/apollo-components/lib/tokens";
import styled from "styled-components";

export const StyledPanelTitleContainer = styled(Flex)({
  paddingLeft: apolloSpaceInset1X,
  paddingBottom: apolloSpaceStack2X,
  alignItems: "center",
  justifyContent: "space-between",
});
