import { type FC, type ReactNode, useContext } from "react";
import { ThemeContext } from "styled-components";

import {
  type ApolloComponent,
  Box,
  Container,
  Flex,
  MainContainer,
  MainContent,
  Text,
  useUniqueId,
} from "@nexthink/apollo-components";
import { apolloSpaceInset4X, apolloSpaceStack3X } from "@nexthink/apollo-components/lib/tokens";

const prefixId = "ps-error-";

export interface ErrorProps extends ApolloComponent {
  /** main error message */
  message: string | ReactNode;
  /** error description */
  description: string | ReactNode;
  /** error image */
  image: ReactNode;
}

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
const Error: FC<ErrorProps> = ({ id, "data-insights": insightsAttr, message, description, image, ...rest }) => {
  id = useUniqueId(prefixId, id);

  const themeContext = useContext(ThemeContext);

  return (
    <MainContainer>
      <MainContent>
        <Container id={id} data-insights={insightsAttr} height={"auto"} {...rest}>
          <Flex flexDirection="column" alignItems="center" flex={1} p={apolloSpaceInset4X}>
            <Box role="alert" aria-describedby={`${id}_description`}>
              <Text
                as="h1"
                id={`${id}_message`}
                data-insights={insightsAttr && `${insightsAttr}_message`}
                color={themeContext.colors.apolloColorTextTertiary}
                variant="heading3"
              >
                {message}
              </Text>
            </Box>
            <Box my={apolloSpaceStack3X} role="img" aria-hidden={true}>
              {image}
            </Box>
            <Box textAlign={"center"}>
              <Text
                id={`${id}_description`}
                data-insights={insightsAttr && `${insightsAttr}_description`}
                color={themeContext.colors.apolloColorTextTertiary}
              >
                {description}
              </Text>
            </Box>
          </Flex>
        </Container>
      </MainContent>
    </MainContainer>
  );
};

export default Error;
