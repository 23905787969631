import { useGetMenuResponse } from "../../hooks/useGetMenuResponse";
import type { Menu, MenuResponse } from "../../services/types/menu";

export const useGetBlueBarMenu = (menuId: string): Menu | undefined => {
  const menuResponse: MenuResponse | null = useGetMenuResponse();

  return findMenuInGroups(menuResponse, menuId);
};

export const findMenuInGroups = (menuResponse: MenuResponse | null, menuId: string): Menu | undefined => {
  return menuResponse?.groupedMenus.reduce((acc, group) => {
    if (acc) {
      return acc;
    }
    return group.menuItems.find((menu) => menu.id === menuId);
  }, undefined);
};
